"use strict";
import { closeIcon } from "../../NewUI/shared/styleClasses";
import { theme } from "../../ThemeContext/ThemeObject";
export const classes = {
  summaryPopper: {
    zIndex: 12
  },
  summaryPopover: {
    position: "relative",
    width: "440px",
    maxHeight: "500px",
    overflow: "auto",
    padding: "32px 36px 24px 46px",
    filter: "drop-shadow(0px 2px 14px rgb(48, 90, 111, 0.16))",
    borderRadius: "19px",
    backgroundColor: "#FFFFFF",
    fontFamily: "Source Sans Pro",
    fontSize: "14.5px",
    transform: "translateX(14px)",
    "& li": {
      position: "relative",
      marginBottom: "6px",
      "&::before": {
        content: '""',
        position: "absolute",
        width: "8px",
        height: "8px",
        backgroundColor: "#2D388D",
        left: "-18px",
        top: "8px",
        borderRadius: "50%"
      }
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "36px",
      height: "36px",
      transform: "rotate(45deg)",
      backgroundColor: "#FFFFFF",
      left: "-17px",
      top: "calc(50% - 18px)",
      clipPath: "polygon(0 0, 0 100%, 100% 100%, 100% 100%, 0% 0)"
    }
  },
  expandIcon: {
    fontSize: "18px",
    position: "absolute",
    top: "12px",
    right: "12px",
    cursor: "pointer",
    color: "#2D388D"
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "60px",
    maxWidth: "1200px",
    width: "100%",
    height: "94vh",
    maxHeight: "844px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  aiModalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90%",
    height: "100%",
    rowGap: "40px",
    marginTop: "34px",
    paddingTop: "2px"
  },
  closeIcon,
  summaryContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    columnGap: "112px",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto"
  },
  applicantNameHeader: {
    width: "100%",
    marginBottom: "-12px",
    "& a": {
      color: "#2D388D",
      fontFamily: "Source Sans Pro",
      fontSize: "22px",
      fontWeight: "700"
    }
  },
  header: {
    fontFamily: "Source Sans Pro",
    fontSize: "38px",
    fontWeight: "700",
    color: "#2D388D",
    width: "100%"
  },
  responseHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "38px"
  },
  fieldSubHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#2D388D",
    marginBottom: "16px"
  },
  aiRow: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  inclusionsContainer: {
    display: "flex",
    columnGap: "28px",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  checkboxGroup: {
    display: "flex",
    alignItems: "center",
    columnGap: "6px"
  },
  inclusionsLabel: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: 600,
    color: "#2D388D",
    paddingTop: "2px"
  },
  inclusionsCheckbox: {
    color: "#7A8EF1 !important",
    "& .MuiSvgIcon-root": {
      fontSize: "24px"
    },
    "&.Mui-checked": {
      color: "#2D388D !important"
    }
  },
  applicantsContainer: {
    display: "flex",
    columnGap: "24px",
    width: "100%"
  },
  applicant: {
    position: "relative"
  },
  applicantInitials: {
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    backgroundColor: "#CDD7F2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "700",
    color: "#2D388D",
    cursor: "default"
  },
  applicantName: {
    position: "absolute",
    width: "max-content",
    top: "35px",
    left: "15px",
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontWeight: "700",
    color: "#666666",
    backgroundColor: "#FFFFFF",
    padding: "4px 10px",
    borderRadius: "6px",
    boxShadow: "0px 2px 14px rgba(48, 90, 111, 0.16)",
    zIndex: 2
  },
  removeApplicant: {
    position: "absolute",
    top: "-3px",
    right: "0px",
    cursor: "pointer",
    backgroundColor: "#798DF0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    "& svg": {
      color: "#FFFFFF",
      fontSize: "12px"
    },
    zIndex: 2
  },
  pillButton: {
    textTransform: "none",
    height: "36px",
    minWidth: "fit-content",
    paddingInline: "17px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "700",
    color: "#AFBCFF",
    border: "1px solid #AFBCFF",
    borderRadius: "18px"
  },
  noSummary: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "600",
    color: "#999999",
    width: "100%",
    textAlign: "center"
  },
  noSummaryFelix: {
    maxWidth: "100px",
    margin: "0px auto 20px auto",
    textAlign: "center"
  },
  noSummaryGenerate: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "6px"
  },
  noSavedQuestions: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontWeight: "600",
    color: "#999999",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px"
  },
  questionSkeleton: {
    width: "100%",
    height: "200px",
    borderRadius: "6px",
    transformOrigin: "unset",
    transform: "unset"
  },
  fieldInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "regular",
    borderRadius: "6px",
    height: "42px",
    width: "100%",
    "& legend": {
      display: "none"
    },
    "& input": {
      padding: "0px 20px 3.5px 20px",
      color: "#333333"
    },
    "& fieldset": {
      borderColor: "#DDDDDD"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADADA !important"
      }
    }
  },
  emailQuestionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    rowGap: "40px",
    paddingTop: "2px"
  },
  suitabilityCommentWarning: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: theme.palette.error.main,
    paddingTop: "12px"
  }
};
