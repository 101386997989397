import React, { useState, Dispatch } from 'react';
import { Stack } from '@mui/material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { TCandidate } from '../../../Candidates/types';
import { validateEmail } from '../../../Candidates/EditCandidate';

export default function Roubler({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [candidate, setCandidate] = useState<TCandidate | null>(application?.candidate || null);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateInputs = () => {
    if (!candidate) return false;
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!candidate.firstname.trim()) inputErrors.firstName = 'First name can not be empty';
    if (!candidate.lastname.trim()) inputErrors.lastName = 'Last name can not be empty';
    if (!validateEmail(candidate.email)) inputErrors.email = 'Please enter a proper email address';
    setErrors(inputErrors);
    return !Object.keys(inputErrors).length;
  };

  const { mutate: roublerExport, isLoading: exportingRoubler } = useMutation({
    mutationFn: async () => {
      if (application && candidate) {
        const { res } = await Api.roublerExport(application.id, {
          first_name: candidate.firstname,
          last_name: candidate.lastname,
          email: candidate.email
        });
        return res;
      }
    },
    onSuccess: (res: { success: string }) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: error.error, state: 'error' } });
    }
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  if (!candidate) return null;

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.ROUBLER}
      label="HROnboard export modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={{ ...styles.modalContainer, rowGap: 4 }}>
        <Stack sx={styles.modalTitle}>Export to Roubler</Stack>
        <Stack sx={{ overflow: 'auto', rowGap: 3, paddingBottom: 4 }}>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="First name"
              value={candidate.firstname || ''}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, firstname: e.target.value })}
              error={errors.firstName}
            />
            <FormTextField
              label="Surname"
              value={candidate.lastname || ''}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, lastname: e.target.value })}
              error={errors.lastName}
            />
          </Stack>
          <FormTextField
            label="Email"
            value={candidate.email}
            styles={{ width: '48%' }}
            required
            onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
            error={errors.email}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Export"
          primaryButtonCallback={() => validateInputs() && roublerExport()}
          isLoading={exportingRoubler}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
