"use strict";
import { AttachmentType } from "../Application/types";
export const getAttachmentType = (selectedAttachment) => {
  const contentType = selectedAttachment == null ? void 0 : selectedAttachment.attached_content_type;
  if (!contentType) return;
  const typeMap = {
    image: AttachmentType.Image,
    video: AttachmentType.Video,
    audio: AttachmentType.Audio,
    "text/plain": AttachmentType.Text,
    "application/pdf": AttachmentType.Pdf,
    "application/msword": AttachmentType.Word,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": AttachmentType.Word
  };
  const typePrefix = contentType.split("/")[0];
  const typeFull = contentType;
  return typeMap[typeFull] || typeMap[typePrefix];
};
