import React from 'react';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface StepperLeftButtonProps {
  activeStep: number;
  onClick: () => void;
  classes: any;
}

const StepperLeftButton: React.FC<StepperLeftButtonProps> = ({ activeStep, onClick, classes }) => {
  return activeStep !== 0 ? (
    <Button disableElevation className={classes.buttonSecondaryLight} onClick={onClick}>
      <ChevronLeftIcon />
    </Button>
  ) : null;
};

export default StepperLeftButton;
