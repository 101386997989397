import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InPlaceInputEditor from '../scout/table/fields/inplace_input_editor';

const SavedSearchItem = ({ savedSearch, isStaff }) => {
  const [title, setTitle] = useState(savedSearch.title);
  const itemUrl = '/admin/search/advanced/saved/' + savedSearch.id;
  const emailUrl =
    '/admin/search/advanced/saved/' +
    savedSearch.id +
    '/email-batches/new' +
    (isStaff ? '?skip_scout_clients=true' : '');

  const updateItem = (value, id) =>
    $.ajax({
      url: `/admin/api/v1/saved_searches/${id}`,
      method: 'PATCH',
      data: {
        saved_search: {
          title: value,
          name: value
        }
      },
      success: ({ data }) => setTitle(data.saved_search.title)
    });

  const renderSearchLink = () => {
    const { post_code1, post_code2, status, tags, date1, date2, by_title, query } =
      savedSearch.params;

    let url = window.location.origin + '/admin/search/advanced/results?';

    let data_params = {
      query: query || '',
      sorting: 'id',
      start: '0',
      date1: date1 || '',
      date2: date2 || '',
      post_code1: post_code1 || '',
      post_code2: post_code2 || '',
      by_title: by_title || false
    };
    let ret = [];
    ret = encodeDataParams(ret, data_params);
    ret = encodeStatus(ret, status);
    ret = encodeTags(ret, tags);

    url += ret.join('&');

    return <a href={url}>{title || savedSearch.name}</a>;
  };

  const renderFilters = () => {
    const { post_code1, post_code2, status, tags, date1, date2, by_title } = savedSearch.params;
    const parameters = [];

    if (tags && tags.length > 0) parameters.push(`Tags: (${tags.join(', ')})`);
    if (status && status.length > 0) parameters.push(`Statuses: (${status.join(', ')})`);
    if (post_code1 && post_code2) parameters.push(`Postcode: ${post_code1} — ${post_code2}`);
    if (date1) parameters.push(`Dates: ${date1} — ${date2}`);
    if (by_title) parameters.push('Search by title');

    return parameters.join(', ');
  };

  return (
    <tr>
      <td>
        <InPlaceInputEditor
          id={savedSearch.id}
          saveCallback={updateItem}
          defaultLabel={renderSearchLink()}
          value={title || savedSearch.name}
        />
      </td>
      <td>{renderFilters()}</td>
      <td className="">
        <a className="btn" href={emailUrl}>
          Email This List
        </a>
        <a className="btn" href={itemUrl} data-method="delete">
          Delete
        </a>
        <a className="btn" href={`${itemUrl}.csv`}>
          Export As CSV
        </a>
      </td>
    </tr>
  );
};

SavedSearchItem.propTypes = {
  savedSearch: PropTypes.object,
  isStaff: PropTypes.bool
};

const encodeDataParams = (array, dataParams) => {
  for (let d in dataParams) {
    if (dataParams[d] === '') continue;
    array.push(encodeURIComponent(d) + '=' + encodeURIComponent(dataParams[d]));
  }
  return array;
};

const encodeStatus = (array, status) => {
  if (status) {
    for (let i = 0; i < status.length; i++) {
      array.push(encodeURIComponent('status[]') + '=' + encodeURIComponent(status[i]));
    }
  }

  return array;
};

const encodeTags = (array, tags) => {
  if (tags) {
    for (let i = 0; i < tags.length; i++) {
      array.push(encodeURIComponent('tags[]') + '=' + encodeURIComponent(tags[i]));
    }
  }
  return array;
};

export default SavedSearchItem;
