"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { classes as rootStyles } from "../styles";
import { theme } from "../../ThemeContext/ThemeObject";
import { closeIcon } from "../../NewUI/shared/styleClasses";
export const classes = __spreadProps(__spreadValues({}, rootStyles), {
  closeIcon,
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px",
    marginTop: "auto"
  },
  genericBackButton: {
    fontSize: "14px",
    backgroundColor: "#5BC4BF1F",
    color: theme.palette.action.main,
    borderRadius: "6px",
    textTransform: "none",
    boxShadow: "none",
    padding: "8px 14px",
    "& svg": {
      color: "#5BC4BF"
    },
    "&:hover": {
      backgroundColor: "#4da8a4",
      "& svg": {
        color: "#FFFFFF"
      }
    }
  },
  sectionContentWithNav: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  leftSideContent: {
    width: "30%",
    borderRight: "#DDDDDD 1px solid",
    paddingTop: 8
  },
  rightSideContent: {
    display: "grid",
    width: "70%",
    padding: "45px 60px 45px 45px"
  },
  emptyStateContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "100px 0px"
  },
  noReportsText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px",
    textAlign: "center"
  }
});
