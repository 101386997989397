import React from 'react';
import { IActivity } from '../../../Dashboard/types';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { theme } from '../../../../ThemeContext/ThemeObject';
import EventCircle from '../../../Dashboard/assets/EventCircle';
import dompurify from 'dompurify';
import { connectingLine } from '../../../shared/styleClasses';

function ActivityFeedItem({ activity, isLast }: { activity: IActivity; isLast: boolean }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="flex-start"
        gap="24px"
      >
        <Box display="flex" height="100%">
          <Box sx={{ ...styles.lineContainer, ...(!isLast && { ...styles.connectingLine }) }}>
            <EventCircle />
          </Box>
          <Box sx={styles.infoContainer}>
            <Box>{dayjs(activity.timestamp).format('hh:mma')}</Box>
            <Box>{activity.actor}</Box>
            <Box dangerouslySetInnerHTML={{ __html: dompurify.sanitize(activity.activity_html) }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  lineContainer: {
    marginRight: theme.spacing(2),
    position: 'relative',
    '& svg': {
      width: '30px',
      height: '30px'
    }
  },
  connectingLine,
  infoContainer: {
    '& > div:nth-child(1)': {
      color: '#666',
      fontWeight: '700',
      fontSize: '13px',
      textTransform: 'uppercase'
    },
    '& > div:nth-child(2) a': {
      color: '#333',
      fontWeight: '700',
      fontSize: '18px'
    },
    '& > div:nth-child(3)': {
      color: '#666',
      fontSize: '14px'
    }
  }
};

export default ActivityFeedItem;
