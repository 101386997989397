"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../../ThemeContext/ThemeObject";
import {
  actionButtonStyles,
  actionsMenuPaperCommonStyles,
  actionsMenuSpanCommonStyles,
  applyButtonStyles,
  basePrimaryButtonStyles,
  baseSecondaryButtonStyles,
  checkboxContainerStyles,
  filterPopoverContentStyles,
  modalConfirmationButtonCommonStyles,
  rowSelectContainerCommonStyles,
  scrollShadowAfterStyles,
  scrollShadowBeforeStyles,
  selectedTooltipCommonStyles,
  statusClampAfterStyles,
  statusClampCommonStyles,
  statusClampDropdownAfterStyles,
  statusClampDropdownStyles,
  statusClampHoverStyles,
  switchLabelsCommonStyles
} from "./commonStyles";
export const notesEditSaveButton = __spreadProps(__spreadValues({}, basePrimaryButtonStyles), {
  width: "86px",
  padding: "5px 0px",
  "& svg": {
    color: "#FFFFFF",
    fontSize: "16px !important"
  },
  "&:disabled": {
    backgroundColor: "#EEEEEE",
    color: "#BBBBBB",
    transition: "all 250ms ease-in-out"
  }
});
export const notesCancelButton = __spreadProps(__spreadValues({}, baseSecondaryButtonStyles), {
  width: "86px",
  padding: "5px 0px",
  "& svg": {
    color: theme.palette.error.main,
    fontSize: "16px !important"
  }
});
export const editNotePopover = {
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    rowGap: "10px",
    width: "300px",
    minHeight: "150px",
    padding: "17px 17px 14px 17px"
  }
};
export const statusClamp = __spreadProps(__spreadValues({}, statusClampCommonStyles), {
  width: "140px",
  padding: "6px 20px 5px 24px",
  "&::after": statusClampAfterStyles,
  "& .status-dropdown-full": __spreadProps(__spreadValues({}, statusClampDropdownStyles), {
    width: "140px",
    padding: "6px 20px 5px 24px",
    "&::after": statusClampDropdownAfterStyles
  }),
  "&:hover": statusClampHoverStyles
});
export const scrollShadowLeft = {
  "&::after": __spreadProps(__spreadValues({}, scrollShadowAfterStyles), {
    right: "-2px"
  }),
  "&::before": __spreadProps(__spreadValues({}, scrollShadowBeforeStyles), {
    right: "0"
  })
};
export const scrollShadowRight = {
  "&::after": __spreadProps(__spreadValues({}, scrollShadowAfterStyles), {
    left: "-2px"
  }),
  "&::before": __spreadProps(__spreadValues({}, scrollShadowBeforeStyles), {
    left: "0"
  })
};
export const searchInput = {
  fontFamily: "Source Sans Pro",
  fontSize: "20px",
  fontWeight: "regular",
  borderRadius: "6px",
  minHeight: "54px",
  "& legend": {
    display: "none"
  },
  "& svg": {
    fontSize: "31px",
    color: "#084D6D"
  },
  "& input": {
    padding: "10.5px 11px 15.5px 11px",
    color: "#000000"
  },
  "& fieldset": {
    borderColor: "#EAEEF0"
  },
  "&:hover": {
    "& fieldset": {
      borderColor: "#DADEE0 !important"
    }
  }
};
export const chip = {
  fontFamily: "Source Sans Pro",
  fontSize: "18px",
  fontWeight: "600",
  padding: "20px 12px",
  borderRadius: "25px",
  backgroundColor: "#FFFFFF",
  border: "2px solid #CCCCCC",
  color: "#CCCCCC",
  transition: "all 0.4s ease-in-out",
  "&:hover": {
    backgroundColor: "#FFFFFF"
  }
};
export const chipPacket = {
  display: "flex",
  flexWrap: "wrap",
  rowGap: "20px",
  columnGap: "26px",
  marginTop: "36px"
};
export const chipSelected = {
  fontFamily: "Source Sans Pro",
  fontSize: "18px",
  fontWeight: "600",
  padding: "20px 12px",
  borderRadius: "25px",
  color: theme.palette.primary.main,
  backgroundColor: "#FFFFFF",
  border: "2px solid " + theme.palette.action.main,
  transition: "all 0.4s ease-in-out",
  "&:hover": {
    backgroundColor: "#FFFFFF"
  }
};
export const actionsMenu = {
  "& .MuiPaper-root": __spreadProps(__spreadValues({}, actionsMenuPaperCommonStyles), {
    width: "170px",
    marginTop: "12px"
  }),
  "& span": actionsMenuSpanCommonStyles
};
export const filterMenu = {
  "& .MuiPaper-root": __spreadProps(__spreadValues({}, actionsMenuPaperCommonStyles), {
    rowGap: "11px",
    minWidth: "166px",
    marginTop: "6px"
  }),
  "& span": __spreadProps(__spreadValues({}, actionsMenuSpanCommonStyles), {
    color: "#939393"
  })
};
export const fileName = {
  fontFamily: "Source Sans Pro",
  fontSize: "14px",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  maxWidth: "240px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
};
export const connectingLine = {
  "&:before": {
    content: '""',
    display: "block",
    width: "1px",
    background: "#edf2f5",
    height: "100%",
    position: "absolute",
    left: "15px",
    top: "30px"
  }
};
export const newApplicationButton = {
  fontFamily: "Source Sans Pro",
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: "1.3",
  color: theme.palette.action.main,
  backgroundColor: "#5BC4BF1F",
  height: "60px",
  width: "260px",
  borderRadius: "6px",
  border: "none",
  textTransform: "none",
  "@media  screen and (max-width: 1130px)": {
    fontSize: "14px"
  },
  "&:hover": {
    backgroundColor: "#5BC4BF35",
    border: "none"
  }
};
export const sortableSwitchLabels = __spreadProps(__spreadValues({}, switchLabelsCommonStyles), {
  marginLeft: "4px"
});
export const filterSwitchLabels = __spreadProps(__spreadValues({}, switchLabelsCommonStyles), {
  marginLeft: "0px"
});
export const rowSelect = {
  fontFamily: "Source Sans Pro",
  fontSize: "14px",
  color: "#666666",
  fontWeight: "bold",
  borderRadius: "6px !important",
  "& legend": {
    display: "none"
  },
  "& svg": {
    display: "none"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#DBDEDF"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CACDCE"
  },
  "& .MuiOutlinedInput-input": {
    padding: "4px 12px 8px 12px !important"
  }
};
export const pagination = {
  marginRight: "24px",
  "& button": {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#CCCCCC",
    margin: "0px -2px"
  },
  "& .Mui-selected": {
    color: "#666666",
    backgroundColor: "#FFFFFF !important",
    "&:hover": {
      backgroundColor: "#FFFFFF !important"
    }
  },
  "& .MuiPaginationItem-previousNext": {
    color: "#666666"
  }
};
export const csvExportModal = {
  display: "flex",
  flexDirection: "column",
  padding: "60px",
  width: "520px",
  borderRadius: "6px",
  dropShadow: "0px 2px 14px #305A6F29",
  position: "absolute",
  background: "#FFFFFF",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontFamily: "Source Sans Pro",
  color: "#333333"
};
export const csvExportHeader = {
  fontSize: "20px",
  fontWeight: "600"
};
export const csvExportSubheader = {
  color: "#333333",
  fontSize: "14px"
};
export const closeIcon = {
  position: "absolute",
  top: "20px",
  right: "20px",
  cursor: "pointer",
  color: "#DDDDDD"
};
export const switchLabels = __spreadProps(__spreadValues({}, switchLabelsCommonStyles), {
  width: "200px"
});
export const clearButton = __spreadProps(__spreadValues({}, baseSecondaryButtonStyles), {
  fontSize: "10px",
  padding: "5px 10px",
  "& .MuiButton-endIcon svg": {
    fontSize: "10px"
  }
});
export const modalSendButton = __spreadProps(__spreadValues({}, basePrimaryButtonStyles), {
  padding: "8px 19px",
  "&:focus": {
    outline: "none"
  }
});
export const deleteApplicant = {
  color: theme.palette.error.main,
  backgroundColor: "#D6827D33",
  "&:hover": {
    color: "#FFFFFF",
    backgroundColor: theme.palette.error.main,
    border: "none"
  }
};
export const link = {
  fontSize: "19px",
  cursor: "pointer",
  color: "#939393",
  transition: "color 0.2s ease-in-out",
  "&:hover": {
    color: "#737373"
  },
  "@media (max-width: 1450px)": {
    fontSize: "16px"
  },
  "@media (max-width: 1260px)": {
    fontSize: "14px"
  }
};
export const copyButton = {
  fontSize: "12px",
  fontWeight: "bold",
  backgroundColor: "#5BC4BF1F",
  color: theme.palette.action.main,
  borderRadius: "6px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.palette.action.main,
    color: "#FFFFFF"
  }
};
export const actionModalFormLine = {
  position: "relative",
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  columnGap: "50px",
  rowGap: "26px",
  alignItems: "center",
  marginBottom: "26px",
  width: "100%",
  marginLeft: "12px"
};
export const csvExportButton = {
  fontFamily: "Source Sans Pro",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "none",
  color: "#FFFFFF",
  backgroundColor: theme.palette.action.main,
  borderRadius: "8px",
  height: "58px",
  marginTop: "42px",
  "&:hover": {
    backgroundColor: "#4da8a4"
  }
};
export const rightArrow = {
  display: "none",
  alignItems: "center",
  justifyContent: "center",
  position: "sticky",
  width: "40px",
  height: "65px",
  bottom: "370px",
  borderRadius: "10px 0px 0px 10px",
  background: "#eeeeee",
  zIndex: "9999",
  opacity: "0.9",
  cursor: "pointer"
};
export const applyButton = __spreadProps(__spreadValues({}, applyButtonStyles), {
  fontSize: "10px"
});
export const actionButton = __spreadValues({}, actionButtonStyles);
export const filterPopoverContent = __spreadProps(__spreadValues({}, filterPopoverContentStyles), {
  overflowY: "scroll"
});
export const paginationContainer = {
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  marginTop: "5px",
  padding: "12px",
  fontFamily: "Source Sans Pro",
  fontSize: "14px",
  fontWeight: "bold",
  color: "#666666"
};
export const selectedApplicantsTableHeader = {
  fontFamily: "Source Sans Pro",
  fontSize: "16px",
  fontWeight: "600",
  color: theme.palette.primary.main,
  marginTop: "24px",
  marginBottom: "20px"
};
export const checkboxLabel = {
  "& .MuiTypography-root": {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    color: "#939393"
  },
  "& span": {
    color: theme.palette.error.main
  }
};
export const modalCreateButton = __spreadProps(__spreadValues({}, modalConfirmationButtonCommonStyles), {
  padding: "8px 19px"
});
export const selectedTooltip = __spreadProps(__spreadValues({}, selectedTooltipCommonStyles), {
  justifyContent: "space-evenly",
  padding: "20px 30px",
  minWidth: "665px",
  left: "calc(50% - 350px)"
});
export const rowSelectContainer = __spreadProps(__spreadValues({}, rowSelectContainerCommonStyles), {
  padding: "12px"
});
export const rowSelectContainerAlt = __spreadProps(__spreadValues({}, rowSelectContainerCommonStyles), {
  padding: "8px"
});
export const checkboxContainer = __spreadProps(__spreadValues({}, checkboxContainerStyles), {
  marginTop: "24px"
});
