"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
import {
  pagination,
  paginationContainer,
  rowSelect,
  rowSelectContainer
} from "../../shared/styleClasses";
export const classes = {
  tableWrapper: {
    tableLayout: "auto !important",
    minWidth: "1000px",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "12px"
    },
    th: {
      color: theme.palette.primary.main,
      fontSize: "16px",
      fontWeight: "bold",
      paddingBottom: "16px"
    }
  },
  fileIconContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  fileIcon: {
    width: "50px",
    height: "50px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    backgroundColor: "#084D6D10",
    "& svg": {
      color: theme.palette.primary.main,
      margin: "0px 2px 2px 0px"
    }
  },
  fileName: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    fontWeight: "bold"
  },
  fileDate: {
    color: "#999999"
  },
  fileLinkContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: "15px",
    "& a": {
      color: "#333333",
      fontSize: "15px",
      "&:hover": {
        color: "#000000",
        textDecoration: "none"
      }
    }
  },
  fileAttachedBy: {
    color: "#000000",
    fontSize: "15px"
  },
  copyButton: {
    fontSize: "10px",
    fontWeight: "bold",
    backgroundColor: "#5BC4BF1F",
    color: theme.palette.action.main,
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.action.main,
      color: "#FFFFFF"
    }
  },
  noAttachments: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  paginationParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  },
  rowSelectContainer,
  paginationContainer,
  pagination,
  rowSelect,
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  }
};
