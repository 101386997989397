"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
import { actionsModalReducedPaddingCommonStyles } from "../../shared/commonStyles";
import { actionModalFormLine, closeIcon, modalSendButton } from "../../shared/styleClasses";
export const classes = {
  toggleButtonsContainer: {
    marginTop: "20px",
    marginBottom: "40px"
  },
  automationContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
    h2: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#666666",
      marginBottom: "24px"
    }
  },
  autoStatusesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1028px",
    padding: "30px 40px",
    backgroundColor: "#F3F3F367",
    borderRadius: "8px"
  },
  autoStatusesSubContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxWidth: "880px",
    rowGap: "24px",
    marginLeft: "24px",
    paddingBlock: "38px",
    borderBottom: "1px solid #DDDDDD",
    h4: {
      fontSize: "22px",
      fontWeight: "bold",
      color: "#666666"
    }
  },
  switchContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 6px",
    marginBlock: "auto",
    minWidth: "100px"
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    },
    "& :hover": {
      "& .MuiSwitch-track": {
        backgroundColor: "#000 !important"
      }
    }
  },
  switchActive: {
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    }
  },
  statusFormLine: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    columnGap: "24px",
    rowGap: "10px"
  },
  addNewFieldButton: {
    fontFamily: "Source Sans Pro",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "8px",
    backgroundColor: "#EEEEEE",
    padding: "8px 12px",
    color: "#999999",
    "&:hover": {
      backgroundColor: "#DDDDDD",
      color: "#666666"
    }
  },
  noStatusesAddButton: {
    fontFamily: "Source Sans Pro",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "8px",
    backgroundColor: "#EEEEEE",
    padding: "8px 14px",
    color: "#999999",
    "&:hover": {
      backgroundColor: "#DDDDDD",
      color: "#666666"
    }
  },
  removeButton: {
    transform: "translateX(-22px)",
    marginTop: "24px",
    "&:hover": {
      "& svg": {
        color: theme.palette.error.main
      }
    },
    "& svg": {
      color: "#666666"
    }
  },
  deleteButton: {
    position: "absolute",
    top: "20px",
    right: "0px",
    "& svg": {
      color: theme.palette.error.main
    }
  },
  clearButton: {
    transform: "translate(-22px, 12px)",
    "& svg": {
      color: theme.palette.error.main
    }
  },
  addNewStatusButton: {
    position: "absolute",
    bottom: "-19.5px",
    left: "47%",
    color: "#DDDDDD",
    "& svg": {
      color: "#CCCCCC",
      zIndex: 1
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: "25%",
      left: "25%",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      backgroundColor: "white",
      zIndex: "0"
    }
  },
  ratingSlider: {
    fontFamily: "Source Sans Pro",
    color: theme.palette.action.main,
    height: "3.5px",
    width: "80%",
    "& .MuiSlider-rail": {
      backgroundColor: "#E0E0E0"
    },
    "& .MuiSlider-track": {
      backgroundColor: theme.palette.action.main
    },
    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.action.main,
      width: "14px",
      height: "14px",
      "&:hover": {
        boxShadow: "0px 0px 0px 7px" + theme.palette.action.main + "16"
      }
    },
    "& .MuiSlider-thumb.Mui-active": {
      boxShadow: "0px 0px 0px 9px" + theme.palette.action.main + "16"
    },
    "& .MuiSlider-valueLabel": {
      fontSize: "11px",
      fontWeight: "500",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      top: "40px",
      "&::after": {
        display: "none"
      }
    }
  },
  titleAndButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  newButton: {
    fontFamily: "Source Sans Pro",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "6px",
    padding: "8px 16px",
    color: theme.palette.action.main,
    backgroundColor: `${theme.palette.action.main}1F`,
    "&:hover": {
      backgroundColor: `${theme.palette.action.main}2F`
    }
  },
  tableContainer: {
    maxWidth: "100%",
    overflow: "auto"
  },
  table: {
    "& th": {
      border: "none",
      backgroundColor: "#FFFFFF",
      whiteSpace: "nowrap",
      fontFamily: "Source Sans Pro",
      padding: "12px 0px 38px 0px"
    },
    "& td": {
      border: "none",
      fontFamily: "Source Sans Pro"
    },
    "& thead th": {
      minWidth: "170px",
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textTransform: "none",
      paddingRight: "8px"
    },
    "& tbody tr": {
      borderTop: "1px solid #DDDDDD"
    },
    "& tbody tr td": {
      padding: "25px 8px 25px 0px"
    }
  },
  tableActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "right",
    columnGap: "8px"
  },
  tableActionsReply: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: "25px",
    columnGap: "8px"
  },
  tableDeleteButton: {
    "& svg": {
      color: theme.palette.error.main
    }
  },
  tableEditButton: {
    "& svg": {
      color: "#666666"
    }
  },
  actionsModalReducedPadding: actionsModalReducedPaddingCommonStyles,
  actionModalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginLeft: "8px"
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    margin: "46px 0px"
  },
  actionModalFormLine,
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px"
  },
  modalCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "100px",
    textTransform: "none",
    textDecoration: "underline",
    color: theme.palette.error.main,
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      boxShadow: "none"
    }
  },
  modalSendButton,
  closeIcon,
  emailPreview: {
    padding: "30px 60px 50px 60px"
  },
  previewSkeleton: {
    borderRadius: "8px",
    margin: "16px"
  },
  formError: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    position: "absolute",
    bottom: "-24px",
    left: "4px"
  },
  noAutoForwarders: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "300px",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#939393"
  },
  noQuestionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  noQuestionsText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px",
    textAlign: "center"
  },
  noticeContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "9px 16px",
    border: `1px solid ${theme.palette.action.main}`,
    borderRadius: "8px",
    color: "#939393",
    fontSize: "16px",
    margin: "16px 0",
    rowGap: "1px"
  },
  noticeIcon: {
    color: theme.palette.action.main,
    margin: "0 9px 3px 0",
    fontSize: "16px"
  },
  htmlContent: {
    background: "#E0E0E01F",
    borderRadius: "0.5rem",
    color: "#939393",
    margin: "0px 16px 20px 16px",
    userSelect: "none",
    "& p": {
      marginTop: "0"
    }
  },
  previewHeader: {
    display: "flex",
    borderRadius: "6px 0 0 0",
    color: "#666666",
    background: "#DDDDDD",
    padding: "5px 10px",
    width: "120px",
    textAlign: "center",
    fontWeight: "700",
    justifyContent: "space-evenly"
  },
  placeholderText: {
    fontFamily: "Source Sans Pro",
    color: "#838383",
    fontSize: "11px",
    margin: "6px 0px 20px 4px"
  }
};
