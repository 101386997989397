var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import {
  Select,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  FormHelperText,
  Grid,
  MenuItem,
  InputAdornment,
  Box,
  Switch
} from "@material-ui/core";
import useStyles from "../useStyles";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate } from "react-day-picker/moment";
import ErrorIcon from "@material-ui/icons/Error";
import { jobAdPropTypes } from "../../../NewUI/Components/Multiposter/types";
import TextEditor from "../../../NewUI/Components/CustomUIElements/TextEditor";
function Step2({ answers, setAnswers, jobAdParams, requiredRefs, activeError }) {
  const classes = useStyles();
  function handleMultiCheck(value, state, key) {
    state.includes(value) ? setAnswers(__spreadProps(__spreadValues({}, answers), { [key]: state.filter((id) => id !== value) })) : setAnswers(__spreadProps(__spreadValues({}, answers), { [key]: [...answers[key], value] }));
  }
  return /* @__PURE__ */ React.createElement("div", { className: classes.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Job details"), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium} ${classes.greyBox}`
    },
    /* @__PURE__ */ React.createElement(Grid, { container: true, alignItems: "center" }, /* @__PURE__ */ React.createElement("span", null, "Job title:"), /* @__PURE__ */ React.createElement("div", null, answers.jobTitle)),
    /* @__PURE__ */ React.createElement(Grid, { container: true, alignItems: "center" }, /* @__PURE__ */ React.createElement("span", null, "Job company name:"), /* @__PURE__ */ React.createElement("div", null, answers.companyName)),
    /* @__PURE__ */ React.createElement(Grid, { container: true, alignItems: "center" }, /* @__PURE__ */ React.createElement("span", null, "Job location:"), /* @__PURE__ */ React.createElement("div", null, `${[answers.address, answers.city, answers.state].filter((cur) => cur).join(", ")}`))
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.employmentType,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "What type of employment is it?", /* @__PURE__ */ React.createElement("span", { className: classes.asterisk }, " *"), activeError === requiredRefs.employmentType && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement(Grid, { container: true }, jobAdParams.work_type_options.map((type) => /* @__PURE__ */ React.createElement(Grid, { key: type[0], item: true, xs: 6 }, /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        disabled: answers.employmentType.length >= 2 && !answers.employmentType.includes(type[1]),
        label: type[0],
        value: type[1],
        control: /* @__PURE__ */ React.createElement(
          Checkbox,
          {
            checked: answers.employmentType.includes(type[1]),
            onChange: (e) => handleMultiCheck(e.target.value, answers.employmentType, "employmentType")
          }
        )
      }
    )))))
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.schedule,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "What is the schedule for this job?", /* @__PURE__ */ React.createElement("span", { className: classes.asterisk }, " *"), activeError === requiredRefs.schedule && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement(Grid, { container: true }, jobAdParams.schedules_options.map((schedule) => /* @__PURE__ */ React.createElement(Grid, { key: schedule[1], item: true, xs: 6 }, /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        label: schedule[0],
        value: schedule[1],
        control: /* @__PURE__ */ React.createElement(
          Checkbox,
          {
            checked: answers.schedule.includes(schedule[1]),
            onChange: (e) => handleMultiCheck(e.target.value, answers.schedule, "schedule")
          }
        )
      }
    )))))
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.startDate,
      fullWidth: true,
      className: `${classes.formControl} ${classes.small}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Is there a planned start date for this job?", /* @__PURE__ */ React.createElement("span", { className: classes.asterisk }, " *"), activeError === requiredRefs.startDate && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        label: "Yes",
        control: /* @__PURE__ */ React.createElement(
          Radio,
          {
            checked: answers.isStartDate === true,
            onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { isStartDate: true }))
          }
        )
      }
    ), /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        label: "No",
        control: /* @__PURE__ */ React.createElement(
          Radio,
          {
            checked: answers.isStartDate === false,
            onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { isStartDate: false }))
          }
        )
      }
    )),
    answers.isStartDate && /* @__PURE__ */ React.createElement(
      DayPickerInput,
      {
        value: answers.startDate,
        onDayChange: (day) => setAnswers(__spreadProps(__spreadValues({}, answers), { startDate: formatDate(day, "LL") })),
        classNames: {
          container: `${classes.calendarContainer} ${activeError === requiredRefs.startDate ? classes.errorStartDate : ""}`,
          overlay: classes.calendarOverlay
        },
        format: "LL",
        placeholder: "Select a date"
      }
    )
  ), /* @__PURE__ */ React.createElement("h4", { ref: requiredRefs.salary, className: classes.requiredHeader }, "Salary & benefits", /* @__PURE__ */ React.createElement("span", null, "*"), activeError === requiredRefs.salary && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "Please input valid salary range"))), /* @__PURE__ */ React.createElement("div", { className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(Box, { mb: 1, classes: { root: classes.salaryInput } }, /* @__PURE__ */ React.createElement(
    Select,
    {
      classes: { select: classes.input, root: classes.fullWidth },
      variant: "outlined",
      value: answers.selectedSalaryType,
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), {
        selectedSalaryType: e.target.value,
        rangeTo: 0,
        rangeFrom: 0
      })),
      displayEmpty: true,
      renderValue: (value) => value[0]
    },
    answers.salaryTypes.map((type) => /* @__PURE__ */ React.createElement(MenuItem, { key: type[1], value: type }, type[0]))
  )), /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement(Grid, { container: true, classes: { root: classes.inputAdornment } }, /* @__PURE__ */ React.createElement(
    Grid,
    {
      item: true,
      container: true,
      xs: 9,
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      wrap: "nowrap"
    },
    answers.selectedSalaryType[0] === "Range" ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, { m: 1, ml: 0 }, /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.salary,
        className: classes.textField,
        variant: "outlined",
        value: Number(answers.rangeFrom).toLocaleString(),
        InputProps: {
          startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "$")
        },
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), {
          rangeFrom: e.target.value ? parseFloat(e.target.value.split(",").join("")) : ""
        }))
      }
    )), /* @__PURE__ */ React.createElement(Box, { sx: { marginTop: "20px" }, m: 1 }, /* @__PURE__ */ React.createElement("span", null, "to")), /* @__PURE__ */ React.createElement(Box, { m: 1 }, /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.salary,
        className: classes.textField,
        variant: "outlined",
        value: Number(answers.rangeTo).toLocaleString(),
        InputProps: {
          startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "$")
        },
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), {
          rangeTo: e.target.value ? parseFloat(e.target.value.split(",").join("")) : ""
        }))
      }
    ))) : /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.salary,
        style: { width: "100%", marginRight: 10 },
        className: classes.textField,
        variant: "outlined",
        value: Number(answers.rangeTo).toLocaleString(),
        InputProps: {
          startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "$")
        },
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), {
          rangeTo: e.target.value ? parseFloat(e.target.value.split(",").join("")) : "",
          rangeFrom: e.target.value ? parseFloat(e.target.value.split(",").join("")) : ""
        }))
      }
    )
  ), /* @__PURE__ */ React.createElement(Grid, { item: true, xs: 3 }, /* @__PURE__ */ React.createElement(Box, { m: 1, mr: 0 }, /* @__PURE__ */ React.createElement(
    Select,
    {
      classes: { select: classes.input },
      className: classes.salaryDropdown,
      variant: "outlined",
      value: answers.rangeUnit,
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { rangeUnit: e.target.value })),
      displayEmpty: true,
      renderValue: (value) => value[1]
    },
    jobAdParams.payment_frequencies_options.map((range) => /* @__PURE__ */ React.createElement(MenuItem, { key: range[0], value: range }, range[1]))
  ))))), /* @__PURE__ */ React.createElement(Box, { mt: 1 }, /* @__PURE__ */ React.createElement("span", null, "Specifying $0 omits salary from your job ad"))), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Are any forms of additional pay offered?"), /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement(Grid, { container: true }, jobAdParams.additional_payment_options.map((pay) => /* @__PURE__ */ React.createElement(Grid, { key: pay[1], item: true, xs: 6 }, /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: pay[0],
      value: pay[1],
      control: /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          checked: answers.additionalPay.includes(pay[1]),
          onChange: (e) => handleMultiCheck(e.target.value, answers.additionalPay, "additionalPay")
        }
      )
    }
  )))))), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Are any of the following benefits offered?"), /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement(Grid, { container: true }, jobAdParams.salary_benefit_options.map((benefit) => /* @__PURE__ */ React.createElement(Grid, { key: benefit[1], item: true, xs: 6 }, /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: benefit[0],
      value: benefit[1],
      control: /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          checked: answers.benefits.includes(benefit[1]),
          onChange: (e) => handleMultiCheck(e.target.value, answers.benefits, "benefits")
        }
      )
    }
  )))))), /* @__PURE__ */ React.createElement("h4", null, "Application Settings"), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Do you want applicants to submit a resume?"), /* @__PURE__ */ React.createElement(
    RadioGroup,
    {
      value: answers.submitResume,
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { submitResume: e.target.value }))
    },
    /* @__PURE__ */ React.createElement(FormControlLabel, { label: "Yes", value: "required", control: /* @__PURE__ */ React.createElement(Radio, null) }),
    /* @__PURE__ */ React.createElement(FormControlLabel, { label: "No", value: "hidden", control: /* @__PURE__ */ React.createElement(Radio, null) }),
    /* @__PURE__ */ React.createElement(FormControlLabel, { label: "Optional", value: "optional", control: /* @__PURE__ */ React.createElement(Radio, null) })
  )), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.small}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Is there an application deadline?"), /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: "Yes",
      control: /* @__PURE__ */ React.createElement(
        Radio,
        {
          checked: answers.isApplicationDeadline === true,
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { isApplicationDeadline: true }))
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: "No",
      control: /* @__PURE__ */ React.createElement(
        Radio,
        {
          checked: answers.isApplicationDeadline === false,
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { isApplicationDeadline: false }))
        }
      )
    }
  )), answers.isApplicationDeadline && /* @__PURE__ */ React.createElement(
    DayPickerInput,
    {
      value: answers.applicationDeadline,
      onDayChange: (day) => setAnswers(__spreadProps(__spreadValues({}, answers), { applicationDeadline: formatDate(day, "LL") })),
      classNames: { container: `${classes.calendarContainer}` },
      format: "LL",
      placeholder: "Select a date"
    }
  )), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.large}` }, /* @__PURE__ */ React.createElement(Grid, { container: true }, /* @__PURE__ */ React.createElement(Grid, { item: true, xs: 10 }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Do you want to let applicants start the conversation?"), /* @__PURE__ */ React.createElement("span", { className: classes.subLabel }, "Allow job seekers to message you about their applications.", /* @__PURE__ */ React.createElement("br", null), "Messages will be sent to the email address you added in Application settings.")), /* @__PURE__ */ React.createElement(Grid, { item: true, xs: 2 }, /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: answers.startConversation ? "Yes" : "No",
      control: /* @__PURE__ */ React.createElement(
        Switch,
        {
          checked: answers.startConversation,
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { startConversation: !answers.startConversation }))
        }
      )
    }
  )))), /* @__PURE__ */ React.createElement("h4", { ref: requiredRefs.description, className: classes.requiredHeader }, "Job description", /* @__PURE__ */ React.createElement("span", null, "*"), activeError === requiredRefs.description && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${classes.formControl} ${classes.large}`,
      style: { marginTop: 5 }
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Describe the responsbilities of this job, required work experience, skills, or education."),
    /* @__PURE__ */ React.createElement(
      TextEditor,
      {
        editorState: answers.description,
        setAnswers,
        answers,
        activeError,
        requiredRef: requiredRefs.description,
        classes
      }
    )
  ), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.large}` }, /* @__PURE__ */ React.createElement(FormLabel, { className: classes.label }, "Are you taking any additional COVID-19 precautions?"), /* @__PURE__ */ React.createElement("span", { className: classes.subLabel }, "Let job seekers know how you're responding to COVID-19"), /* @__PURE__ */ React.createElement(
    TextField,
    {
      multiline: true,
      placeholder: "Eg. All customers are required to wear a mask, curbside pickup available, common surfaces sanitised regularly, etc.",
      className: `${classes.textField} ${classes.textBox}`,
      variant: "outlined",
      value: answers.covidPrecautions,
      onChange: (e) => e.target.value.length <= 255 && setAnswers(__spreadProps(__spreadValues({}, answers), { covidPrecautions: e.target.value }))
    }
  ), /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, answers.covidPrecautions.length, "/255")), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.jobReference,
      fullWidth: true,
      className: `${classes.formControl} ${classes.small}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, focused: false, className: classes.label }, "Job reference ID"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.jobReference,
        type: "number",
        className: classes.textField,
        variant: "outlined",
        value: answers.jobReference,
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { jobReference: e.target.value })),
        InputProps: {
          endAdornment: activeError === requiredRefs.jobReference && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
        }
      }
    )
  ), /* @__PURE__ */ React.createElement("h4", null, "Applicant qualifications"), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: classes.large, style: { marginTop: 14 } }, /* @__PURE__ */ React.createElement(Grid, { container: true }, /* @__PURE__ */ React.createElement(Grid, { item: true, xs: 10 }, /* @__PURE__ */ React.createElement("span", { className: classes.label }, "Include applicant questionnaire from your job.")), /* @__PURE__ */ React.createElement(Grid, { item: true, xs: 2 }, /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: answers.askQualifications ? "On" : "Off",
      control: /* @__PURE__ */ React.createElement(
        Switch,
        {
          checked: answers.askQualifications,
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { askQualifications: !answers.askQualifications }))
        }
      )
    }
  )))));
}
Step2.propTypes = jobAdPropTypes;
export default Step2;
