"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const lineHeight = 1.43;
const linesToShow = 3;
import { theme } from "../../../ThemeContext/ThemeObject";
import {
  statusClampCommonStyles,
  statusClampAfterStyles,
  statusClampDropdownStyles,
  statusClampDropdownAfterStyles,
  actionsMenuPaperCommonStyles,
  actionsMenuSpanCommonStyles,
  rightArrowStyles,
  leftArrowStyles,
  selectedTooltipCommonStyles
} from "../../shared/commonStyles";
import {
  notesEditSaveButton,
  notesCancelButton,
  editNotePopover,
  rowSelect,
  pagination,
  paginationContainer,
  rowSelectContainerAlt
} from "../../shared/styleClasses";
export const classes = {
  category: {
    marginBottom: theme.spacing(2),
    marginTop: 0
  },
  tableWrapper: {
    tableLayout: "auto !important",
    "& tbody": {
      "& td, th ": {
        borderWidth: "1px 0px 0px 0px",
        backgroundColor: "white"
      },
      "& tr:last-child td, th": {
        borderBottom: "none"
      }
    },
    "& .Mui-selected": {
      backgroundColor: "#F2F2F2"
    },
    "& tr": {
      "& td, th": {
        borderWidth: "0px",
        textTransform: "none"
      }
    },
    "& svg": {
      fontSize: "1.1rem"
    }
  },
  manageMetrics: {
    position: "absolute",
    top: 0,
    right: 0
  },
  tableHeaderRow: {
    "& td, th": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      border: "none",
      backgroundColor: "white",
      textTransform: "none",
      "& span": {
        color: theme.palette.primary.main,
        "&:hover": {
          color: "#0c74a4"
        }
      },
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    "& .Mui-selected": {
      backgroundColor: "#F2F2F2"
    },
    "& .MuiTableSortLabel-icon": {
      color: "darkgrey !important"
    }
  },
  tableContainer: {
    display: "relative",
    scrollBehavior: "smooth"
  },
  tableBody: {
    "& tr, td": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px"
    }
  },
  leftArrow: __spreadProps(__spreadValues({}, leftArrowStyles), {
    position: "fixed",
    zIndex: "999"
  }),
  rightArrow: __spreadProps(__spreadValues({}, rightArrowStyles), {
    position: "fixed",
    zIndex: "999"
  }),
  showArrows: {
    display: "flex",
    animation: "$fadeIn linear 0.17s"
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 0.9 }
  },
  iconRed: {
    color: theme.palette.error.main
  },
  iconGreen: {
    color: theme.palette.success.main
  },
  iconBlue: {
    color: theme.palette.primary.main
  },
  iconGray: {
    color: "#666666"
  },
  stateColumn: {
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "113px",
    height: "35px",
    borderRadius: "6px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  noteColumn: {
    display: "-webkit-box",
    height: `${lineHeight}*${linesToShow}`,
    margin: "0 auto",
    lineHeight,
    lineClamp: linesToShow,
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  link: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    color: theme.palette.primary.main
  },
  published: {
    color: "#8CCF95",
    backgroundColor: "RGBA(140, 207, 149, 0.12)"
  },
  draft: {
    color: "#E6A052",
    backgroundColor: "RGBA(230, 160, 82, 0.12)"
  },
  unpublished: {
    color: "#D6827D",
    backgroundColor: "RGBA(214, 130, 125, 0.12)"
  },
  saved: {
    color: "#FFFFFF",
    backgroundColor: "#CCCCCC"
  },
  closed: {
    color: "#FFFFFF",
    backgroundColor: "#CCCCCC"
  },
  stateDropdown: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "11px",
      minWidth: "145px",
      padding: "14px 0px 14px 20px",
      marginTop: "6px",
      borderRadius: "6px"
    },
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "13px",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#939393",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  stateLoader: {
    color: theme.palette.primary.main
  },
  stateErrorTooltip: {
    "& .MuiTooltip-tooltip": {
      textAlign: "center"
    }
  },
  statusClamp: __spreadProps(__spreadValues({}, statusClampCommonStyles), {
    width: "126px",
    padding: "6px 20px 5px 12px",
    "&::after": statusClampAfterStyles,
    "& .status-dropdown-full": __spreadProps(__spreadValues({}, statusClampDropdownStyles), {
      width: "126px",
      padding: "6px 20px 5px 12px",
      "&::after": statusClampDropdownAfterStyles
    }),
    "&:hover": {
      overflow: "visible",
      lineClamp: "unset",
      "& .status-dropdown-full": {
        visibility: "visible"
      }
    }
  }),
  noDropdown: {
    border: "1px solid #FFFFFF",
    "& .status-dropdown-full": {
      border: "1px solid #FFFFFF"
    },
    "&::after": {
      display: "none"
    }
  },
  noStatuses: {
    width: "100%",
    paddingLeft: "18px",
    display: "flex"
  },
  statusDropdown: {
    border: "1px solid #CCCCCC",
    borderRadius: "6px",
    cursor: "pointer"
  },
  statusDropdownItem: {
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F1F1F1"
    }
  },
  statusDropdownItems: {
    "& .MuiPaper-root": {
      width: "200px",
      maxHeight: "250px",
      overflowY: "auto",
      borderRadius: "6px",
      marginTop: "6px"
    }
  },
  statusLoader: {
    color: theme.palette.primary.main,
    position: "absolute",
    zIndex: "4",
    top: "calc(50% - 9px)",
    left: "calc(50% - 9px)"
  },
  rowSelectContainer: rowSelectContainerAlt,
  paginationContainer,
  pagination,
  rowSelect,
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  },
  titleCell: {
    position: "sticky",
    left: "206px",
    width: "200px",
    background: "white !important",
    paddingRight: "8px !important",
    zIndex: "3",
    "& a": {
      fontWeight: "bold",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      color: "#666666",
      wordBreak: "break-word !important"
    },
    "& a:hover": {
      textDecoration: "none"
    },
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      right: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      right: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  titleHeaderCell: {
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      right: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      right: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  actionsCell: {
    position: "sticky",
    right: 0,
    width: "70px !important",
    background: "white",
    zIndex: "3",
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      left: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      left: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  actionsHeaderCell: {
    position: "sticky",
    right: 0,
    width: "70px !important",
    background: "white",
    zIndex: "1",
    "&::after": {
      content: '""',
      background: "#aaaaaa",
      width: "2px",
      height: "100%",
      filter: "blur(2px)",
      position: "absolute",
      top: "0",
      left: "-2px",
      opacity: "0%",
      transition: "opacity 0.17s linear"
    },
    "&::before": {
      content: '""',
      top: "0",
      left: "0",
      width: "6px",
      height: "100%",
      position: "absolute",
      background: "#ffffff",
      zIndex: "2"
    }
  },
  cellAnimate: {
    "&&::after": {
      opacity: "20%"
    }
  },
  selectedTooltip: __spreadProps(__spreadValues({}, selectedTooltipCommonStyles), {
    justifyContent: "space-between",
    padding: "20px 50px",
    width: "665px",
    left: "calc(50% - 300px)"
  }),
  selectedTooltipText: {
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#666666"
  },
  selectedTooltipButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "17px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#D6827D",
    backgroundColor: "rgba(214, 130, 125, 0.22)",
    boxShadow: "none",
    width: "129px",
    height: "50px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "rgba(214, 130, 125, 0.33)",
      boxShadow: "none"
    }
  },
  selectedTooltipButtonsContainer: {
    display: "flex",
    columnGap: "18px"
  },
  fadeIn: {
    visibility: "visible",
    opacity: "1",
    transform: "translateX(0px)",
    transition: "visibility 0s linear 0s, opacity 300ms, transform 300ms"
  },
  fadeOut: {
    visibility: "hidden",
    opacity: "0",
    transform: "translateX(20px)",
    transition: "visibility 0s linear 300ms, opacity 300ms, transform 300ms"
  },
  actionsMenu: {
    "& .MuiPaper-root": __spreadProps(__spreadValues({}, actionsMenuPaperCommonStyles), {
      width: "135px",
      marginTop: "6px"
    }),
    "& span": actionsMenuSpanCommonStyles
  },
  editNotePopover,
  noteInput: {
    marginBottom: "4px"
  },
  notesButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    columnGap: "10px"
  },
  notesEditSaveButton,
  notesCancelButton,
  infoIcon: {
    color: "#084d6d",
    marginBottom: "3.5px",
    marginRight: "5px"
  },
  snackbar: {
    backgroundColor: "#5BC4BF",
    color: "#FFFFFF",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    maxWidth: "440px",
    textAlign: "center"
  },
  pinJobsButton: {
    width: "100px",
    height: "26px",
    borderRadius: "6px",
    backgroundColor: "rgba(230, 160, 82, 0.2)",
    color: "rgba(230, 160, 82)",
    textTransform: "none",
    fontFamily: "Source Sans Pro",
    fontSize: "10px",
    fontWeight: "700",
    position: "absolute",
    zIndex: 99,
    top: "28px",
    right: "0px",
    "&:hover": {
      backgroundColor: "rgba(230, 160, 82, 0.3)"
    }
  },
  pinJobsNotActive: {
    backgroundColor: "#EEEEEE",
    color: "#AAAAAA",
    "&:hover": {
      backgroundColor: "rgba(230, 160, 82, 0.2)",
      color: "rgba(230, 160, 82)"
    }
  }
};
