import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
export function handleDraftValue(e) {
  const contentBlock = htmlToDraft(e);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  return null;
}
