import React, { useEffect, useState, useMemo } from "react";
import { Box } from "@material-ui/core";
import useStyles from "../../../../../IndeedIntegration/AdEditor/useStyles";
import { jobAdPropTypes } from "../../../../../NewUI/Components/Multiposter/types";
function Step2({ answers, setAnswers, jobAdParams, requiredRefs, activeError }) {
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement(Box, { className: classes.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Preview job"));
}
Step2.propTypes = jobAdPropTypes;
export default Step2;
