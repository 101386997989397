"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  checkboxContainerStyles,
  filterPopoverHeaderCommonStyles
} from "../../shared/commonStyles";
import {
  applyButton,
  clearButton,
  filterPopoverContent,
  sortableSwitchLabels,
  switchLabels
} from "../../shared/styleClasses";
export const classes = {
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  popover: {
    marginTop: "15px"
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)"
  },
  switchLabels,
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  switchActive: {
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC"
    }
  },
  filterPopoverContent,
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  filterPopoverHeader: __spreadProps(__spreadValues({}, filterPopoverHeaderCommonStyles), {
    top: "0px"
  }),
  accordionRoot: {
    boxShadow: "none",
    margin: "14px 0px",
    "&::before": {
      display: "none"
    },
    "&:first-of-type": {
      marginTop: "0px !important"
    },
    "&.Mui-expanded": {
      marginTop: "14px 0px 0px 0px"
    }
  },
  accordionSummary: {
    minHeight: "0px !important",
    display: "inline-flex",
    padding: "0px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0px !important",
      paddingRight: "25px"
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "#084D6D",
      transform: "rotate(-90deg)",
      "&.Mui-expanded": {
        transform: "rotate(0deg)"
      },
      "& svg": {
        fontSize: "20px"
      }
    }
  },
  summaryTypography: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D"
  },
  accordionDetails: {
    padding: "6px 0px"
  },
  checkboxLabels: {
    justifyContent: "space-between",
    width: "174px",
    height: "25px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#939393"
    },
    "& .MuiCheckbox-colorPrimary": {
      color: "#DDDDDD",
      "&.Mui-checked": {
        color: "#5BC4C0"
      }
    },
    "& svg": {
      fontSize: "17px"
    }
  },
  uncheckedLabel: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontWeight: "600"
    }
  },
  clearButton,
  applyButton,
  multiSelectContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    marginBottom: "10px"
  },
  checkboxContainer: checkboxContainerStyles,
  dateDisplay: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#939393",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2px"
  },
  dateDisplayBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    borderRadius: "4px",
    border: "2px solid #DDDDDD",
    width: "88px",
    height: "36px"
  },
  dateInput: {
    "& .MuiOutlinedInput-root": {
      width: "88px",
      height: "31px",
      padding: "0px",
      transform: "translateY(2px)",
      "&:hover": {
        "& fieldset": {
          borderColor: "#BBBBBB"
        }
      }
    },
    "& fieldset": {
      border: "2px solid #DDDDDD",
      borderColor: "#DDDDDD",
      borderRadius: "4px",
      "& legend": {
        display: "none"
      }
    },
    "& input": {
      fontSize: "12px",
      color: "#939393",
      padding: "0px",
      paddingLeft: "12px",
      paddingBottom: "4px"
    },
    "& button": {
      display: "none"
    }
  },
  invalidDate: {
    "& .MuiOutlinedInput-root": {
      "&:hover": {
        "& fieldset": {
          borderColor: "#E60B0B !important"
        }
      },
      "& fieldset": {
        borderColor: "#E60B0B"
      }
    }
  },
  tableSettingsHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: "8px",
    paddingLeft: "4px"
  },
  sortableField: {
    display: "flex",
    alignItems: "center",
    width: "204px"
  },
  sortableSwitchLabels
};
