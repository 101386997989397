export const handleNext = (type, checkErrors, requiredRefs, setActiveError, submit) => {
  const errorRef = checkErrors();
  if (errorRef) {
    const ref = requiredRefs[errorRef];
    setActiveError(ref);
    window.scrollTo({ top: ref.current.offsetTop - 120, behavior: "smooth" });
  } else {
    submit(type);
  }
};
