import React, { useState, Dispatch } from 'react';
import {
  CircularProgress,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import OldIntegrationsLink from './OldIntegrationsLink';
import ExportButton from './ExportButton';
import OnboardExpressLogo from './Logos/OnboardExpressLogo.png';
import XrefLogo from './Logos/XrefLogo.png';
import MvsLogo from './Logos/MvsLogo.png';
import ReferooLogo from './Logos/ReferooLogo.png';
import FoundULogo from './Logos/FoundULogo.png';
import TestgridLogo from './Logos/TestgridLogo.png';
import HronboardLogo from './Logos/HronboardLogo.png';
import RoublerLogo from './Logos/RoublerLogo.png';
import { ApplicationState, ApplicationAction } from '../types';
import { ModalType } from '../config';
import OnboardExpress from './Modals/OnboardExpress';
import XRef from './Modals/XRef';
import Mvs from './Modals/Mvs';
import Referoo from './Modals/Referoo';
import FoundU from './Modals/FoundU';
import Testgrid from './Modals/Testgrid';
import Hronboard from './Modals/Hronboard';
import Roubler from './Modals/Roubler';
import { ScoutOnboardLogo } from './Logos/Logos';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { IUserPermissions } from '../../Components/sharedTypes';
import { styles } from './styles';
import { IApplication } from '../types';
import moment from 'moment-timezone';
import Api from '../API';
import { theme } from '../../../../components/ThemeContext/ThemeObject';
import UnusualActivity from './Modals/UnusualActivity';
import AlreadyExportedModal from './Modals/AlreadyExportedModal';

export default function IntegrationsTab({
  ApplicationState,
  dispatch,
  newIntegrationEnabled
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  newIntegrationEnabled: boolean;
}) {
  const [alreadyExportedModalOpen, setAlreadyExportedModalOpen] = useState<{
    revolve: boolean;
    referoo: boolean;
    hro: boolean;
  }>({ revolve: false, referoo: false, hro: false });
  const { modalsOpen, userTimeZone } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  const formatDate = (date: string) =>
    moment
      .tz(
        date,
        application?.job?.time_zone ||
          userTimeZone ||
          Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      .format('ddd, D MMM YYYY, hh:mma z');

  const { data: integrationsData, isRefetching: refetchingIntegrationsData } = useQuery({
    queryKey: ['integrations'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getIntegrationsData(application.id);
        return res;
      }
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting integrations data, ${error}`,
          state: 'error'
        }
      });
    }
  });

  const UpdateStatusIcon = () => (
    <Tooltip title="Update status" placement="top" arrow>
      <IconButton
        sx={{
          padding: 0.5,
          '&:focus': {
            outline: 'none'
          }
        }}
        onClick={() => queryClient.refetchQueries(['integrations'])}
      >
        {refetchingIntegrationsData ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <RefreshIcon fontSize="small" sx={{ color: '#666666' }} />
        )}
      </IconButton>
    </Tooltip>
  );

  if (!application) return null;

  return (
    <Stack sx={{ rowGap: 5 }}>
      <Stack sx={{ flexDirection: 'row', columnGap: 4, flexWrap: 'wrap', rowGap: 4 }}>
        {permissions?.Integrations?.['Export To Revolve Onboard'] && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={styles.integrationCardImageContainer}
              component="img"
              src={OnboardExpressLogo}
            />
            <ExportButton
              onClick={() =>
                application.revolve_record_id
                  ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, revolve: true })
                  : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ONBOARD_EXPRESS })
              }
            />
          </Stack>
        )}
        {permissions?.Integrations?.['Export To SCOUT Onboard'] && (
          <Stack sx={styles.integrationCard}>
            <Stack sx={styles.integrationCardImageContainer}>
              <ScoutOnboardLogo />
            </Stack>
            <ExportButton
              onClick={() =>
                dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.EXPORT_TO_ONBOARD })
              }
            />
          </Stack>
        )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.['Xref (Export)'] &&
          application?.integrations?.xref && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, width: '4rem' }}
                component="img"
                src={XrefLogo}
              />
              <ExportButton
                onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.XREF })}
              />
            </Stack>
          )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.['My Verification Service (MVS)'] &&
          application?.integrations?.mvs && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, width: '4rem' }}
                component="img"
                src={MvsLogo}
              />
              <ExportButton
                onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.MVS })}
              />
            </Stack>
          )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.Referoo &&
          application?.integrations?.referoo && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, width: '5.5rem' }}
                component="img"
                src={ReferooLogo}
              />
              <ExportButton
                onClick={() => {
                  if (integrationsData?.referoo_login_url)
                    window.open(integrationsData?.referoo_login_url);
                  else {
                    integrationsData?.referoo_record
                      ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, referoo: true })
                      : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.REFEROO });
                  }
                }}
              />
            </Stack>
          )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.FoundU &&
          application?.integrations?.foundu && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, width: '3.5rem' }}
                component="img"
                src={FoundULogo}
              />
              <ExportButton
                onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.FOUNDU })}
              />
            </Stack>
          )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.TestGrid &&
          application?.integrations?.test_grid && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, padding: '8px 0px', width: '5rem' }}
                component="img"
                src={TestgridLogo}
              />
              <ExportButton
                onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.TESTGRID })}
              />
            </Stack>
          )}
        {newIntegrationEnabled && permissions?.Integrations?.['Export To HR Onboard'] && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, padding: '8px 0px', width: '7rem' }}
              component="img"
              src={HronboardLogo}
            />
            <ExportButton
              onClick={() =>
                integrationsData?.hro_record_exported
                  ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, hro: true })
                  : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.HRONBOARD })
              }
            />
          </Stack>
        )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.['Roubler Integration'] &&
          application?.integrations?.roubler && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, padding: '8px 0px', width: '6rem' }}
                component="img"
                src={RoublerLogo}
              />
              <ExportButton
                onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ROUBLER })}
              />
            </Stack>
          )}
      </Stack>
      {newIntegrationEnabled &&
        permissions?.Integrations?.Referoo &&
        application?.integrations?.referoo &&
        integrationsData?.referoo_record && (
          <Stack sx={styles.integrationDetails}>
            <Stack
              sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Referoo requested checks</Stack>
              <UpdateStatusIcon />
            </Stack>
            <Table sx={styles.integrationDetailsTable}>
              <TableHead>
                <TableRow>
                  {[
                    'Submitted at',
                    'References required',
                    'Reference ID',
                    'Candidate status',
                    'Referees status'
                  ].map((title, index) => (
                    <TableCell sx={styles.integrationDetailsTableHeader} key={index}>
                      <Stack>{title}</Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ verticalAlign: 'top', minWidth: '120px' }}>
                    {formatDate(integrationsData.referoo_record.created_at)}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top', minWidth: '150px' }}>
                    {integrationsData.referoo_record.number_of_references_required}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top', minWidth: '110px' }}>
                    {integrationsData.referoo_record.referoo_id}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top', minWidth: '120px' }}>
                    {integrationsData.referoo_record.candidate_status}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <Stack sx={{ rowGap: 2 }}>
                      {integrationsData.referoo_record.referees_status ? (
                        <>
                          {integrationsData.referoo_record.referees_status.map((status, index) => (
                            <Stack key={index} sx={{ rowGap: 1 }}>
                              <Stack>{status.referee}</Stack>
                              {status.report_path && (
                                <Link
                                  href={
                                    integrationsData.referoo_record?.login_url || status.report_path
                                  }
                                  target="_blank"
                                  style={{
                                    color: 'inherit',
                                    width: 'fit-content',
                                    textDecoration: 'underline'
                                  }}
                                >
                                  Download report
                                </Link>
                              )}
                            </Stack>
                          ))}
                        </>
                      ) : (
                        '-'
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        )}
      {newIntegrationEnabled &&
        permissions?.Integrations?.['Xref (View)'] &&
        !!integrationsData?.xref_records.length && (
          <Stack sx={styles.integrationDetails}>
            <Stack
              sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Xref requested checks</Stack>
              <UpdateStatusIcon />
            </Stack>
            <Table sx={styles.integrationDetailsTable}>
              <TableHead>
                <TableRow>
                  {['Submitted at', 'Package name', 'Reference ID', 'Status', 'Referees'].map(
                    (title, index) => (
                      <TableCell sx={styles.integrationDetailsTableHeader} key={index}>
                        <Stack>{title}</Stack>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {integrationsData?.xref_records.map((record, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      {formatDate(record.created_at)}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>{record.package_name}</TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>{record.reference_id}</TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>{record.state}</TableCell>
                    <TableCell>
                      {record.referees?.length ? (
                        <Stack sx={{ rowGap: 2 }}>
                          {record.referees.map((referee, index) => (
                            <Stack key={index} sx={{ rowGap: 1 }}>
                              {referee.first_name} {referee.last_name} - {referee.state}
                              {referee.report_path && (
                                <Link
                                  href={referee.report_path}
                                  target="_blank"
                                  style={{
                                    color: 'inherit',
                                    width: 'fit-content',
                                    textDecoration: 'underline'
                                  }}
                                >
                                  View Report
                                </Link>
                              )}
                              {referee.unusual_activities && (
                                <Stack
                                  sx={{
                                    color: theme.palette.error.main,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    width: 'fit-content'
                                  }}
                                  onClick={() => {
                                    dispatch({
                                      type: 'SET_MODALS_OPEN',
                                      payload: ModalType.UNUSUAL_ACTIVITY
                                    });
                                    dispatch({
                                      type: 'SET_UNUSUAL_ACTIVITY_DATA',
                                      payload: {
                                        referenceId: record.reference_id,
                                        selectedReferee: referee
                                      }
                                    });
                                  }}
                                >
                                  Unusual Activity
                                </Stack>
                              )}
                            </Stack>
                          ))}
                        </Stack>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        )}
      {newIntegrationEnabled &&
        permissions?.Integrations?.['My Verification Service (MVS)'] &&
        application?.integrations?.mvs &&
        !!integrationsData?.mvs_requests.length && (
          <Stack sx={styles.integrationDetails}>
            <Stack
              sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>MVS requested tests</Stack>
            </Stack>
            <Table sx={styles.integrationDetailsTable}>
              <TableHead>
                <TableRow>
                  {['Package name', 'Checks', 'Status', 'Requested at'].map((title, index) => (
                    <TableCell sx={styles.integrationDetailsTableHeader} key={index}>
                      <Stack>{title}</Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {integrationsData?.mvs_requests.map((request, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ verticalAlign: 'top' }}>{request.package_name}</TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      {request.blueprint_ids.join(', ')}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>{request.status}</TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      {formatDate(request.created_at)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        )}
      <OldIntegrationsLink />
      {modalsOpen === ModalType.ONBOARD_EXPRESS && (
        <OnboardExpress ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.XREF && (
        <XRef ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.MVS && (
        <Mvs ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.REFEROO && (
        <Referoo ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.FOUNDU && (
        <FoundU ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.TESTGRID && (
        <Testgrid ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.HRONBOARD && (
        <Hronboard ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.ROUBLER && (
        <Roubler ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      <AlreadyExportedModal
        alreadyExportedModalOpen={alreadyExportedModalOpen}
        onClose={() => setAlreadyExportedModalOpen({ revolve: false, referoo: false, hro: false })}
        dispatch={dispatch}
      />
      {modalsOpen === ModalType.UNUSUAL_ACTIVITY && (
        <UnusualActivity ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
    </Stack>
  );
}
