"use strict";
import { closeIcon } from "../../NewUI/shared/styleClasses";
export const classes = {
  aiModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "60px",
    maxWidth: "1200px",
    width: "100%",
    height: "94vh",
    maxHeight: "844px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  closeIcon,
  aiQuestionsContainer: {
    paddingTop: "30px",
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto"
  },
  genericContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%"
  },
  fieldHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "38px",
    fontWeight: "700",
    color: "#2D388D",
    marginBottom: "36px"
  },
  fieldSubHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#2D388D",
    marginBottom: "16px"
  },
  fieldInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "regular",
    borderRadius: "6px",
    height: "42px",
    width: "100%",
    "& legend": {
      display: "none"
    },
    "& input": {
      padding: "0px 20px 3.5px 20px",
      color: "#333333"
    },
    "& fieldset": {
      borderColor: "#DDDDDD"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADADA !important"
      }
    }
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "auto"
  },
  felixMessageContainer: {
    display: "flex",
    columnGap: "26px",
    alignItems: "center"
  },
  felixMessage: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#2D388D",
    maxWidth: "340px",
    "& span": {
      color: "#AFBCFF"
    }
  },
  footerButtonsContainer: {
    display: "flex",
    columnGap: "32px"
  },
  footerContinueButton: {
    textTransform: "none",
    height: "40px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "700",
    color: "#FFFFFF",
    backgroundColor: "#7A8EF1",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#2D388D"
    },
    "&:disabled": {
      backgroundColor: "#CCCCCC",
      color: "#DDDDDD"
    }
  },
  descriptionHeader: {
    marginBottom: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  descriptionHeaderText: {
    fontFamily: "Source Sans Pro",
    fontSize: "38px",
    fontWeight: "700",
    color: "#2D388D"
  },
  question: {
    width: "100%",
    display: "flex",
    columnGap: "26px",
    padding: "20px",
    background: "rgba(122, 142, 241, 0.12)",
    borderRadius: "6px",
    color: "#2D388D",
    fontFamily: "Source Sans Pro",
    fontSize: "18px"
  },
  footerAddButton: {
    textTransform: "none",
    height: "40px",
    minWidth: "97px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "700",
    color: "#FFFFFF",
    backgroundColor: "#7A8EF1",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#2D388D"
    },
    "&:disabled": {
      backgroundColor: "#CCCCCC",
      color: "#DDDDDD"
    }
  },
  footerBackButton: {
    textTransform: "none",
    textDecoration: "underline",
    height: "40px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#CCCCCC",
    backgroundColor: "#FFFFFF",
    borderRadius: "20px",
    border: "none"
  },
  pill: {
    textTransform: "none",
    height: "36px",
    width: "auto",
    paddingInline: "17px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "700",
    color: "#AFBCFF",
    border: "1px solid #AFBCFF",
    borderRadius: "18px"
  },
  checkbox: {
    color: "#939393 !important",
    "& .MuiSvgIcon-root": {
      fontSize: "24px"
    },
    "&.Mui-checked": {
      color: "#939393 !important"
    }
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "24px",
    rowGap: "28px",
    width: "100%",
    position: "relative",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  }
};
