"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  actionsMenuPaperCommonStyles,
  actionsMenuSpanCommonStyles
} from "../NewUI/shared/commonStyles";
import { theme } from "../ThemeContext/ThemeObject";
export const classes = {
  reportPageContainer: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "#FFFFFF",
    padding: "40px"
  },
  reportPageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 3,
    "& h1": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "38px",
      color: "#666666",
      marginTop: "0"
    }
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "#EEEEEE",
    margin: "24px 0"
  },
  tabs: {
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      letterSpacing: "0",
      color: "#939393",
      textTransform: "none",
      minWidth: "unset",
      "&:hover": {
        color: "#737373"
      },
      "&:focus": {
        outline: "none"
      }
    },
    "& button.Mui-selected": {
      fontWeight: "600",
      color: "#084D6D"
    }
  },
  tabIndicator: {
    backgroundColor: "#5BC4BF",
    margin: "0 16px"
  },
  csvDownloadButton: {
    background: "rgba(140, 207, 149, 0.12)",
    color: "#8CCF95",
    fontWeight: 700,
    textTransform: "none",
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    lineHeight: "18px",
    padding: "13px 15px",
    "&:hover": {
      background: "rgba(140, 207, 149, 1)",
      color: "#fff"
    }
  },
  starFilled: {
    ".MuiSvgIcon-colorPrimary": {
      color: "#FFC107"
    }
  },
  starEmpty: {
    "& .MuiSvgIcon-root": {
      fill: "#FFFFFF"
    }
  },
  actionsMenu: {
    "& .MuiPaper-root": __spreadProps(__spreadValues({}, actionsMenuPaperCommonStyles), {
      width: "160px",
      marginTop: "6px"
    }),
    "& span": actionsMenuSpanCommonStyles
  },
  actionButtonSection: {
    display: "flex",
    justifyContent: "end",
    "& svg": {
      color: "#666666",
      fontSize: "18px",
      cursor: "pointer"
    },
    columnGap: 3
  },
  actionButtonIcons: {
    margin: "auto 0"
  },
  exitButton: {
    borderRadius: "6px",
    fontFamily: "Source Sans Pro",
    padding: "8px 19px",
    textTransform: "none",
    fontSize: "14px",
    boxShadow: "none",
    fontWeight: "bold",
    background: theme.palette.error.main,
    color: theme.palette.error.light,
    "&:hover": {
      background: theme.palette.error.dark,
      boxShadow: "none"
    }
  },
  disclaimerContainer: {
    padding: 2,
    border: `1px solid ${theme.palette.action.main}`,
    borderRadius: "8px",
    columnGap: 1,
    flexDirection: "row",
    fontSize: "16px"
  },
  disclaimerIcon: {
    color: theme.palette.action.main,
    marginTop: "4px",
    fontSize: "16px"
  }
};
