var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  FormControl,
  Switch,
  FormLabel,
  FormHelperText,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormGroup,
  Checkbox,
  Grid
} from "@material-ui/core";
import sharedStyles from "../../../../../IndeedIntegration/AdEditor/useStyles";
import usestyles from "../useStyles";
import { jobAdPropTypes } from "../../../../../NewUI/Components/Multiposter/types";
import ErrorIcon from "@material-ui/icons/Error";
import TextEditor from "../../../../../NewUI/Components/CustomUIElements/TextEditor";
import AddressInput from "../../../../../NewUI/Components/CustomUIElements/AddressInput";
const MAX_KEY_SELLING_POINTS = 3;
const MAX_CHAR_LIMIT = 80;
function Step1({ answers, setAnswers, jobAdParams, requiredRefs, activeError }) {
  var _a, _b;
  const [value, setValue] = React.useState({
    description: [answers.address].filter((cur) => cur).join(", ")
  });
  const sharedClasses = sharedStyles();
  const classes = usestyles();
  function handleMultiCheck(value2, state, key) {
    state.includes(value2) ? setAnswers(__spreadProps(__spreadValues({}, answers), { [key]: state.filter((id) => id !== value2) })) : setAnswers(__spreadProps(__spreadValues({}, answers), { [key]: [...answers[key], value2] }));
  }
  return /* @__PURE__ */ React.createElement("div", { className: sharedClasses.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Feature Job on Viewjobs"), /* @__PURE__ */ React.createElement(Box, { className: classes.switchContainer }, /* @__PURE__ */ React.createElement(FormLabel, null, "From $350 + GST, boost visibility and attract more candidates."), /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      control: /* @__PURE__ */ React.createElement(
        Switch,
        {
          checked: answers.featured,
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { featured: !answers.featured })),
          color: "secondary"
        }
      ),
      label: ""
    }
  )), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.title,
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: sharedClasses.label }, "Job Title"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.title,
        className: sharedClasses.textField,
        variant: "outlined",
        value: answers.title,
        onChange: (e) => e.target.value.length <= 52 && setAnswers(__spreadProps(__spreadValues({}, answers), { title: e.target.value })),
        InputProps: {
          endAdornment: activeError === requiredRefs.title && /* @__PURE__ */ React.createElement("div", { className: sharedClasses.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
        }
      }
    ),
    /* @__PURE__ */ React.createElement(FormHelperText, { className: sharedClasses.helperText }, answers.title.length, "/52")
  ), /* @__PURE__ */ React.createElement(
    Box,
    {
      ref: requiredRefs.description,
      className: `${sharedClasses.label} ${classes.descriptionLabel}`
    },
    "Job description",
    /* @__PURE__ */ React.createElement("span", null, "*"),
    activeError === requiredRefs.description && /* @__PURE__ */ React.createElement("div", { className: sharedClasses.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.large}`,
      style: { marginTop: 5 }
    },
    /* @__PURE__ */ React.createElement(
      TextEditor,
      {
        editorState: answers.description,
        setAnswers,
        answers,
        activeError,
        requiredRef: requiredRefs.description,
        classes: sharedClasses
      }
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.classification,
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: sharedClasses.label }, "Classification"),
    /* @__PURE__ */ React.createElement(
      Select,
      {
        classes: { select: sharedClasses.input },
        variant: "outlined",
        value: answers.classification,
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { classification: e.target.value })),
        displayEmpty: true,
        renderValue: (value2) => value2 || /* @__PURE__ */ React.createElement("span", { className: sharedClasses.placeholder }, "Select an option"),
        endAdornment: activeError === requiredRefs.classification && /* @__PURE__ */ React.createElement("div", { className: sharedClasses.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
      },
      jobAdParams.classifications.map((classification) => /* @__PURE__ */ React.createElement(MenuItem, { key: classification[1], value: classification[0] }, classification[0]))
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.workType,
      fullWidth: true,
      className: `${sharedClasses.formControl}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: sharedClasses.label }, "Work type", activeError === requiredRefs.workType && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        value: answers.workType,
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { workType: e.target.value }))
      },
      jobAdParams.worktypes.map((type) => /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          key: type[1],
          label: type[0],
          value: type[0],
          control: /* @__PURE__ */ React.createElement(Radio, { color: "secondary" })
        }
      ))
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.workLocation,
      fullWidth: true,
      className: `${sharedClasses.formControl}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: sharedClasses.label }, "Work location", activeError === requiredRefs.workLocation && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        value: answers.workLocation,
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { workLocation: e.target.value }))
      },
      jobAdParams.worklocations.map((location) => /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          key: location[1],
          label: location[0],
          value: location[0],
          control: /* @__PURE__ */ React.createElement(Radio, { color: "secondary" })
        }
      ))
    )
  ), /* @__PURE__ */ React.createElement(
    AddressInput,
    {
      requiredRef: requiredRefs.address,
      classes: sharedClasses,
      value,
      setValue,
      activeError: activeError === requiredRefs.address,
      answers,
      setAnswers,
      viewJobsAd: true
    }
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.workLocation,
      fullWidth: true,
      className: `${sharedClasses.formControl}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: sharedClasses.label }, "Pay details", activeError === requiredRefs.payType && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        value: answers.payType,
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { payType: e.target.value }))
      },
      jobAdParams.paytypes.map((type) => /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          key: type[1],
          label: type[0],
          value: type[0],
          control: /* @__PURE__ */ React.createElement(Radio, { color: "secondary" })
        }
      ))
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: sharedClasses.label }, "Benefits"),
    /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement(Grid, { container: true }, jobAdParams.extrabenefits.map((benefit) => {
      var _a2;
      return /* @__PURE__ */ React.createElement(Grid, { key: benefit[1], item: true, xs: 6 }, /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          label: benefit[0],
          value: benefit[0],
          control: /* @__PURE__ */ React.createElement(
            Checkbox,
            {
              checked: (_a2 = answers == null ? void 0 : answers.benefits) == null ? void 0 : _a2.includes(benefit[0]),
              onChange: (e) => handleMultiCheck(e.target.value, answers.benefits, "benefits")
            }
          )
        }
      ));
    })))
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: false, className: sharedClasses.label }, "Short Summary (Optional)"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        className: sharedClasses.textField,
        variant: "outlined",
        value: answers.jobPreview.summary,
        onChange: (e) => e.target.value.length <= 150 && setAnswers(__spreadProps(__spreadValues({}, answers), {
          jobPreview: __spreadProps(__spreadValues({}, answers.jobPreview), { summary: e.target.value })
        }))
      }
    ),
    /* @__PURE__ */ React.createElement(FormHelperText, { className: sharedClasses.helperText }, ((_b = (_a = answers.jobPreview) == null ? void 0 : _a.summary) == null ? void 0 : _b.length) || 0, "/150")
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: false, className: sharedClasses.label }, "Key Selling Points (Optional)"),
    /* @__PURE__ */ React.createElement("span", { className: sharedClasses.subLabel }, "Enter ", MAX_KEY_SELLING_POINTS, " key selling points to attract candidates to view your role"),
    Array.from({ length: MAX_KEY_SELLING_POINTS }).map((_, index) => {
      var _a2, _b2;
      return /* @__PURE__ */ React.createElement(
        FormControl,
        {
          key: index,
          fullWidth: true,
          className: `${sharedClasses.formControl} ${sharedClasses.medium}`
        },
        /* @__PURE__ */ React.createElement(
          TextField,
          {
            className: sharedClasses.textField,
            variant: "outlined",
            value: answers.jobPreview[`key${index + 1}`],
            onChange: (e) => {
              if (e.target.value.length <= MAX_CHAR_LIMIT) {
                setAnswers(__spreadProps(__spreadValues({}, answers), {
                  jobPreview: __spreadProps(__spreadValues({}, answers.jobPreview), { [`key${index + 1}`]: e.target.value })
                }));
              }
            }
          }
        ),
        /* @__PURE__ */ React.createElement(FormHelperText, { className: sharedClasses.helperText }, ((_b2 = (_a2 = answers.jobPreview) == null ? void 0 : _a2[`key${index + 1}`]) == null ? void 0 : _b2.length) || 0, "/", MAX_CHAR_LIMIT)
      );
    })
  ));
}
Step1.propTypes = jobAdPropTypes;
export default Step1;
