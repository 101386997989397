"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../components/ThemeContext/ThemeObject";
import { leftArrowStyles, rightArrowStyles } from "../components/NewUI/shared/commonStyles";
import {
  pagination,
  paginationContainer,
  rowSelect,
  rowSelectContainerAlt,
  scrollShadowLeft,
  scrollShadowRight,
  searchInput
} from "../components/NewUI/shared/styleClasses";
export const styles = {
  searchInput,
  rowSelectContainer: rowSelectContainerAlt,
  paginationContainer,
  pagination,
  rowSelect,
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  },
  tableWrapper: {
    tableLayout: "auto !important",
    minWidth: "1000px",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none"
    },
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    tr: {
      fontSize: "16px",
      borderBottom: "1px solid #DDDDDD"
    }
  },
  skeletonContainer: {
    borderBottom: "1px solid #DDDDDD",
    padding: "10px 0px"
  },
  noRecords: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  arrowsContainer: {
    height: 0,
    overflow: "visible",
    position: "sticky",
    top: "200px",
    zIndex: "99",
    transform: "translateY(100px)"
  },
  leftArrow: __spreadProps(__spreadValues({}, leftArrowStyles), {
    position: "sticky",
    zIndex: "999"
  }),
  rightArrow: __spreadProps(__spreadValues({}, rightArrowStyles), {
    position: "sticky",
    zIndex: "999"
  }),
  showArrows: {
    display: "flex",
    animation: "$fadeIn linear 0.17s"
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 0.9 }
  },
  stickyRight: {
    position: "sticky",
    right: "0px",
    background: "white"
  },
  scrollShadowLeft,
  scrollShadowRight,
  scrollShadowVisible: {
    "&&::after": {
      opacity: "20%"
    }
  },
  attachmentsHeader: {
    minWidth: "86px"
  },
  attachmentsCell: {
    minWidth: "86px",
    width: "50px",
    "& svg": {
      color: "#666666",
      fontSize: "18px",
      cursor: "pointer"
    }
  },
  actionItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  attachmentItem: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    padding: "14px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  sticky: (prevLeft = 0) => ({
    position: "sticky",
    left: `${prevLeft}px`,
    background: "white",
    zIndex: 1
  })
};
