"use strict";
import { useState } from "react";
import { arrayMove } from "@dnd-kit/sortable";
export const useFieldChoiceList = () => {
  const [choiceList, setChoiceList] = useState([
    { id: "", _destroy: 0, position: 0, name: "" }
  ]);
  const addChoice = () => {
    const newTempId = Date.now();
    setChoiceList([...choiceList, { id: newTempId, _destroy: 0, position: null, name: "" }]);
  };
  const removeChoice = (index) => {
    const list = [...choiceList];
    if (list[index].position !== void 0 && list[index].position !== null) {
      list[index]._destroy = 1;
    } else {
      list.splice(index, 1);
    }
    setChoiceList(list);
  };
  const clearChoices = () => {
    setChoiceList([{ id: "", _destroy: 0, position: 0, name: "" }]);
  };
  const updateChoiceAttr = (value, index, attribute) => {
    const list = [...choiceList];
    list[index][attribute] = value;
    setChoiceList(list);
  };
  const updateChoice = (value, index) => updateChoiceAttr(value, index, "name");
  const updateRank = (value, index) => updateChoiceAttr(value, index, "rank");
  const updateRankValue = (value, index) => updateChoiceAttr(value, index, "rank_value");
  const updateRating = (value, index) => updateChoiceAttr(value, index, "rating");
  const switchChoices = (oldId, newId) => {
    if (oldId === newId) {
      return;
    }
    const oldIndex = choiceList.map((c) => c.id).indexOf(oldId);
    const newIndex = choiceList.map((c) => c.id).indexOf(newId);
    setChoiceList(arrayMove(choiceList, oldIndex, newIndex));
  };
  return {
    choiceList,
    setChoiceList,
    addChoice,
    removeChoice,
    clearChoices,
    updateChoice,
    switchChoices,
    updateRank,
    updateRankValue,
    updateRating
  };
};
