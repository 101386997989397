import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  switchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  },
  descriptionLabel: {
    marginTop: 50
  },
  textField: {
    marginTop: 10
  }
}));
