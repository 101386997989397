var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect } from "react";
import {
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Checkbox,
  TextField,
  Radio
} from "@material-ui/core";
import useStyles from "../useStyles";
import PropTypes from "prop-types";
import ErrorIcon from "@material-ui/icons/Error";
import HtmlEditor from "../../../NewUI/Components/Utilities/HtmlEditor";
function Step3({
  answers,
  setAnswers,
  activeError,
  requiredRefs,
  linkOutEnabled,
  privacyOverLimit,
  activeErrorMessage,
  jobId,
  setActiveError,
  setActiveErrorMessage,
  job_url_prop
}) {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return /* @__PURE__ */ React.createElement("div", { className: classes.stepContainer }, /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.title,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Job Title"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.title,
        className: classes.textField,
        variant: "outlined",
        value: answers.title,
        onChange: (e) => e.target.value.length >= 0 && setAnswers(__spreadProps(__spreadValues({}, answers), { title: e.target.value })),
        InputProps: {
          endAdornment: activeError === requiredRefs.title && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
        }
      }
    )
  ), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.editor}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Ad Details", /* @__PURE__ */ React.createElement("span", { className: classes.asterisk }, " *"), activeError === requiredRefs.ad_details && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))), /* @__PURE__ */ React.createElement(
    HtmlEditor,
    {
      body: answers.ad_details,
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { ad_details: e }))
    }
  ), /* @__PURE__ */ React.createElement("div", null, "Supported formatting styles: headings (H2), Italics, Bold, Underline, Bullet Points/Numbered List"), /* @__PURE__ */ React.createElement("div", null, "NOTE: If your job description contains unsupported formatting styles, they will be removed from the Ad Details section.")), /* @__PURE__ */ React.createElement("h4", null, "Candidate Application Options"), linkOutEnabled && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    RadioGroup,
    {
      value: answers.link_out,
      onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { link_out: true }))
    },
    /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        label: "Apply through :Recruit Talent Landing Page",
        checked: answers.link_out === true,
        control: /* @__PURE__ */ React.createElement(Radio, null)
      }
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      style: { margin: "0.5rem 0 1.5rem 0" },
      className: `${classes.formControl} ${classes.large}`
    },
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        className: classes.textField,
        style: { marginLeft: "1rem" },
        variant: "outlined",
        disabled: answers.link_out === false,
        value: answers.application_url || "",
        onChange: (e) => e.target.value.length >= 0 && setAnswers(__spreadProps(__spreadValues({}, answers), { application_url: e.target.value }))
      }
    )
  )), linkOutEnabled && /* @__PURE__ */ React.createElement(
    RadioGroup,
    {
      value: answers.link_out,
      onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { link_out: false }))
    },
    /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        ref: requiredRefs.link_out,
        label: "Apply through SEEK",
        checked: answers.link_out === false,
        disabled: answers.link_out && answers.advertisement_id,
        control: /* @__PURE__ */ React.createElement(Radio, null)
      }
    ),
    activeError === requiredRefs.link_out && activeErrorMessage && activeErrorMessage.length >= 1 && /* @__PURE__ */ React.createElement("span", null, activeErrorMessage.map((error, i) => /* @__PURE__ */ React.createElement(
      "div",
      {
        style: { display: "flex", marginTop: "0.5rem" },
        className: classes.errorMessage,
        key: i
      },
      /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }),
      /* @__PURE__ */ React.createElement("div", { className: "seekValidationErrorMsg" }, error, ".")
    )), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classes.reviewQuestionText,
        onClick: () => {
          window.open(`${window.location.origin}/admin/jobs/${jobId}/fields`, "_blank").focus();
          setActiveError(null);
          setActiveErrorMessage(null);
        }
      },
      "Review questions"
    ))
  ), !answers.link_out && linkOutEnabled && /* @__PURE__ */ React.createElement(RadioGroup, { value: answers.posting_questionnaire, className: classes.secondaryRadioGroup }, /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: "Include application questionnaire",
      checked: answers.posting_questionnaire,
      disabled: answers.advertisement_id,
      className: classes.secondaryRadio,
      control: /* @__PURE__ */ React.createElement(
        Radio,
        {
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { posting_questionnaire: true, link_out: false }))
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: "Do not include application questionnaire",
      checked: !answers.posting_questionnaire,
      disabled: answers.advertisement_id,
      className: classes.secondaryRadio,
      control: /* @__PURE__ */ React.createElement(
        Radio,
        {
          className: classes.secondaryRadio,
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { posting_questionnaire: false, link_out: false }))
        }
      )
    }
  ), /* @__PURE__ */ React.createElement("div", { className: classes.optionHint }, /* @__PURE__ */ React.createElement("strong", null, "Please note: "), answers.posting_questionnaire && /* @__PURE__ */ React.createElement("span", null, "All questions will be mandatory and cannot be changed once this option has been selected and the ad has been published. Please click", " ", /* @__PURE__ */ React.createElement("a", { href: job_url_prop, target: "_blank", rel: "noreferrer" }, "HERE"), " ", "to alter questions if required before posting."), !answers.posting_questionnaire && /* @__PURE__ */ React.createElement("span", null, "Candidates will receive an email to fill out the missing questions after applying."))), !linkOutEnabled && /* @__PURE__ */ React.createElement(RadioGroup, { value: answers.posting_questionnaire }, /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: "Include application questionnaire",
      checked: answers.posting_questionnaire,
      disabled: answers.advertisement_id,
      className: classes.secondaryRadio,
      control: /* @__PURE__ */ React.createElement(
        Radio,
        {
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { posting_questionnaire: true, link_out: false }))
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      label: "Do not include application questionnaire",
      checked: !answers.posting_questionnaire,
      disabled: answers.advertisement_id,
      className: classes.secondaryRadio,
      control: /* @__PURE__ */ React.createElement(
        Radio,
        {
          className: classes.secondaryRadio,
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { posting_questionnaire: false, link_out: false }))
        }
      )
    }
  ), /* @__PURE__ */ React.createElement("div", { className: classes.optionHint }, /* @__PURE__ */ React.createElement("strong", null, "Please note: "), answers.posting_questionnaire && /* @__PURE__ */ React.createElement("span", null, "All questions will be mandatory and cannot be changed once this option has been selected and the ad has been published. Please click", " ", /* @__PURE__ */ React.createElement("a", { href: job_url_prop, target: "_blank", rel: "noreferrer" }, "HERE"), " ", "to alter questions if required before posting."), !answers.posting_questionnaire && /* @__PURE__ */ React.createElement("span", null, "Candidates will receive an email to fill out the missing questions after applying."))), /* @__PURE__ */ React.createElement(
    FormControlLabel,
    {
      style: { marginTop: "2rem" },
      label: "Include a privacy policy agreement",
      control: /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          checked: answers.include_privacy,
          disabled: privacyOverLimit || answers.advertisement_id,
          onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), { include_privacy: !answers.include_privacy }))
        }
      )
    }
  ), privacyOverLimit && /* @__PURE__ */ React.createElement("div", null, "Privacy Policy agreement is unable to be included because it is is over Seek\u2019s 250 character limit. Please check entity settings or contact Support"));
}
Step3.propTypes = {
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  requiredRefs: PropTypes.object.isRequired,
  activeError: PropTypes.ref,
  linkOutEnabled: PropTypes.bool.isRequired,
  job_url_prop: PropTypes.string
};
export default Step3;
