"use strict";
import { theme } from "../../../../../ThemeContext/ThemeObject";
import { modalCreateButton } from "../../../../shared/styleClasses";
export const classes = {
  adPageContainer: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "#FFFFFF",
    padding: "40px"
  },
  adPageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px",
    "& h1": {
      fontWeight: "bold",
      fontSize: "38px",
      lineHeight: "1.2em",
      color: "#666666",
      marginTop: "0",
      "@media (max-width: 1450px)": {
        fontSize: "34px"
      },
      "@media (max-width: 1260px)": {
        fontSize: "28px"
      }
    }
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "#EEEEEE",
    margin: "40px 0px 20px 0px"
  },
  tabs: {
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      letterSpacing: "0",
      color: "#939393",
      textTransform: "none",
      minWidth: "unset",
      "&:hover": {
        color: "#737373"
      },
      "&:focus": {
        outline: "none"
      }
    },
    "& button.Mui-selected": {
      fontWeight: "600",
      color: "#084D6D"
    }
  },
  tabIndicator: {
    backgroundColor: "#5BC4BF",
    margin: "0 16px"
  },
  backToJobAdButton: {
    marginBottom: "20px",
    fontSize: "16px",
    color: "#eee",
    opacity: "0.8",
    display: "flex",
    alignItems: "center",
    "& div": {
      fontWeight: "600",
      color: "#666666",
      ":hover": {
        color: "#545454",
        cursor: "pointer"
      }
    }
  },
  tabContent: {
    display: "flex",
    flexDirection: "column",
    height: "80%",
    overflowY: "auto",
    margin: "20px 0px 40px 0",
    padding: "0px 16px"
  },
  headerContent: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#666666",
    lineHeight: "2rem"
  },
  checkbox: {
    color: "#DDDDDD",
    "&.Mui-checked": {
      color: theme.palette.action.main
    },
    "& .MuiSvgIcon-root": {
      fontSize: "22px"
    }
  },
  label: {
    marginBottom: "20px",
    "& .MuiTypography-root": {
      color: "#939393",
      fontSize: "16px",
      fontFamily: "Source Sans Pro, sans-serif"
    }
  },
  detailsTitle: {
    margin: "20px 0px 10px 0px",
    color: "#939393",
    fontSize: "16px",
    fontFamily: "Source Sans Pro, sans-serif"
  },
  details: {
    color: "#000000",
    fontSize: "16px",
    fontFamily: "Source Sans Pro, sans-serif"
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    columnGap: "50px",
    rowGap: "26px",
    margin: "40px 0px",
    width: "100%",
    alignItems: "flex-start"
  },
  datePicker: {
    margin: "33px 0 0 2px",
    fontFamily: "Source Sans Pro",
    width: "190px",
    color: "#666666",
    borderRadius: "6px",
    "& input": {
      padding: "5px 10px 7px 10px",
      fontFamily: "Source Sans Pro"
    },
    "& fieldset": {
      borderColor: "#DDDDDD",
      "& legend": {
        display: "none"
      }
    },
    "& button": {
      padding: "0px",
      transform: "translate(-2px, -1.5px)",
      "&:hover": {
        background: "transparent",
        "& svg": {
          color: "#888888"
        }
      }
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0px"
    },
    "& svg": {
      color: "#AAAAAA"
    }
  },
  formAutocomplete: {
    "& .MuiAutocomplete-root": {
      "& .MuiOutlinedInput-root": {
        padding: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "0px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "& .MuiAutocomplete-input": {
        padding: "6px 4px 6px 11px"
      }
    },
    "& fieldset": {
      display: "none"
    },
    "& label": {
      color: "#838383 !important",
      fontSize: "21px",
      fontFamily: "Source Sans Pro, sans-serif",
      paddingLeft: "4px",
      left: "-13px",
      top: "-15px",
      "& .MuiFormLabel-asterisk": {
        color: theme.palette.error.main
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      transition: "all 0.25s ease-in-out",
      color: "#AAAAAA"
    }
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px",
    margin: "40px 0px"
  },
  modalCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "100px",
    textTransform: "none",
    textDecoration: "underline",
    color: theme.palette.error.main,
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      boxShadow: "none"
    }
  },
  modalCreateButton,
  actionsContianer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "22px",
    margin: "10px 0px 30px 0px"
  },
  editButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#666666",
    backgroundColor: "#9393931F",
    padding: "10px 24px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#93939335",
      border: "none"
    }
  },
  approveButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#8CCF95",
    backgroundColor: "#8CCF951F",
    padding: "10px 24px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#8CCF9535",
      border: "none"
    }
  }
};
