"use strict";
import { searchInput } from "../shared/styleClasses";
export const styles = {
  tabs: {
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      letterSpacing: "0",
      color: "#939393",
      textTransform: "none",
      minWidth: "unset",
      "&:hover": {
        color: "#737373"
      },
      "&:focus": {
        outline: "none"
      }
    },
    "& button.Mui-selected": {
      fontWeight: "600",
      color: "#084D6D"
    }
  },
  tabIndicator: {
    backgroundColor: "#5BC4BF",
    margin: "0 16px"
  },
  filtersRow: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: "30px",
    columnGap: "17px"
  },
  jobPageContainer: {
    backgroundColor: "#FFFFFF",
    padding: "40px"
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "#EEEEEE",
    marginBottom: "46px"
  },
  searchInput,
  jobPageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "26px",
    padding: "0px 16px",
    "& h1": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "38px",
      color: "#666666",
      marginTop: "0"
    }
  },
  multiposterContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "40px"
  },
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D",
      transform: "translateY(1px)"
    }
  },
  iconButtonSelected: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  toggleButtonGroup: {
    borderRadius: "11px",
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#F3F3F3",
      "&:hover": {
        backgroundColor: "#F3F3F3"
      }
    }
  },
  toggleButton: {
    padding: "none !important",
    borderRadius: "11px",
    width: "125px",
    height: "47px",
    borderColor: "#F3F3F3"
  },
  logoSize: {
    width: "90px !important",
    height: "40px !important"
  },
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute"
  }
};
