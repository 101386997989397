"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { classes as rootClass } from "../styles";
import { theme } from "../../ThemeContext/ThemeObject";
import { closeIcon } from "../../NewUI/shared/styleClasses";
export const classes = __spreadProps(__spreadValues({}, rootClass), {
  systemReportsTableHeader: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    paddingLeft: 2,
    paddingBottom: 3
  },
  tableStyling: {
    "& .MuiTableCell-root": {
      fontFamily: "Source Sans Pro",
      borderInline: "none",
      borderTop: "none"
    },
    "& .MuiTable-root": {
      borderCollapse: "collapse"
    }
  },
  reportTitleContainer: {
    flexDirection: "row",
    columnGap: 2,
    alignItems: "center"
  },
  reportTitle: {
    fontFamily: "inherit",
    fontSize: "14px",
    color: "#666666",
    fontWeight: "bold"
  },
  avatarStyle: {
    bgcolor: "#5BC4BF",
    width: "12px",
    height: "12px",
    fontSize: "0.65em",
    fontWeight: "bold",
    borderRadius: "2px"
  },
  generateReportButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    minWidth: "140px",
    fontWeight: "bold",
    textTransform: "none",
    color: "rgb(140, 207, 149)",
    background: "rgb(140, 207, 149,0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#8CCF95",
      color: "#FFFFFF",
      boxShadow: "none"
    }
  },
  actionsColumn: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 4
  },
  saveReportButton: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    textTransform: "none",
    color: "rgb(91,196,191)",
    background: "rgb(91,196,191,0.12)",
    borderRadius: "6px",
    padding: "8px 14px",
    "&:hover": {
      color: "rgb(91,196,191)",
      background: "rgb(91,196,191,0.12)"
    },
    "&:focus": {
      outline: "none"
    }
  },
  downloadReportButton: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    background: "#5BC4BF",
    borderRadius: "6px",
    padding: "8px 14px",
    "&:hover": {
      color: "#FFFFFF",
      background: "#5BC4BF"
    },
    "&:focus": {
      outline: "none"
    }
  },
  previewReportTitle: {
    fontWeight: "400",
    paddingTop: 2,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 1,
    flexGrow: 1
  },
  previewReportPeriod: {
    color: "#666666",
    background: "#eeeeee",
    padding: "6px 16px",
    borderRadius: "1rem",
    letterSpacing: "0.1px",
    "&.active": {
      borderColor: theme.palette.secondary.main
    }
  },
  previewHeaderSection: { fontSize: "14px", marginRight: "4rem", marginBottom: "1rem" },
  previewHeaderTitles: { fontWeight: "bold", color: "#666666" },
  previewEditButton: {
    background: "#eeeeee",
    "&:hover": { background: "#d6d6d6" },
    "&:focus": {
      outline: "none"
    }
  },
  reportPreviewPopperContainer: {
    width: "274px",
    padding: "8px 0px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    marginTop: 2,
    borderRadius: "6px"
  },
  reportPeriodOption: {
    padding: "8px 24px",
    "&:hover": { background: "rgba(8,77,109,0.05)" },
    fontSize: "14px",
    fontWeight: "bold"
  },
  editIcon: { fontSize: "14px", stroke: "#666666" },
  closeIcon,
  filtersBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#084D6D",
      color: "#FFFFFF",
      transform: "translate(0%, -50%)",
      fontWeight: "bold",
      fontSize: "9px",
      minWidth: "16px",
      height: "16px"
    }
  },
  reportFiltersList: {
    padding: "8px 24px",
    fontSize: "14px",
    flexDirection: "row",
    columnGap: 1,
    textTransform: "capitalize"
  }
});
