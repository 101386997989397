import React, { useEffect, useState, useRef } from 'react';
import { classes } from './styles';
import Api from './API';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import DomainIcon from '@mui/icons-material/Domain';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HelpIcon from '@mui/icons-material/Help';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import InfoIcon from '@mui/icons-material/Info';
import FadeChildrenVisibility from './utils/FadeChildrenVisibility';
import { clearAllPreferenceCache } from '../NewUI/helpers/uiPreferencesHelpers';

enum STEPS {
  LOGIN,
  TWOFACTOR
}

const BASE_URL = window.location.origin;
const assets = [
  {
    url: '/static/login/recruit_login_image_V2.jpg',
    blurb: '',
    info: 'Let\'s connect people to grow companies, careers and communities.',
  },
  {
    url: '/static/login/recruit_login_image_02_V2.jpg',
    blurb: '',
    info: 'Empower your organisation\'s strategic growth with Scout Talent.'
  }
]
const RNG = () => {
  return Math.floor(Math.random() * Math.floor(2));
}

function NewLogin(props : {messages: string, intervalToValidateInSeconds: number}) {
  const [step, setStep] = useState(0);
  const [code, setCode] = useState('');
  const [snack, setSnack] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [sendingCode, setSendingCode] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [domain, setDomain] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);
  const [errors, setErrors] = useState('');
  const [randomNumber, setRandomNumber] = useState(RNG());
  const [emailFieldSelected, setEmailFieldSelected] = useState(false);
  const [passwordFieldSelected, setPasswordFieldSelected] = useState(false);
  const [newPasswordFieldSelected, setNewPasswordFieldSelected] = useState(false);
  const [newPasswordConfirmationFieldSelected, setNewPasswordConfirmationFieldSelected] = useState(false);
  const [domainFieldSelected, setDomainFieldSelected] = useState(false);
  const [singleSignOn, setSingleSignOn] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const messages = props.messages ? props.messages.match(/<\s*span[^>]*>(.*?)<\s*\/\s*span>/)[1] : '';
  const submitButton = useRef(null);
  const size = useWindowSize();

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);

      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  }

  useEffect(() => {
    const redirect = new URLSearchParams(window.location.search).get('redirect_from')
    const user_email = new URLSearchParams(window.location.search).get('user_email')
    if (redirect === 'saml_sso') {
      setEmail(user_email)
      setStep(STEPS.TWOFACTOR)
    }
  }, [])

  useEffect(() => {
    if(window.location.href.includes('reset')){
      setToken(window.location.href.split('=').pop());
    }
    if (token){
      setUpdatePassword(true);
      setResetPassword(false);
      setSingleSignOn(false);
    }
  }, [token]);

  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (errors) {
      setErrors('');
    }
    setEmail(event.target.value);
  }

  const handleCodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (errors) {
      setErrors('');
    }
    setCode(event.target.value);
  }

  const handlePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (errors) {
      setErrors('');
    }
    setPassword(event.target.value);
  }

  const handleDomainInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (errors) {
      setErrors('');
    }
    setDomain(event.target.value);
  }

  const handleNewPasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  }

  const handleNewPasswordConfirmationInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPasswordConfirmation(event.target.value);
  }

  const handleSubmit = async () => {
    setLoading(true)
    if (singleSignOn) {
      await postSSOLogin()
    } else if (resetPassword) {
      await postResetPassword()
    } else if (updatePassword) {
      await putUpdatePassword()
    } else if (step === STEPS.TWOFACTOR) {
      await postTwoFactorCode()
    } else {
      await postLogin()
    }
    clearAllPreferenceCache();
    setLoading(false);
  }

  const handleSSOClick = () => {
    setSingleSignOn(prev => !prev);
    setResetPassword(false);
    setUpdatePassword(false);
    setErrors('');
  }

  const handleButtonSecondaryClick = () => {
    if (step === STEPS.LOGIN) {
      setResetPassword(prev => !prev);
      setUpdatePassword(false)
    } else {
      resendTwoFactorCode();
    }
    setErrors('');
  }

  const buttonDisabled = () => {
    if (singleSignOn) {
      return !domain
    } else if (resetPassword) {
      return !email
    } else if (updatePassword) {
      return !newPassword || !newPasswordConfirmation
    } else if (step === STEPS.TWOFACTOR) {
      return code.length !== 6
    } else {
      return !email || !password || !!errors || loading
    }
  }

  function finaliseLogin() {
    localStorage.removeItem("userPermissions");
    window.location.href = redirectUrl || `${BASE_URL}/admin`
  }

  async function postLogin() {
    try {
      const response = await Api.postLogin({
        'email': email,
        'password': password
      })
      if ('errors' in response) {
        setErrors(response.errors ? response.errors[0] : '')
        setSingleSignOn(response.password_disabled === true ? true : false)
        return
      }
      if (response.url) {
        setRedirectUrl(response.url)
      }
      if (response.twofa_enabled) {
        const res = await Api.sendTwoFactor()
        if ('error' in res) {
          return setErrors(res.error)
        }
        setStep(STEPS.TWOFACTOR)
      } else {
        finaliseLogin(response.url)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function postSSOLogin() {
    try {
      const response = await Api.postSSOLogin({
        team_name: domain
      })
      if (!response.errors) {
        window.location.href = response.url;
      }
      setErrors(response.errors ? response.errors[0] : '');
    } catch (error) {
      console.log(error);
    }
  }

  async function postTwoFactorCode() {
    try {
      const response = await Api.postTwoFactor({ code })
      if (response.authenticated) {
        finaliseLogin(response.url)
      } else {
        if ('error' in response) {
          return setErrors(response.error)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function resendTwoFactorCode() {
    setSendingCode(true)
    try {
      const res = await Api.sendTwoFactor()
      if ('error' in res) {
        return setErrors(res.error)
      }
      setSnack(`A new code has been sent to ${email}`)
    } catch (error) {
      console.log(error)
    } finally {
      setSendingCode(false)
    }
  }

  async function postResetPassword() {
    try {
      const response = await Api.postResetPassword({
        'email': email
      })

      setErrors(response.notice ? response.notice[0] : '')

    } catch (error) {
      console.log(error)
    }
  }

  async function putUpdatePassword() {
    try {
      const response = await Api.putUpdatePassword(token, {
        'password': newPassword,
        'password_confirmation': newPasswordConfirmation,
      })
      if(response.success){
        window.location.href = `${BASE_URL}/login`
      }
      setErrors(response.errors ? response.errors[0] : '')

    } catch (error) {
      console.log(error)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') submitButton.current.click();
  }

  const buttonText = (() => {
    if (updatePassword) {
      return 'Update'
    } else if (resetPassword) {
      return 'Send password reset link'
    } else if (step === STEPS.TWOFACTOR) {
      return 'Submit'
    } else {
      return 'Login'
    }
  })();

  const buttonSecondaryText = (() => {
    if (sendingCode) {
      return 'Sending'
    } else if (resetPassword) {
      return 'Return to login'
    } else if (step === STEPS.TWOFACTOR) {
      return 'Resend code'
    } else {
      return 'Forgot password?'
    }
  })();

  return (
    <Box sx={classes.pageContainer}>
      <Box sx={classes.logoContainer}>
        <img src={size.width <= 1024 ? '/static/stg-logo-white.svg' : '/static/scout-logo.png'} alt='scout logo' />
      </Box>

      <Box sx={classes.loginFormContainer}>
        <Box sx={classes.loginForm}>
          <FadeChildrenVisibility>
            <Box key={1} is-visible={step === STEPS.TWOFACTOR}>
              <h1>Two-step authentication</h1>
              <Box sx={classes.description}>
                Enter the authentication code we sent to <span>{email}</span>. This code will be valid for {props.intervalToValidateInMinutes} mins.
              </Box>
              <TextField
                fullWidth
                id="code"
                label="Code"
                name="code"
                autoComplete="code"
                value={code}
                size={size.width <= 430 ? "small" : "medium"}
                autoFocus
                onChange={handleCodeInput}
                onKeyDown={handleKeyPress}
                onFocus={() => setEmailFieldSelected(true)}
                onBlur={() => setEmailFieldSelected(false)}
                sx={classes.inputs}
                InputLabelProps={{ shrink: !!code || emailFieldSelected, sx: classes.inputLabel }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon sx={classes.inputIcons} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box key={2} is-visible={step === STEPS.LOGIN}>
              {messages &&
                <Alert
                  icon={<InfoIcon fontSize="inherit" />}
                  severity="warning"
                  id="alert-with-icon"
                  sx={classes.alert}
                >
                  {messages}
                </Alert>
              }
              <h1>Welcome back</h1>
              <FadeChildrenVisibility>
                <div key={0} is-visible={!(singleSignOn || resetPassword || updatePassword)}>
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    value={email}
                    size={size.width <= 430 ? "small" : "medium"}
                    autoFocus
                    onChange={handleEmailInput}
                    onKeyDown={handleKeyPress}
                    onFocus={() => setEmailFieldSelected(true)}
                    onBlur={() => setEmailFieldSelected(false)}
                    sx={classes.inputs}
                    InputLabelProps={{ shrink: !!email || emailFieldSelected, sx: classes.inputLabel }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon sx={classes.inputIcons} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={password}
                    size={size.width <= 430 ? "small" : "medium"}
                    onChange={handlePasswordInput}
                    onKeyDown={handleKeyPress}
                    onFocus={() => setPasswordFieldSelected(true)}
                    onBlur={() => setPasswordFieldSelected(false)}
                    sx={{...classes.inputs, marginBottom: '6px'}}
                    InputLabelProps={{ shrink: !!password || passwordFieldSelected, sx: classes.inputLabel }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon sx={classes.inputIcons}/>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(prev => !prev)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div key={1} is-visible={!(!singleSignOn || resetPassword || updatePassword)}>
                  <TextField
                    fullWidth
                    id="domain-name"
                    label="Domain name"
                    name="Domain name"
                    autoComplete="domain"
                    size={size.width <= 430 ? "small" : "medium"}
                    autoFocus
                    value={domain}
                    onChange={handleDomainInput}
                    onKeyDown={handleKeyPress}
                    onFocus={() => setDomainFieldSelected(true)}
                    onBlur={() => setDomainFieldSelected(false)}
                    sx={classes.inputs}
                    InputLabelProps={{ shrink: !!domain || domainFieldSelected, sx: classes.inputLabel }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DomainIcon sx={classes.inputIcons}/>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div key={2} is-visible={resetPassword}>
                  <TextField
                    fullWidth
                    id="reset-email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    size={size.width <= 430 ? "small" : "medium"}
                    value={email}
                    autoFocus
                    onChange={handleEmailInput}
                    onKeyDown={handleKeyPress}
                    onFocus={() => setEmailFieldSelected(true)}
                    onBlur={() => setEmailFieldSelected(false)}
                    sx={classes.inputs}
                    InputLabelProps={{ shrink: !!email || emailFieldSelected, sx: classes.inputLabel }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon sx={classes.inputIcons}/>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div key={3} is-visible={updatePassword}>
                  <TextField
                    fullWidth
                    id="newPassword"
                    name="newPassword"
                    label="Password"
                    type={showNewPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    size={size.width <= 430 ? "small" : "medium"}
                    value={newPassword}
                    onChange={handleNewPasswordInput}
                    onKeyDown={handleKeyPress}
                    onFocus={() => setNewPasswordFieldSelected(true)}
                    onBlur={() => setNewPasswordFieldSelected(false)}
                    sx={{...classes.inputs, marginBottom: '6px'}}
                    InputLabelProps={{ shrink: !!newPassword || newPasswordFieldSelected, sx: classes.inputLabel }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon sx={classes.inputIcons}/>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowNewPassword(prev => !prev)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    fullWidth
                    id="newPasswordConfirm"
                    name="newPasswordConfirm"
                    label="Password Confirmation"
                    type={showNewPasswordConfirmation ? 'text' : 'password'}
                    autoComplete="current-password"
                    size={size.width <= 430 ? "small" : "medium"}
                    value={newPasswordConfirmation}
                    onChange={handleNewPasswordConfirmationInput}
                    onKeyDown={handleKeyPress}
                    onFocus={() => setNewPasswordConfirmationFieldSelected(true)}
                    onBlur={() => setNewPasswordConfirmationFieldSelected(false)}
                    sx={{...classes.inputs, marginBottom: '6px'}}
                    InputLabelProps={{ shrink: !!newPasswordConfirmation || newPasswordConfirmationFieldSelected, sx: classes.inputLabel }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon sx={classes.inputIcons}/>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowNewPasswordConfirmation(prev => !prev)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </FadeChildrenVisibility>
            </Box>
          </FadeChildrenVisibility>
          <Box sx={classes.forgotPassword}>
            <Box
              id='forgot-password'
              sx={classes.link}
              onClick={handleButtonSecondaryClick}
            >
              {buttonSecondaryText}
            </Box>
          </Box>
          <Box
            sx={{
              ...classes.errorMessage,
              height: errors ? `${Math.ceil(errors.length / 56) * 22}px` : '0px',
              width: '100%',
            }}
          >
            <Box
              sx={errors ?
                {...classes.fadeIn, textAlign: 'center'} : {...classes.fadeOut, textAlign: 'center'}
              }
              id='message-box'
            >
              {errors}
            </Box>
          </Box>
          <Button
            id='submit-button'
            sx={classes.loginButton}
            ref={submitButton}
            onClick={handleSubmit}
            disabled={buttonDisabled()}
          >
            {loading && <CircularProgress size={20} color="inherit" />}
            {buttonText}
          </Button>
          <Box sx={classes.singleSignOn}>
            <Box
              id='single-sign-on'
              sx={classes.link}
              onClick={handleSSOClick}
            >
              {singleSignOn ?  'Sign in using email' : 'Sign in using Single Sign On'}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{backgroundImage: `linear-gradient(#00000040, #00000040), url(${assets[randomNumber].url})`, ...classes.imageAndBlurbContainer}}>
        <Box sx={classes.blurb}>
            {assets[randomNumber].blurb}
        </Box>
      </Box>
      <Box sx={{...classes.infoPanel, backgroundColor: randomNumber ? '#5BC4BF' : '#084D6D'}}>
        <Box sx={classes.infoPanelText}>
          {assets[randomNumber].info}
        </Box>
      </Box>
      <Box
        sx={classes.helpContainer}
        onClick={() => window.location.href = 'https://scouttalent.force.com/support/s/'}
      >
        <HelpIcon sx={classes.helpIcon}/>
        <Box sx={classes.helpText}>
          Support Centre
        </Box>
      </Box>
      <Snackbar
        open={!!snack}
        onClose={() => setSnack('')}
        message={snack}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} />
    </Box>
  )
}

export default NewLogin;
