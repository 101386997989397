import React, { useState, useRef } from 'react';
import { classes } from '../styles';
import { Box, Snackbar } from '@mui/material';

export default function AdTable({ apiKey }: { apiKey: string }) {
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [tableHovered, setTableHovered] = useState(false);
  const actionCellRef = useRef(null);
  const jobCellRef = useRef(null);

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box sx={classes.tableContainer}>
          <Box sx={classes.table}>
            <Box sx={{ ...classes.row, ...classes.rowHeader }}>
              <Box
                component="span"
                ref={jobCellRef}
                sx={{
                  ...classes.title,
                  minWidth: '182px',
                  ...classes.sticky,
                  ...classes.scrollShadowLeft,
                  ...(tableHovered && classes.scrollShadowVisible),
                  left: 0
                }}
              >
                Job
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Creation Date
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Created By
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Expiry
              </Box>
              <Box component="span" sx={{ ...classes.center, minWidth: '130px' }}>
                Active on ViewJobs
              </Box>
              <Box
                component="span"
                sx={{
                  ...classes.stickyRight,
                  ...classes.scrollShadowRight,
                  ...(tableHovered && classes.scrollShadowVisible)
                }}
                ref={actionCellRef}
              ></Box>
            </Box>
            <Box sx={classes.row}>
              <Box sx={classes.title}>No ads to display</Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Snackbar open={isSnackOpen} onClose={() => setIsSnackOpen(false)} message={snackMessage} />
    </Box>
  );
}
