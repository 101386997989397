import React, { Dispatch } from 'react';
import { Stack } from '@mui/material';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import Ratings from './Ratings';
import Api from '../API';
import { defaultSections, ModalType } from '../config';
import AddRecord from '../../Candidate/AddRecord';
import CreateApproval from '../Modals/CreateApproval';
import EmailExternal from '../Modals/EmailExternal';
import SendSms from '../Modals/SendSms';
import { IUserPermissions } from '../../Components/sharedTypes';
import { DeleteAttachment } from '../Modals/DeleteAttachment';
import AttachmentPreviewModal from '../Modals/AttachmentPreviewModal';
import AdditionalInfo from './AdditionalInfo';
import { Section } from './Section';

export default function OverviewTab({
  aiSummariesEnabled,
  ApplicationState,
  dispatch,
  updateApplicationNotepad,
  updatingApplicationNotepad
}: {
  aiSummariesEnabled: boolean;
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  updateApplicationNotepad: UseMutateFunction<unknown, { error: string }, string, unknown>;
  updatingApplicationNotepad: boolean;
}) {
  const { sortableSections, modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  useQuery({
    queryKey: ['ui preferences'],
    queryFn: async () => {
      const { res } = await Api.getUiPreferences();
      return res.ui_preferences;
    },
    onSuccess: (res) =>
      dispatch({
        type: 'SET_SORTABLE_SECTIONS',
        payload:
          res[0]?.sorting.order && res[0]?.sorting.order.length === defaultSections.length
            ? res[0]?.sorting.order
            : defaultSections
      }),
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting ui preferences data, ${error}`,
          state: 'error'
        }
      })
  });

  const { mutate: addRecord, isLoading: addingRecord } = useMutation({
    mutationFn: async ({ subject, body }: { subject: string; body: string }) => {
      if (application) {
        const { res } = await Api.addRecord(application.job.id, application?.id, {
          subject,
          body
        });
        return res;
      }
    },
    onSuccess: (res) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      dispatch({ type: 'SET_MODALS_OPEN', payload: null });
      queryClient.invalidateQueries(['application']);
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in adding permanent record, ${error}`,
          state: 'error'
        }
      });
    }
  });

  const hrQuestion = useQuery({
    queryKey: ['hr questions'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getHrQuestions(application.job.id, application.id);
        return res.hr_answers[0] || null;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting application questionnaire data, ${error}`,
          state: 'error'
        }
      }),
    enabled: !!application && !!permissions?.Jobs?.AnswerApplicationHRQuestions
  });

  if (!application) return null;

  return (
    <Stack sx={{ rowGap: 3 }}>
      <Ratings ApplicationState={ApplicationState} dispatch={dispatch} hrQuestion={hrQuestion} />
      {sortableSections.map((section, index) => (
        <Section
          key={index}
          aiSummariesEnabled={aiSummariesEnabled}
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          hrQuestion={hrQuestion}
          section={section}
          updateApplicationNotepad={updateApplicationNotepad}
          updatingApplicationNotepad={updatingApplicationNotepad}
        />
      ))}
      <AdditionalInfo />
      {modalsOpen === ModalType.ADD_RECORD && (
        <AddRecord
          isOpen={modalsOpen === ModalType.ADD_RECORD}
          handleClose={() => dispatch({ type: 'SET_MODALS_OPEN', payload: null })}
          addRecord={addRecord}
          addingRecord={addingRecord}
          styleOverrides={{ maxHeight: '84vh' }}
        />
      )}
      {modalsOpen === ModalType.CREATE_APPROVAL && (
        <CreateApproval ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.EMAIL_EXTERNAL && (
        <EmailExternal
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          aiSummariesEnabled={aiSummariesEnabled}
        />
      )}
      {modalsOpen === ModalType.SEND_SMS && (
        <SendSms
          smsTemplatePermissions={true}
          ApplicationState={ApplicationState}
          dispatch={dispatch}
        />
      )}
      {ApplicationState.selectedAttachmentAction.delete && (
        <DeleteAttachment
          dispatch={dispatch}
          isDialogOpen={!!ApplicationState.selectedAttachmentAction.delete}
          selectedAttachment={ApplicationState.selectedAttachmentAction.delete}
        />
      )}
      {ApplicationState.selectedAttachmentAction.preview && (
        <AttachmentPreviewModal
          previewOpen={!!ApplicationState.selectedAttachmentAction.preview}
          selectedAttachment={ApplicationState.selectedAttachmentAction.preview}
          {...{ dispatch, ApplicationState, permissions }}
        />
      )}
    </Stack>
  );
}
