"use strict";
import { closeIcon } from "../../NewUI/shared/styleClasses";
export const classes = {
  buttonContainer: {
    position: "relative",
    cursor: "pointer",
    transform: "scale(0.77)"
  },
  button: {
    textTransform: "none",
    backgroundColor: "#F2F2F2",
    color: "#4E4EC7",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "700",
    padding: "8px 0px",
    paddingRight: "20px",
    paddingLeft: "54px",
    borderRadius: "22px",
    "&:hover": {
      backgroundColor: "#F2F2F2",
      color: "#4E4EC7"
    }
  },
  avatarContainer: {
    width: "44px",
    height: "44px",
    borderRadius: "50%",
    zIndex: 1,
    position: "absolute",
    top: "0px",
    left: "-1px",
    background: "linear-gradient(225.64deg, #E548FF -23.43%, #084D6D 140.57%, #084D6D 140.58%, #5BC4C0 140.6%)",
    boxShadow: "2px 3px 4px rgba(36, 36, 36, 0.18)"
  },
  hoverAnimation: {
    "@keyframes grow": {
      "0%": {
        transform: "scale(1) translate(2px, -3px)"
      },
      "100%": {
        transform: "scale(1.11) translate(2px, -5px)"
      }
    },
    "& svg": {
      animation: "grow 0.7s forwards"
    }
  },
  unhoverAnimation: {
    "@keyframes shrink": {
      "0%": {
        transform: "scale(1.11) translate(2px, -5px)"
      },
      "100%": {
        transform: "scale(1) translate(2px, -3px)"
      }
    },
    "& svg": {
      animation: "shrink 0.7s forwards"
    }
  },
  betaPill: {
    position: "absolute",
    top: "-8px",
    right: "2px",
    zIndex: 1,
    backgroundColor: "#5BC4C0",
    color: "#FFFFFF",
    fontFamily: "Source Sans Pro",
    fontSize: "9.5px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "16px",
    width: "30px",
    borderRadius: "8px"
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "60px",
    maxWidth: "1200px",
    width: "100%",
    height: "94vh",
    maxHeight: "844px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    rowGap: "16px"
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    marginTop: "auto",
    paddingTop: "16px"
  },
  felixMessageContainer: {
    display: "flex",
    columnGap: "26px",
    alignItems: "center"
  },
  felixMessage: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#2D388D",
    maxWidth: "330px",
    "& span": {
      color: "#AFBCFF"
    }
  },
  footerButtonsContainer: {
    display: "flex",
    columnGap: "32px"
  },
  footerContinueButton: {
    textTransform: "none",
    height: "40px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "700",
    color: "#FFFFFF",
    backgroundColor: "#7A8EF1",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#2D388D"
    },
    "&:disabled": {
      backgroundColor: "#CCCCCC",
      color: "#DDDDDD"
    }
  },
  footerBackButton: {
    textTransform: "none",
    textDecoration: "underline",
    height: "40px",
    paddingInline: "18px",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#CCCCCC",
    backgroundColor: "#FFFFFF",
    borderRadius: "20px",
    border: "none"
  },
  closeIcon,
  inputStepContainer: {
    paddingTop: "30px",
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto"
  },
  genericContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%"
  },
  sideBySideContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    columnGap: "44px"
  },
  fieldHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "38px",
    fontWeight: "700",
    color: "#2D388D",
    marginBottom: "36px"
  },
  fieldSubHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "600",
    color: "#2D388D",
    marginBottom: "16px"
  },
  fieldInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "regular",
    borderRadius: "6px",
    height: "42px",
    width: "100%",
    "& legend": {
      display: "none"
    },
    "& input": {
      padding: "0px 20px 3.5px 20px",
      color: "#333333"
    },
    "& fieldset": {
      borderColor: "#DDDDDD"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADADA !important"
      }
    }
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "40px",
    columnGap: "6px"
  },
  checkbox: {
    color: "#939393 !important",
    "& .MuiSvgIcon-root": {
      fontSize: "24px"
    },
    "&.Mui-checked": {
      color: "#939393 !important"
    }
  },
  checkboxLabel: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: 600,
    color: "#2D388D",
    paddingTop: "2px"
  },
  socialResponseContainer: {
    display: "flex",
    width: "90%",
    overflowY: "auto"
  },
  socialCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    border: "1px solid #DDDDDD",
    padding: "20px",
    width: "100%",
    height: "fit-content",
    boxShadow: "3px 3px 4px #dddddd"
  },
  socialCardHeader: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "864px",
    paddingRight: "30px",
    fontSize: "15px"
  },
  socialCardPreview: {
    width: "400px",
    padding: "13px",
    backgroundColor: "#f8f8f8",
    borderRadius: "12px",
    marginTop: "10px"
  },
  skeleton: {
    borderRadius: "12px",
    width: "400px",
    height: "200px"
  },
  responseHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
    marginBottom: "20px",
    lineHeight: "1"
  },
  responseHeaderText: {
    fontFamily: "Source Sans Pro",
    fontSize: "38px",
    fontWeight: "700",
    color: "#2D388D"
  },
  previewImage: {
    width: "100%",
    height: "200px",
    backgroundSize: "cover",
    borderRadius: "6px 6px 0px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  noImageIcon: {
    fontSize: 80,
    color: "#AAAAAA"
  },
  previewTitle: {
    fontWeight: "bold",
    marginTop: "8px",
    fontSize: "14px"
  },
  previewDescription: {
    fontSize: "12px"
  },
  previewLink: {
    fontSize: "11px"
  },
  pill: {
    textTransform: "none",
    height: "36px",
    width: "auto",
    paddingInline: "17px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "700",
    color: "#AFBCFF",
    border: "1px solid #AFBCFF",
    borderRadius: "18px"
  },
  centeredBiggerMargin: {
    alignItems: "center",
    marginBlock: "40px"
  },
  welcomePageSelections: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "96px",
    width: "90%",
    justifyContent: "center",
    marginTop: "70px",
    overflowY: "auto"
  },
  socialSelection: {
    display: "flex",
    flexDirection: "column",
    width: "336px",
    height: "400px",
    padding: "52px 32px 0px 52px",
    borderRadius: "10px",
    border: "3px solid #AFBCFF",
    color: "#AFBCFF",
    transition: "all 0.45s ease-in-out",
    cursor: "pointer",
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      color: "#000000",
      transition: "all 0.45s ease-in-out"
    },
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#AFBCFF",
      "& span": {
        color: "#FFFFFF"
      }
    }
  },
  advertisementSelection: {
    display: "flex",
    flexDirection: "column",
    width: "336px",
    height: "400px",
    padding: "52px 32px 0px 52px",
    borderRadius: "10px",
    border: "3px solid #5656DB",
    color: "#5656DB",
    transition: "all 0.45s ease-in-out",
    cursor: "pointer",
    "& span": {
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      color: "#000000",
      transition: "all 0.45s ease-in-out"
    },
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5656DB",
      "& span": {
        color: "#FFFFFF"
      }
    }
  },
  welcomePageSelectionHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    fontWeight: "700",
    margin: "40px 0px 16px 0px"
  },
  generatedResponseContainer: {
    fontFamily: "Source Sans Pro",
    width: "90%",
    height: "100%",
    overflowY: "auto",
    padding: "18px",
    paddingTop: "4px",
    border: "1px solid #DDDDDD",
    borderRadius: "6px",
    "& h2": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      color: "#666666",
      fontSize: "18px"
    },
    "& h3": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      color: "#666666",
      fontSize: "16px"
    },
    "& p": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "normal"
    },
    "& ul": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "14px",
      listStyleType: "circle",
      marginLeft: "30px",
      marginTop: "16px",
      marginBottom: "8px"
    },
    "& ol": {
      fontFamily: "Source Sans Pro",
      color: "#666666",
      fontSize: "16px",
      marginLeft: "30px",
      marginTop: "16px",
      marginBottom: "8px",
      listStyle: "decimal",
      "& li": {
        marginBottom: "12px",
        paddingLeft: "10px"
      }
    }
  },
  characterCounter: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: "#666666",
    position: "absolute",
    top: "10px",
    right: "10px"
  },
  keyPoint: {
    backgroundColor: "#E3E8FF",
    borderRadius: "6px",
    padding: "6px 14px 6px 14px",
    margin: "8px 6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: "12px",
    fontWeight: "600",
    color: "#2D388D"
  },
  copyIcon: {
    color: "#2D388D",
    "& svg": {
      fontSize: "18px"
    }
  }
};
