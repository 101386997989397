import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import EditJob from './EditJob';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export interface IEditJobProps {
  aiInterview: boolean;
  aiStudio: boolean;
  aiStudioSummaries: boolean;
  apiKey: string;
  job_id: number;
  userTimeZone: string;
}

export default function EditJobPage({
  aiInterview,
  aiStudio,
  aiStudioSummaries,
  apiKey,
  job_id,
  userTimeZone
}: IEditJobProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <EditJob aiInterview={aiInterview} aiStudio={aiStudio} aiStudioSummaries={aiStudioSummaries} apiKey={apiKey} job_id={job_id} userTimeZone={userTimeZone} />
    </QueryClientProvider>
  );
}