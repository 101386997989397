"use strict";
export var SectionName = /* @__PURE__ */ ((SectionName2) => {
  SectionName2["CandidateTags"] = "Candidate Tags";
  SectionName2["FelixAISummary"] = "Felix AI Summary";
  SectionName2["Responses"] = "Responses";
  SectionName2["Notepad"] = "Notepad";
  SectionName2["Actions"] = "Actions";
  SectionName2["ApplicationQuestionnaire"] = "Application questionnaire";
  SectionName2["Attachments"] = "Attachments";
  return SectionName2;
})(SectionName || {});
export var AttachmentType = /* @__PURE__ */ ((AttachmentType2) => {
  AttachmentType2["Image"] = "image";
  AttachmentType2["Video"] = "video";
  AttachmentType2["Audio"] = "audio";
  AttachmentType2["Text"] = "text";
  AttachmentType2["Pdf"] = "pdf";
  AttachmentType2["Word"] = "word";
  return AttachmentType2;
})(AttachmentType || {});
