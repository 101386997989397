"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Api from "../Jobs/API";
const EXPIRY_TIME = 3600 * 1e3;
export const fetchPreferences = (source) => __async(void 0, null, function* () {
  const expiryCacheKey = `userPreferences:${source}:expiry`;
  const cacheKey = `userPreferences:${source}`;
  const responseExpiryStr = localStorage.getItem(expiryCacheKey);
  const responseExpiry = responseExpiryStr ? Number(responseExpiryStr) : 0;
  if (responseExpiry > Date.now()) {
    const responseFromLocal = localStorage.getItem(cacheKey);
    if (responseFromLocal) {
      return JSON.parse(responseFromLocal);
    }
  }
  const response = yield Api.getUISettings({ source });
  localStorage.setItem(cacheKey, JSON.stringify(response));
  localStorage.setItem(expiryCacheKey, (Date.now() + EXPIRY_TIME).toString());
  return response;
});
export const clearPreferenceCache = (source) => {
  const expiryCacheKey = `userPreferences:${source}:expiry`;
  const cacheKey = `userPreferences:${source}`;
  localStorage.removeItem(expiryCacheKey);
  localStorage.removeItem(cacheKey);
};
export const clearAllPreferenceCache = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("userPreferences")) {
      localStorage.removeItem(key);
    }
  });
};
export const updateUiSettings = (params, onError) => __async(void 0, null, function* () {
  const source = params.source;
  try {
    yield Api.postUISettings({ ui_preference: params });
    if (source) {
      clearPreferenceCache(source);
    }
  } catch (error) {
    console.log(error);
    if (onError && error instanceof Error) {
      onError(error.message);
    }
  }
});
