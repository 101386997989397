import React, { useRef } from 'react';
import { Box, Breadcrumbs, Link, Tooltip } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { classes } from '../styles';
import { styled } from '@mui/system';
import { theme } from '../../../../../components/ThemeContext/ThemeObject';
import { useIsOverflow } from '../../../hooks';

const MAX_CHARACTER = 8;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  .MuiBreadcrumbs-ol {
    flex-wrap: nowrap;
  }
  .MuiBreadcrumbs-li {
    display: flex;
    align-items: center;
  }
`;

const BreadcrumbsContainer = ({ breadcrumbsData }: { breadcrumbsData: string }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isHorizontalOverflow = useIsOverflow(containerRef);

  const convertBreadcrumbsData = (breadcrumbsData: string) => {
    if (breadcrumbsData?.length < 1) return [];
    const nameRegex = /<a[^>]*>(.*?)<\/a>/;
    const hrefRegex = /<a[^>]*href="([^"]*)"[^>]*>/;
    // if path is /admin then return only dashboard
    if (window.location.pathname !== '/admin') {
      const breadcrumbsArray = breadcrumbsData.split('</li>');
      return breadcrumbsArray
        .map((breadcrumb) => ({
          name: breadcrumb.match(nameRegex)?.[1] || '',
          url: breadcrumb.match(hrefRegex)?.[1] || ''
        }))
        .slice(0, -1);
    } else {
      return [
        {
          name: 'Dashboard',
          url: '/admin'
        }
      ];
    }
  };

  const newBreadCrumbData = convertBreadcrumbsData(breadcrumbsData).filter(
    (item) => item.name !== 'Home'
  );

  return (
    <Box sx={classes.breadcrumbContainer} ref={containerRef}>
      <HomeRoundedIcon onClick={() => window.location.replace('/admin')} sx={classes.homeIcon} />
      <StyledBreadcrumbs
        separator={<NavigateNextIcon sx={classes.breadcrumbSeparator} />}
        aria-label="breadcrumb"
        sx={classes.breadcrumbs}
      >
        <Link underline="hover" color="inherit" href=""></Link>
        {newBreadCrumbData.map((breadcrumb, idx) =>
          idx !== 0 && isHorizontalOverflow && breadcrumb.name.length >= MAX_CHARACTER ? (
            <Tooltip
              key={breadcrumb.name}
              placement="bottom-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    background: '#DEF3F2',
                    color: theme.palette.primary.main,
                    fontFamily: 'Source Sans Pro',
                    fontSize: '14px'
                  }
                }
              }}
              title={breadcrumb.name}
            >
              <Link
                noWrap
                underline="hover"
                key={breadcrumb.name}
                color="inherit"
                href={breadcrumb.url}
              >
                {breadcrumb.name.slice(0, MAX_CHARACTER) + '…'}
              </Link>
            </Tooltip>
          ) : (
            <Link
              noWrap
              underline="hover"
              key={breadcrumb.name}
              color="inherit"
              href={breadcrumb.url}
            >
              {breadcrumb.name}
            </Link>
          )
        )}
      </StyledBreadcrumbs>
    </Box>
  );
};

export default BreadcrumbsContainer;
