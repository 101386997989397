"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { filterPopoverHeaderCommonStyles } from "../../../shared/commonStyles";
import { applyButton, clearButton, sortableSwitchLabels, switchLabels } from "../../../shared/styleClasses";
export const classes = {
  iconButtonSelected: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D",
      transform: "translateY(1px)"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  sortableField: {
    display: "flex",
    alignItems: "center",
    width: "204px"
  },
  sortableSwitchLabels,
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  switchActive: {
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    }
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    },
    "& :hover": {
      "& .MuiSwitch-track": {
        backgroundColor: "#000 !important"
      }
    }
  },
  switchLabels,
  popover: {
    zIndex: 12
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    marginTop: 2,
    background: "white",
    borderRadius: "4px"
  },
  tableSettingsHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: 1,
    paddingLeft: "4px"
  },
  filterPopoverContent: {
    background: "white",
    width: "272px",
    maxHeight: "500px",
    overflowY: "scroll",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    marginTop: 2,
    position: "relative",
    borderRadius: "4px",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  filterPopoverHeader: __spreadProps(__spreadValues({}, filterPopoverHeaderCommonStyles), {
    top: "0px"
  }),
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  clearButton,
  applyButton
};
