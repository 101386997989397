"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../../ThemeContext/ThemeObject";
export const baseButtonStyles = {
  fontFamily: "Source Sans Pro",
  fontSize: "14px",
  fontWeight: "bold",
  textTransform: "none",
  borderRadius: "6px",
  boxShadow: "none"
};
export const basePrimaryButtonStyles = __spreadProps(__spreadValues({}, baseButtonStyles), {
  color: "#FFFFFF",
  backgroundColor: "#5BC4BF",
  "&:hover": {
    backgroundColor: "#4da8a4",
    boxShadow: "none"
  }
});
export const baseSecondaryButtonStyles = __spreadProps(__spreadValues({}, baseButtonStyles), {
  color: "#939393",
  backgroundColor: "rgba(147, 147, 147, 0.12)",
  "&:hover": {
    backgroundColor: "rgba(147, 147, 147, 0.24)",
    boxShadow: "none"
  }
});
export const statusClampCommonStyles = {
  display: "-webkit-box",
  position: "relative",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  minHeight: "35px",
  maxHeight: "53px",
  textAlign: "left"
};
export const statusClampAfterStyles = {
  content: '"\u25BE"',
  fontSize: "13px",
  color: "#CCCCCC",
  position: "absolute",
  right: "6px",
  lineHeight: "0",
  top: "50%"
};
export const statusClampDropdownStyles = {
  visibility: "hidden",
  minHeight: "35px",
  textAlign: "left",
  position: "absolute",
  top: "-1px",
  left: "-1px",
  zIndex: "2",
  background: "#ffffff",
  borderRadius: "6px",
  border: "1px solid #CCCCCC"
};
export const statusClampDropdownAfterStyles = {
  content: '"\u25BE"',
  fontSize: "13px",
  color: "#CCCCCC",
  position: "absolute",
  right: "6px",
  lineHeight: "0"
};
export const statusClampHoverStyles = {
  overflow: "visible",
  WebkitLineClamp: "unset",
  "& .status-dropdown-full": {
    visibility: "visible"
  }
};
export const scrollShadowAfterStyles = {
  content: '""',
  background: "#aaaaaa",
  width: "2px",
  height: "100%",
  filter: "blur(2px)",
  position: "absolute",
  top: "0",
  opacity: "0%",
  transition: "opacity 0.17s linear"
};
export const scrollShadowBeforeStyles = {
  content: '""',
  top: "0",
  width: "6px",
  height: "100%",
  position: "absolute",
  background: "#ffffff",
  zIndex: "2"
};
export const candidateSearchCommonStyles = {
  fontFamily: "Source Sans Pro",
  fontSize: "16px",
  fontWeight: "regular",
  color: "#939393",
  borderRadius: "6px",
  width: "320px",
  "& .MuiOutlinedInput-root": {
    paddingLeft: "3px"
  },
  "& legend": {
    display: "none"
  },
  "& svg": {
    fontSize: "22px",
    color: "#939393",
    transform: "translate(-6px, -1px)"
  },
  "& input": {
    padding: "8px 9px 12px 0px",
    color: "#000000"
  }
};
export const actionsModalReducedPaddingCommonStyles = {
  display: "flex",
  flexDirection: "column",
  padding: "60px",
  paddingLeft: "48px",
  width: "100%",
  maxWidth: "900px",
  height: "84vh",
  borderRadius: "6px",
  dropShadow: "0px 2px 14px #305A6F29",
  position: "absolute",
  background: "#FFFFFF",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "& h3": {
    color: "#939393",
    fontSize: "19px",
    fontWeight: "Bold",
    marginBottom: "30px"
  }
};
export const filterPopoverHeaderCommonStyles = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "14px",
  backgroundColor: "white",
  position: "sticky",
  zIndex: 99,
  paddingTop: "26px",
  paddingBottom: "2px",
  boxShadow: "0px 6px 10px 7px #ffffff"
};
export const actionsMenuPaperCommonStyles = {
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
  padding: "14px 0px 14px 20px",
  borderRadius: "6px"
};
export const actionsMenuSpanCommonStyles = {
  fontFamily: "Source Sans Pro",
  fontSize: "14px",
  fontWeight: "bold",
  color: "#666666",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main
  }
};
export const switchLabelsCommonStyles = {
  justifyContent: "space-between",
  width: "100%",
  "& .MuiFormControlLabel-label": {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D"
  },
  "& .MuiSwitch-track": {
    backgroundColor: "rgba(0,0,0,0.38)",
    opacity: "1"
  },
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: "rgb(91, 196, 191, 0.17) !important",
    opacity: "1 !important"
  },
  "& .Mui-checked": {
    color: "rgb(91, 196, 191, 0.5) !important"
  }
};
const _arrowStyles = {
  display: "none",
  alignItems: "center",
  justifyContent: "center",
  width: "40px",
  height: "65px",
  bottom: "370px",
  background: "#eeeeee",
  opacity: "0.9",
  cursor: "pointer"
};
export const rightArrowStyles = __spreadProps(__spreadValues({}, _arrowStyles), {
  borderRadius: "10px 0px 0px 10px"
});
export const leftArrowStyles = __spreadProps(__spreadValues({}, _arrowStyles), {
  borderRadius: "0px 10px 10px 0px"
});
export const applyButtonStyles = __spreadProps(__spreadValues({}, baseButtonStyles), {
  color: "#FFFFFF",
  backgroundColor: "#5BC4BF",
  padding: "5px 23px",
  "&:hover": {
    backgroundColor: "#4da8a4",
    boxShadow: "none"
  }
});
export const actionButtonStyles = __spreadProps(__spreadValues({}, baseButtonStyles), {
  fontWeight: "600",
  color: "#666666",
  backgroundColor: "#9393931F",
  padding: "10px 24px",
  border: "none",
  "&:hover": {
    backgroundColor: "#93939335",
    border: "none"
  }
});
export const filterPopoverContentStyles = {
  width: "272px",
  maxHeight: "500px",
  boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  position: "relative",
  "&::-webkit-scrollbar": {
    display: "none"
  }
};
export const selectedApplicantsTableStyles = {
  tableLayout: "auto !important",
  width: "unset",
  border: "none",
  th: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    "& .Mui-active": {
      color: theme.palette.primary.main
    }
  },
  tr: {
    fontSize: "16px",
    border: "none"
  }
};
export const selectedApplicantsTableRowStyles = {
  fontFamily: "Source Sans Pro, sans-serif",
  border: "none",
  backgroundColor: "#FFFFFF",
  textTransform: "none"
};
export const modalConfirmationButtonCommonStyles = {
  fontFamily: "Source Sans Pro",
  fontSize: "14px",
  fontWeight: "bold",
  textTransform: "none",
  color: "#FFFFFF",
  backgroundColor: "#5BC4BF",
  borderRadius: "6px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#4da8a4",
    boxShadow: "none"
  }
};
export const selectedTooltipCommonStyles = {
  display: "flex",
  alignItems: "center",
  visibility: "hidden",
  bottom: "40px",
  position: "fixed",
  zIndex: 99,
  backgroundColor: "rgba(242,242,242)",
  borderRadius: "8px",
  transform: "translateX(20px)"
};
export const rowSelectContainerCommonStyles = {
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  marginTop: "5px",
  fontFamily: "Source Sans Pro",
  fontSize: "14px",
  fontWeight: "bold",
  color: "#666666",
  "& span": {
    marginRight: "12px",
    paddingBottom: "6px"
  }
};
export const checkboxContainerStyles = {
  fontFamily: "Source Sans Pro",
  fontSize: "14px",
  fontWeight: "bold",
  color: "#084D6D",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};
