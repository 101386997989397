"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../../../ThemeContext/ThemeObject";
import {
  candidateSearchCommonStyles,
  actionsModalReducedPaddingCommonStyles,
  filterPopoverHeaderCommonStyles,
  selectedApplicantsTableStyles,
  selectedApplicantsTableRowStyles,
  modalConfirmationButtonCommonStyles,
  selectedTooltipCommonStyles
} from "../../shared/commonStyles";
import {
  statusClamp,
  searchInput,
  chip,
  chipPacket,
  chipSelected,
  fileName,
  newApplicationButton,
  filterSwitchLabels,
  rowSelect,
  pagination,
  csvExportModal,
  csvExportHeader,
  csvExportSubheader,
  closeIcon,
  switchLabels,
  clearButton,
  modalSendButton,
  deleteApplicant,
  csvExportButton,
  applyButton,
  actionButton,
  filterPopoverContent,
  paginationContainer,
  selectedApplicantsTableHeader,
  rowSelectContainer,
  checkboxContainer
} from "../../shared/styleClasses";
export const classes = {
  applicationsContainer: {
    paddingTop: "6px"
  },
  checkbox: {
    color: "#aaa !important",
    "& .MuiSvgIcon-root": {
      fontSize: "20px"
    },
    "&.Mui-checked": {
      color: "#15C7C0 !important"
    }
  },
  sortable: {
    "&:hover": {
      color: "#0C74A4"
    },
    "& .MuiTableSortLabel-icon": {
      color: "darkgrey !important"
    }
  },
  candidateCell: {
    display: "flex",
    alignItems: "center",
    "& a": {
      color: "#666666",
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  paginationParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  },
  rowSelectContainer,
  paginationContainer,
  pagination,
  rowSelect,
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  },
  reviewSubheaders: {
    color: "#838383",
    fontSize: "15px",
    fontFamily: "Source Sans Pro, sans-serif",
    paddingLeft: "4px"
  },
  reviewBoxes: {
    backgroundColor: "#f8f6f6",
    borderRadius: "6px",
    border: "1px solid #DDDDDD",
    display: "block",
    minHeight: "8.5rem",
    width: "10.5rem",
    padding: "1.5rem"
  },
  reviewContent: {
    padding: "0 0 30px 4px"
  },
  noApplications: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  skeletonContainer: {
    borderBottom: "1px solid #DDDDDD",
    padding: "10px 0px"
  },
  selectedTooltip: __spreadProps(__spreadValues({}, selectedTooltipCommonStyles), {
    justifyContent: "center",
    width: "250px",
    height: "62px",
    left: "calc(50% - 105px)"
  }),
  fadeIn: {
    visibility: "visible",
    opacity: "1",
    transform: "translateX(0px)",
    transition: "visibility 0s linear 0s, opacity 300ms, transform 300ms"
  },
  fadeOut: {
    visibility: "hidden",
    opacity: "0",
    transform: "translateX(20px)",
    transition: "visibility 0s linear 300ms, opacity 300ms, transform 300ms"
  },
  selectedTooltipText: {
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#666666"
  },
  actionsContianer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "22px",
    marginBottom: "50px"
  },
  actionButton,
  deleteApplicant,
  filtersRow: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: "30px",
    columnGap: "17px"
  },
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  popover: {
    marginTop: "15px"
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)"
  },
  switchLabels,
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  switchActive: {
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  filterSwitch: {
    transform: "translateX(10px)",
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  filterSwitchActive: {
    transform: "translateX(10px)",
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  filterSwitchLabels,
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  searchInput,
  newApplicationButton,
  attachmentsHeader: {
    width: "50px"
  },
  attachmentsCell: {
    width: "50px",
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "18px",
      cursor: "pointer"
    }
  },
  attachmentItem: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    padding: "14px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  fileIcon: {
    color: theme.palette.primary.main
  },
  fileName,
  filterPopoverContent,
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  filterPopoverHeader: __spreadProps(__spreadValues({}, filterPopoverHeaderCommonStyles), {
    top: "0px"
  }),
  clearButton,
  applyButton,
  filterOptionContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    marginBottom: "12px"
  },
  checkboxContainer,
  ratingSlider: {
    fontFamily: "Source Sans Pro",
    color: theme.palette.action.main,
    height: "2px",
    "& .MuiSlider-rail": {
      backgroundColor: "#E0E0E0"
    },
    "& .MuiSlider-track": {
      backgroundColor: theme.palette.action.main
    },
    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.action.main,
      width: "8px",
      height: "8px",
      "&:hover": {
        boxShadow: "0px 0px 0px 5px" + theme.palette.action.main + "16"
      }
    },
    "& .MuiSlider-thumb.Mui-active": {
      boxShadow: "0px 0px 0px 7px" + theme.palette.action.main + "16"
    },
    "& .MuiSlider-valueLabel": {
      fontSize: "11px",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      top: "36px",
      "&::after": {
        display: "none"
      }
    }
  },
  csvExportModal,
  csvExportHeader,
  csvExportSubheader,
  closeIcon,
  chipPacket,
  chipSelected,
  chip,
  csvExportButton,
  actionsModal: {
    display: "flex",
    flexDirection: "row",
    padding: "0 60px",
    maxWidth: "1000px",
    width: "100%",
    height: "84vh",
    maxHeight: "1200px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  modalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    lineHeight: "32px"
  },
  calendar: {
    zIndex: "19001",
    "& .PrivatePickersFadeTransitionGroup-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.action.main} !important`
    },
    "& .PrivatePickersSlideTransition-root": {
      minHeight: "242px !important"
    }
  },
  formAutocomplete: {
    "& .MuiAutocomplete-root": {
      "& .MuiOutlinedInput-root": {
        padding: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "0px",
      margin: "3px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "& .MuiAutocomplete-input": {
        padding: "6px 4px 6px 11px"
      }
    },
    "& fieldset": {
      display: "none"
    },
    "& label": {
      color: "#838383 !important",
      fontSize: "21px",
      fontFamily: "Source Sans Pro, sans-serif",
      paddingLeft: "4px",
      left: "-13px",
      top: "-15px",
      "& .MuiFormLabel-asterisk": {
        color: theme.palette.error.main
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      transition: "all 0.25s ease-in-out",
      color: "#AAAAAA"
    }
  },
  datePicker: {
    fontFamily: "Source Sans Pro",
    width: "250px",
    color: "#666666",
    borderRadius: "6px",
    "& input": {
      padding: "5px 10px 7px 10px",
      fontFamily: "Source Sans Pro"
    },
    "& fieldset": {
      borderColor: "#DDDDDD",
      "& legend": {
        display: "none"
      }
    },
    "& button": {
      padding: "0px",
      transform: "translate(-2px, -1.5px)",
      "&:hover": {
        background: "transparent",
        "& svg": {
          color: "#888888"
        }
      }
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0px"
    },
    "& svg": {
      color: "#AAAAAA"
    }
  },
  editStep: {
    position: "fixed",
    fontSize: "13px",
    paddingLeft: "1px",
    cursor: "pointer"
  },
  modalHeaderContent: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: "#727272",
    marginBottom: "1.75rem",
    lineHeight: "2rem"
  },
  stepNumberGreen: {
    width: "2rem",
    height: "2rem",
    borderRadius: "1rem",
    backgroundColor: "#5BC4BF",
    color: "#FFFFFF",
    textAlign: "center",
    alignItems: "center",
    fontWeight: "700",
    display: "grid"
  },
  stepNumberGrey: {
    width: "2rem",
    height: "2rem",
    borderRadius: "1rem",
    backgroundColor: "#DDDDDD",
    color: "#727272",
    textAlign: "center",
    alignItems: "center",
    fontWeight: "700",
    display: "grid"
  },
  verticleLine: {
    width: "1px",
    backgroundColor: "#DDDDDD",
    marginLeft: "0.97rem",
    height: "100%"
  },
  verticleLineWhite: {
    width: "1px",
    backgroundColor: "#FFFFFF",
    marginLeft: "0.97rem",
    height: "100%"
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    height: "80%",
    overflowY: "auto",
    margin: "46px 0px"
  },
  modalContentStep3: {
    display: "flex",
    flexDirection: "column",
    height: "91%",
    overflowY: "auto",
    marginBottom: "25px"
  },
  errorBox: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    position: "absolute",
    bottom: "-30px",
    height: "2rem",
    left: "4px"
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px"
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "26px",
    width: "100%"
  },
  modalConfirmationButton: __spreadProps(__spreadValues({}, modalConfirmationButtonCommonStyles), {
    padding: "8px 14px"
  }),
  modalEditButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "rgb(91,196,191)",
    background: "rgb(91,196,191,0.12)",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#5BC4BF",
      color: "#FFFFFF",
      boxShadow: "none"
    }
  },
  modalCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "100px",
    textTransform: "none",
    textDecoration: "underline",
    color: theme.palette.error.main,
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      boxShadow: "none"
    }
  },
  fullWidthFieldContainer: {
    maxWidth: "630px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "26px"
  },
  fullWidthField: {
    width: "100%",
    marginBottom: "26px"
  },
  uploadSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& h3": {
      color: "#939393",
      fontSize: "19px",
      fontWeight: "Bold",
      marginBottom: "14px"
    }
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "2px",
    marginBottom: "24px",
    rowGap: "8px",
    fontSize: "17px",
    fontWeight: "600",
    color: "#AAAAAA"
  },
  statusClamp,
  statusColour: {
    position: "absolute",
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    top: "calc(50% - 3px)",
    left: "10px"
  },
  noDropdown: {
    border: "1px solid #FFFFFF",
    "& .status-dropdown-full": {
      border: "1px solid #FFFFFF"
    },
    "&::after": {
      display: "none"
    }
  },
  noStatuses: {
    width: "100%",
    paddingLeft: "18px",
    display: "flex"
  },
  statusDropdown: {
    border: "1px solid #CCCCCC",
    borderRadius: "6px",
    cursor: "pointer",
    "&::after": {
      display: "block"
    }
  },
  statusDropdownItem: {
    fontFamily: "Source Sans Pro",
    fontSize: "13px",
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F1F1F1"
    }
  },
  statusDropdownItems: {
    "& .MuiPaper-root": {
      width: "200px",
      maxHeight: "250px",
      overflowY: "auto",
      borderRadius: "6px",
      marginTop: "6px"
    }
  },
  statusLoader: {
    color: theme.palette.primary.main,
    position: "absolute",
    zIndex: "4",
    top: "calc(50% - 9px)",
    left: "calc(50% - 9px)"
  },
  applicationStatus: {
    "& span": {
      cursor: "pointer"
    }
  },
  formCheckbox: {
    color: "#ddd !important",
    "& .MuiSvgIcon-root": {
      fontSize: "20px"
    },
    "&.Mui-checked": {
      color: "#15C7C0 !important"
    }
  },
  actionModalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "26px",
    width: "100%",
    marginLeft: "12px"
  },
  actionModalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginLeft: "12px"
  },
  actionsModalReducedPadding: __spreadProps(__spreadValues({}, actionsModalReducedPaddingCommonStyles), {
    maxWidth: "950px"
  }),
  modalSendButton,
  selectedApplicantsTable: __spreadProps(__spreadValues({}, selectedApplicantsTableStyles), {
    minWidth: "650px",
    "td, th, tr, thead": __spreadProps(__spreadValues({}, selectedApplicantsTableRowStyles), {
      padding: "5px 8px 5px 0"
    })
  }),
  selectedApplicantsTableHeader,
  candidateSearch: __spreadProps(__spreadValues({}, candidateSearchCommonStyles), {
    "& fieldset": {
      borderColor: "#6DB5B1",
      backgroundColor: "#AAAAAA09"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "#DADEE0 !important"
      }
    }
  }),
  iconGrey: {
    color: "#666666",
    fontSize: "28px"
  },
  questionsTitle: {
    color: theme.palette.primary.main,
    fontSize: "13px",
    fontWeight: "bold",
    paddingBottom: "5px"
  },
  weightValue: {
    fontSize: "13px",
    textAlign: "center"
  },
  iconGreen: {
    color: theme.palette.success.main,
    fontSize: "1.25rem",
    margin: "auto",
    display: "flex"
  },
  iconGreenResources: {
    color: theme.palette.success.main,
    fontSize: "1.25rem",
    marginLeft: "0.5rem",
    display: "flex"
  },
  iconRed: {
    color: theme.palette.error.main,
    fontSize: "1.25rem",
    margin: "auto",
    display: "flex"
  },
  questionsWrapper: {
    display: "flex",
    padding: "12px 18px 0 18px",
    background: "#F3F3F3",
    marginBottom: "1.75rem",
    borderRadius: "6px"
  },
  questionInput: {
    "& span": {
      fontSize: "14px !Important"
    }
  },
  previewContainer: {
    marginTop: "1rem",
    "& h3": {
      marginBottom: "1rem",
      marginTop: ".3rem",
      color: "#727272",
      fontSize: "16px"
    }
  }
};
