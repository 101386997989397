/* eslint-disable max-lines */
import {
  AttachFile as AttachFileIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Message as MessageIcon,
  NoteAdd as NoteAddIcon
} from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Popover,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import moment from 'moment-timezone';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import InterviewQuestions from '../../../AIStudio/ApplicationSummaries/QuestionsModal/InterviewQuestions';
import SummaryPopover from '../../../AIStudio/ApplicationSummaries/SummaryPopover';
import { TagList } from '../../Components/CustomUIElements/TagList/TagList';
import DocTypeIcon from '../../Components/Utilities/DocTypeIcon';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Api from '../API';
import { EntityContext } from '../Job';
import {
  IApplicantAttachment,
  IApplicationTableProps,
  IJobApplication,
  INoteProps,
  NoteState,
  SortableColumnId
} from '../types';
import EditableNote from './EditableNote';
import { classes } from './styles';
import NotesWithSubjectLine from '../../Components/GenericTable/TableCells/NotesWithSubjectLine';
import NotesPopover from './NotePopover';
import CandidateIcon from '../../Candidate/CandidateIcon';

const CHECKBOX_CELL_WIDTH = 58;
const ID_CELL_WIDTH = 80;
const CANDIDATE_CELL_WIDTH = 200;

export default function ApplicationsTable({
  apiKey,
  applications,
  applicationStatuses,
  clearAllSelection,
  currentPage,
  density,
  enableSortableColumn,
  getAllApplications,
  getApplications,
  getFilterableStatuses,
  globalSelected,
  isLoading,
  jobId,
  jobTitle,
  rowsPerPage,
  selectAllApplications,
  selectAllIsChecked,
  selectAllIsIndeterminate,
  selected,
  setApplications,
  setCurrentPage,
  setGlobalSelected,
  setPreviewOpen,
  setRowsPerPage,
  setSelectAllApplications,
  setSelectAllIsChecked,
  setSelectAllIsIndeterminate,
  setSelected,
  setSelectedAttachment,
  setSnackbarState,
  setSortBy,
  setSortOrder,
  showGender,
  sortableColumns,
  sortBy,
  sortOrder,
  totalApplications,
  totalPages,
  userPermissions,
  newNoteOnApplicationEnabled,
  newApplicationPage
}: IApplicationTableProps) {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedApplication, setSelectedApplication] = useState<{
    id: null | number;
    attachments: null | IApplicantAttachment[];
    status: null | string;
    link: null | string;
  }>({ id: null, attachments: null, status: null, link: null });
  const [isFetchingAllApplications, setIsFetchingAllApplications] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [attachmentsListOpen, setAttachmentsListOpen] = useState(false);
  const [statusAnchorEl, setStatusAnchorEl] = useState<HTMLDivElement | null>(null);
  const [statusIsSending, setStatusIsSending] = useState(false);
  const [tableHovered, setTableHovered] = useState(false);
  const [noteAnchorEl, setNoteAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [canScroll, setCanScroll] = useState([false, true]);
  const tableRef = useRef<HTMLDivElement>(null);
  const actionCellRef = useRef<HTMLDivElement>(null);
  const candidateCellRef = useRef<HTMLDivElement>(null);
  const statusDropdownRef = useRef<HTMLDivElement>(null);
  const preventLoop = useRef(0);
  const [applicantHovered, setApplicantHovered] = useState<boolean>(false);
  const [applicantAnchorEl, setApplicantAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [lastHoveredApplicant, setLastHoveredApplicant] = useState({
    id: null,
    name: '',
    summary: ''
  });
  const [selectedAIApplicant, setSelectedAIApplicant] = useState<IJobApplication | null>(null);
  const [summaryGeneratingIds, setSummaryGeneratingIds] = useState<number[]>([]);
  const { ai_studio_summaries_enabled } = useContext(EntityContext);
  const notesRef = useRef<Array<HTMLInputElement | null>>([]);
  const [activeNote, setActiveNote] = useState<INoteProps['noteState'] | null>(null);
  const canAddNote = userPermissions?.['Applications']?.['Add Notes'];
  const firstThreeColumnsWidth = CHECKBOX_CELL_WIDTH + CANDIDATE_CELL_WIDTH + ID_CELL_WIDTH;

  useEffect(() => {
    preventLoop.current = 0;
  }, [applications]);

  const handleHoverApplicant = (target, application) => {
    if (application) {
      setLastHoveredApplicant({
        id: application.id,
        name: application.candidate?.firstname + ' ' + application.candidate?.lastname,
        summary: application.summary
      });
    }
    setApplicantHovered(true);
    setApplicantAnchorEl(target);
  };

  const handleUnhoverApplicant = useCallback(() => {
    setApplicantHovered(false);
    setApplicantAnchorEl(null);
  }, []);

  const tablePadding = (density: string) => {
    if (density === 'Default') {
      return '22px';
    } else if (density === 'Compact') {
      return '15px';
    } else {
      return '8px';
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newGlobalSelected = globalSelected.filter(
      (currentApp) => !applications?.map((app) => app.id).includes(currentApp.id)
    );
    if (event.target.checked) {
      const selectAllGlobalSelected = [...newGlobalSelected, ...applications];
      setSelected(selectAllGlobalSelected?.map((application) => application.id));
      setGlobalSelected(selectAllGlobalSelected);
      setSelectAllIsChecked(true);
      setSelectAllIsIndeterminate(false);
      setSelectAllApplications(true);
      return;
    }
    setSelected(newGlobalSelected?.map((application) => application.id));
    setGlobalSelected(newGlobalSelected);
    setSelectAllIsChecked(false);
    setSelectAllIsIndeterminate(false);
  };

  const handleSelectAllApplications = async () => {
    setIsFetchingAllApplications(true);
    const allApplications = await getAllApplications(totalApplications, null, null, null);
    setGlobalSelected(allApplications);
    setSelected(allApplications?.map((application) => application.id));
    setSelectAllIsChecked(true);
    setSelectAllApplications(true);
    setSelectAllIsIndeterminate(false);
    setIsFetchingAllApplications(false);
  };

  const handleSelectOne = (
    event: React.ChangeEvent<HTMLInputElement>,
    application: IJobApplication
  ) => {
    let newGlobalSelected = [...globalSelected];
    event.target.checked
      ? newGlobalSelected.push(application)
      : (newGlobalSelected = newGlobalSelected.filter((app) => app.id !== application.id));
    const newSelected = newGlobalSelected?.map((app) => app.id);
    setSelected(newSelected);
    setGlobalSelected(newGlobalSelected);
    setSelectAllIsChecked(
      applications?.map((app) => app.id).every((id) => newSelected.includes(id))
    );
    setSelectAllIsIndeterminate(
      applications?.map((app) => app.id).some((id) => newSelected.includes(id))
    );
  };

  const handleRowsPerPageChange = (selection: number) => {
    if (selection === rowsPerPage) return;
    setRowsPerPage(selection);
    getApplications(null, selection, null, null, null);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage === currentPage) return;
    setCurrentPage(newPage);
    sessionStorage.setItem(`${jobId}-applicationTablePage`, newPage);
    getApplications(newPage, null, null, null, null);
  };

  const handleSortChange = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    columnValue: string
  ) => {
    const isAsc = sortBy === columnValue && sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(columnValue);
    getApplications(null, null, null, { sortBy: columnValue, sortOrder: newSortOrder }, null);
  };

  const handleShowAttachmentsList = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number,
    attachments: IApplicantAttachment[],
    link: string
  ) => {
    setSelectedApplication({ id, attachments, status: null, link });
    setAnchorEl(event.currentTarget);
    setAttachmentsListOpen(true);
  };

  const handleStatusChange = async (id: number) => {
    setStatusAnchorEl(null);
    setStatusIsSending(true);
    try {
      const response = await Api.putApplicationStatus(
        { 'X-api-authenticate': apiKey },
        jobId,
        selectedApplication.id ? selectedApplication.id : 0,
        id
      );
      const newApplications = applications?.map((application) => {
        if (application.id === selectedApplication.id) {
          return { ...application, status: response.name, status_details: response };
        } else {
          return application;
        }
      });
      setApplications(newApplications);
      getFilterableStatuses();
    } catch (error) {
      console.log(error);
    } finally {
      setStatusIsSending(false);
    }
  };

  const handleScroll = () => {
    if (!tableRef.current) return;
    if (tableRef.current.scrollLeft < 10) {
      setCanScroll([false, true]);
    } else if (
      tableRef.current.scrollLeft >
      tableRef.current.scrollWidth - tableRef.current.clientWidth - 10
    ) {
      setCanScroll([true, false]);
    } else {
      setCanScroll([true, true]);
    }
  };

  const handleScrollLeft = () => {
    if (!tableRef.current) return;
    tableRef.current.scrollLeft > 360
      ? (tableRef.current.scrollLeft -= 300)
      : (tableRef.current.scrollLeft = 0);
  };

  const handleScrollRight = () => {
    if (!tableRef.current) return;
    tableRef.current.scrollWidth - tableRef.current.clientWidth > 360
      ? (tableRef.current.scrollLeft += 300)
      : (tableRef.current.scrollLeft = tableRef.current.scrollWidth - tableRef.current.clientWidth);
  };

  const handleStatusDropdown = (anchor: HTMLDivElement, status: string, id: number) => {
    setStatusAnchorEl(anchor);
    setSelectedApplication({ id, attachments: null, status });
  };

  const handleSelectAttachment = (attachment: IApplicantAttachment) => {
    setSelectedAttachment({ ...attachment, application_link: selectedApplication.link });
    setPreviewOpen(true);
  };

  const handleCloseAttachmentsList = () => {
    setAttachmentsListOpen(false);
    setAnchorEl(null);
  };

  const getRatingColor = (rating: number) => {
    if (rating <= 20) {
      return '#CF5005';
    } else if (rating <= 40) {
      return '#E7B500';
    } else if (rating <= 60) {
      return '#B7F018';
    } else if (rating <= 80) {
      return '#94EDBB';
    } else if (rating <= 100) {
      return '#6FCC7C';
    } else {
      return '#FFFFFF';
    }
  };

  function isScrollable(element: HTMLElement) {
    return element && element.scrollWidth > element.clientWidth + 20;
  }

  const handleScrollableEnter = () => {
    if (!tableRef.current || isLoading) return;
    if (isScrollable(tableRef.current)) setTableHovered(true);
  };

  const handleScrollableExit = () => {
    setTableHovered(false);
  };

  const handleOpenNote = (
    event: React.MouseEvent<HTMLElement>,
    application: IJobApplication,
    currentIndex: number,
    selectedNote: NoteState
  ) => {
    const isNotePad = selectedNote === NoteState.NOTEPAD;
    if (newNoteOnApplicationEnabled && application.suitability_comment && isNotePad) {
      enableSortableColumn();
      window.requestAnimationFrame(() => {
        notesRef.current[currentIndex]?.focus();
      });
      return;
    }

    setSelectedApplication({ id: application.id, attachments: null, status: null });
    setNoteAnchorEl(event.currentTarget);
    setActiveNote(isNotePad ? NoteState.NOTEPAD : NoteState.PERMANENT_RECORD);
  };

  const handleCloseNote = () => {
    setNoteAnchorEl(null);
    setSelectedApplication({ id: null, attachments: null, status: null });
    preventLoop.current = 0;
  };

  const handleSubmitNote = () => {
    enableSortableColumn();
  };

  const headerStyle = (name: string) => {
    switch (name) {
      case 'candidate':
        return {
          ...classes.sticky,
          ...classes.scrollShadowLeft,
          ...(tableHovered && classes.scrollShadowVisible),
          minWidth: `${CANDIDATE_CELL_WIDTH}px`,
          left: '138px',
          '& span': { marginLeft: '12px' }
        };
      case 'id':
        return { ...classes.sticky, minWidth: `${ID_CELL_WIDTH}px`, left: '58px' };
      case 'notes':
        return { minWidth: '230px' };
      case 'date':
        return { minWidth: '115px' };
      case 'gender':
        return { paddingLeft: '16px !important' };
      default:
        return { minWidth: '90px' };
    }
  };

  const displaySelectAll =
    selectAllApplications &&
    globalSelected?.length !== totalApplications &&
    totalApplications > rowsPerPage;

  const renderTableCell = useCallback(
    (
      column: IApplicationTableProps['sortableColumns'][0],
      application: IJobApplication,
      createdAt: JSX.Element[] | '-',
      index: number
    ) => {
      const applicationLink = `${application.application_link}?i=${index + rowsPerPage * (currentPage - 1)}`;
      const newApplicationLink = newApplicationPage
        ? applicationLink.replace('applications', 'new_applications')
        : applicationLink;

      const handleHover = {
        onMouseEnter: handleScrollableEnter,
        onMouseLeave: handleScrollableExit
      };

      switch (column.id) {
        case 'id':
          return (
            <TableCell
              {...handleHover}
              key={application.id + column.id}
              sx={{
                ...classes.noLinkStyling,
                ...classes.sticky,
                minWidth: '80px',
                left: '58px'
              }}
            >
              <a href={newApplicationLink} className="application-id-applications-link">
                {application.id || '-'}
              </a>
            </TableCell>
          );
        case 'candidate':
          return (
            <TableCell
              key={application.id + column.id}
              sx={{
                ...classes.sticky,
                minWidth: '200px',
                left: '138px',
                ...classes.scrollShadowLeft,
                ...(tableHovered && classes.scrollShadowVisible)
              }}
              onMouseEnter={(e) => handleHoverApplicant(e.currentTarget, application)}
              onMouseLeave={handleUnhoverApplicant}
            >
              <Box sx={classes.candidateCell}>
                <CandidateIcon
                  existingCandidate={application.candidate?.existing_candidate}
                  sx={classes.candidateIcon}
                />
                <a href={newApplicationLink} className="candidate-name-applications-link">
                  {application.candidate?.firstname + ' ' + application.candidate?.lastname}
                </a>
              </Box>
            </TableCell>
          );
        case 'gender':
          if (showGender) {
            return (
              <TableCell
                key={application.id + column.id}
                sx={{ paddingLeft: '16px !important' }}
                {...handleHover}
              >
                {application.candidate?.gender_full || '-'}
              </TableCell>
            );
          } else {
            return null;
          }
        case 'suburb':
          return (
            <TableCell key={application.id + column.id} {...handleHover}>
              {application.candidate?.address_details?.suburb || '-'}
            </TableCell>
          );
        case 'state':
          return (
            <TableCell key={application.id + column.id} {...handleHover}>
              {application.candidate?.address_details?.state?.name || '-'}
            </TableCell>
          );
        case 'country':
          return (
            <TableCell
              key={application.id + column.id}
              sx={{ textTransform: 'capitalize !important' }}
              {...handleHover}
            >
              {application.candidate?.address_details?.country?.name?.toLowerCase() || '-'}
            </TableCell>
          );
        case 'phone':
          return (
            <TableCell key={application.id + column.id} {...handleHover}>
              {application.candidate?.phone1 || '-'}
            </TableCell>
          );
        case 'date':
          return (
            <TableCell key={application.id + column.id} {...handleHover}>
              {createdAt}
            </TableCell>
          );
        case 'rating':
          return (
            <TableCell
              key={application.id + column.id}
              sx={{
                color: '#000000',
                fontWeight: '600'
              }}
              {...handleHover}
            >
              <Box
                sx={{
                  ...classes.rating,
                  backgroundColor: getRatingColor(
                    application.rating === null ? 101 : Number(application.rating)
                  )
                }}
              >
                {application.rating ? Math.ceil(Number(application.rating)) + '%' : '-'}
              </Box>
            </TableCell>
          );
        case 'status':
          return (
            <TableCell
              key={application.id + column.id}
              sx={classes.applicationStatus}
              {...handleHover}
            >
              <Box
                sx={{
                  ...classes.statusDropdown,
                  ...classes.statusClamp,
                  cursor: userPermissions?.['Applications']?.['Change Status']
                    ? 'pointer'
                    : 'default',
                  ...(!userPermissions?.['Applications']?.['Change Status']
                    ? {
                        '&::after': {
                          display: 'none'
                        }
                      }
                    : {})
                }}
                onClick={(event) => {
                  if (!userPermissions?.['Applications']?.['Change Status']) return;
                  handleStatusDropdown(event.currentTarget, application.status, application.id);
                }}
                ref={statusDropdownRef}
                className="status-dropdown-applications-table"
              >
                <Box
                  className="status-dropdown-full"
                  sx={{
                    cursor: userPermissions?.['Applications']?.['Change Status']
                      ? 'pointer'
                      : 'default',
                    '&::after': {
                      display: userPermissions?.['Applications']?.['Change Status']
                        ? 'block'
                        : 'none',
                      top:
                        statusDropdownRef.current && statusDropdownRef.current.clientHeight > 45
                          ? '25.5px'
                          : '50%'
                    }
                  }}
                >
                  <>
                    {statusIsSending && selectedApplication.id === application.id ? (
                      <CircularProgress size={18} sx={classes.statusLoader} />
                    ) : (
                      <>
                        <Box
                          sx={{
                            ...classes.statusColour,
                            backgroundColor: application.status_details?.colour
                              ? application.status_details?.colour
                              : '#AAAAAA'
                          }}
                        ></Box>
                        {application.status}
                      </>
                    )}
                  </>
                </Box>
                {statusIsSending && selectedApplication.id === application.id ? (
                  <CircularProgress size={18} sx={classes.statusLoader} />
                ) : (
                  <>
                    <Box
                      sx={{
                        ...classes.statusColour,
                        backgroundColor: application.status_details?.colour
                          ? application.status_details?.colour
                          : '#AAAAAA'
                      }}
                    ></Box>
                    {application.status}
                  </>
                )}
              </Box>
            </TableCell>
          );
        case SortableColumnId.NOTE_PAD:
          return (
            <TableCell key={application.id + column.id} {...handleHover}>
              {!!application?.suitability_comment?.length && (
                <EditableNote
                  application={application}
                  setApplications={setApplications}
                  jobId={jobId}
                  setSnackbarState={setSnackbarState}
                  noteRef={(el) => (notesRef.current[index] = el)}
                />
              )}
            </TableCell>
          );
        case SortableColumnId.PERMANENT_RECORD:
        case SortableColumnId.NOTES:
          return (
            <TableCell
              key={application.id + column.id}
              sx={{ minWidth: '300px', paddingRight: '8px !important' }}
              align="left"
              className="note-cell"
              id={`notes-${application.id}`}
              {...handleHover}
            >
              {application?.notes?.length > 0 ? (
                <NotesWithSubjectLine notes={application?.notes} />
              ) : (
                '-'
              )}
            </TableCell>
          );
        case 'candidate_tags':
          return (
            <TableCell
              key={application.id + column.id}
              sx={{ paddingRight: '8px !important' }}
              align="left"
              className="location-cell"
              {...handleHover}
            >
              {application.tag_list.length > 0 && <TagList tags={application.tag_list} />}
            </TableCell>
          );
        default:
          return <TableCell></TableCell>;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentPage,
      handleUnhoverApplicant,
      rowsPerPage,
      selectedApplication.id,
      showGender,
      statusIsSending,
      tableHovered,
      userPermissions,
      newApplicationPage
    ]
  );

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box
          onMouseEnter={handleScrollableEnter}
          onMouseLeave={handleScrollableExit}
          sx={{
            ...classes.arrowsContainer,
            position: 'absolute',
            left: `${firstThreeColumnsWidth}px`
          }}
        >
          <Box
            onClick={handleScrollLeft}
            sx={{
              ...classes.leftArrow,
              ...(tableHovered && canScroll[0] && classes.showArrows),
              top: 0
            }}
            id="left-arrow"
          >
            <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <Box
          onMouseEnter={handleScrollableEnter}
          onMouseLeave={handleScrollableExit}
          sx={{
            ...classes.arrowsContainer,
            position: 'absolute',
            right: `${actionCellRef.current?.offsetWidth}px`
          }}
        >
          <Box
            onClick={handleScrollRight}
            sx={{
              ...classes.rightArrow,
              ...(tableHovered && canScroll[1] && classes.showArrows),
              top: 0
            }}
            id="right-arrow"
          >
            <KeyboardArrowRightIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <TableContainer
          sx={{ boxShadow: 'none', scrollBehavior: 'smooth' }}
          component={Paper}
          onMouseEnter={handleScrollableEnter}
          onMouseLeave={handleScrollableExit}
          ref={tableRef}
          onScroll={handleScroll}
        >
          <Backdrop sx={{ color: '#83ECE7', zIndex: '99999' }} open={isFetchingAllApplications}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Table
            sx={{
              ...classes.tableWrapper,
              'th, td, tr, thead': { padding: `${tablePadding(density)} 10px` }
            }}
            aria-label="job applications"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...classes.sticky, width: `${CHECKBOX_CELL_WIDTH}px` }}>
                  <Checkbox
                    id="select-all-checkbox"
                    sx={classes.checkbox}
                    indeterminate={!selectAllIsChecked && selectAllIsIndeterminate}
                    checked={selectAllIsChecked}
                    onChange={handleSelectAll}
                    inputProps={{
                      'aria-label': 'select all applications'
                    }}
                  />
                </TableCell>
                {sortableColumns
                  .filter((column) => (column.id === 'gender' && !showGender ? false : true))
                  .map(
                    (column) =>
                      column.enabled && (
                        <TableCell
                          key={column.id}
                          sx={headerStyle(column.id)}
                          ref={column.id === 'candidate' ? candidateCellRef : null}
                        >
                          <TableSortLabel
                            id={`${column.id}-sort-label-applications`}
                            active={sortBy === column.id}
                            direction={sortBy === column.id ? (sortOrder as 'asc' | 'desc') : 'asc'}
                            onClick={(event) => handleSortChange(event, column.id)}
                            sx={column.sortable ? classes.sortable : null}
                            disabled={!column.sortable || column.id == SortableColumnId.NOTES}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      )
                  )}
                <TableCell
                  sx={{
                    ...classes.attachmentsHeader,
                    ...classes.stickyRight,
                    ...classes.scrollShadowRight,
                    ...(tableHovered && classes.scrollShadowVisible)
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {applications &&
                (!isLoading || isFetchingAllApplications) &&
                applications?.map((application: IJobApplication, index) => {
                  const createdAt = application.created_at
                    ? moment
                        .tz(application.created_at, timeZoneString)
                        .format('DD MMM YYYY,-h:mma z')
                        .split('-')
                        .map((line) => <div key={`${application.id}-${line}`}>{line}</div>)
                    : '-';
                  return (
                    <TableRow key={application.id}>
                      <TableCell sx={{ ...classes.sticky, width: '58px' }}>
                        <Checkbox
                          id={`select-checkbox-${application.id}`}
                          sx={classes.checkbox}
                          checked={selected.includes(application.id)}
                          onChange={(event) => handleSelectOne(event, application)}
                          inputProps={{
                            'aria-label': `select applicant ${application.candidate?.firstname} ${application.candidate?.lastname}`
                          }}
                        />
                      </TableCell>
                      {sortableColumns?.map((column) => {
                        if (column.enabled) {
                          return renderTableCell(column, application, createdAt, index);
                        }
                      })}
                      <TableCell
                        sx={{
                          ...classes.attachmentsCell,
                          ...classes.stickyRight,
                          ...classes.scrollShadowRight,
                          ...(tableHovered && classes.scrollShadowVisible)
                        }}
                        ref={actionCellRef}
                      >
                        <Box sx={classes.actionItems}>
                          {canAddNote && newNoteOnApplicationEnabled && (
                            <Tooltip title="Add note" placement="top">
                              <IconButton
                                className="notepad-application-table-button"
                                onClick={(e) =>
                                  handleOpenNote(e, application, index, NoteState.NOTEPAD)
                                }
                              >
                                <EditNoteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {canAddNote && (
                            <Tooltip
                              title={
                                newNoteOnApplicationEnabled ? 'Add permanent record' : 'Add note'
                              }
                              placement="top"
                            >
                              <IconButton
                                className="permanent-application-table-button"
                                onClick={(e) =>
                                  handleOpenNote(e, application, index, NoteState.PERMANENT_RECORD)
                                }
                              >
                                <NoteAddIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {application?.attachments?.length > 0 && (
                            <IconButton
                              className="show-attachments-applications-button"
                              aria-label="show attachments"
                              onClick={(event) =>
                                handleShowAttachmentsList(
                                  event,
                                  application.id,
                                  application.attachments,
                                  application.application_link
                                )
                              }
                            >
                              <AttachFileIcon />
                            </IconButton>
                          )}
                          {ai_studio_summaries_enabled && (
                            <Tooltip title="Suggested interview questions" placement="top">
                              <IconButton
                                className="ai-studio-interview-questions-button"
                                aria-label="ai studio interview questions"
                                onClick={() => setSelectedAIApplicant(application)}
                                sx={classes.aiQuestionsButton}
                              >
                                <MessageIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!isLoading && applications?.length === 0 && (
            <Box sx={classes.noApplications}>No applications found</Box>
          )}
          {isLoading && !isFetchingAllApplications && (
            <>
              {[...Array(10)].map((_, index) => (
                <Box key={index} sx={classes.skeletonContainer}>
                  <Skeleton animation="wave" height={60} />
                </Box>
              ))}
            </>
          )}
        </TableContainer>
      </Box>
      <Box sx={classes.paginationParent}>
        <Box sx={classes.rowSelectContainer}>
          <span>Rows per page: </span>
          <FormControl>
            <Select
              id="rows-per-page"
              sx={classes.rowSelect}
              value={rowsPerPage}
              onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
              displayEmpty
              MenuProps={{ sx: classes.paginationMenuItems }}
            >
              <MenuItem id="first-rpp-item" value={10}>
                10
              </MenuItem>
              <MenuItem id="second-rpp-item" value={20}>
                20
              </MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={classes.paginationContainer}>
          <Pagination
            id="pagination-menu"
            count={totalPages}
            page={currentPage}
            siblingCount={0}
            onChange={handlePageChange}
            sx={classes.pagination}
          />
          <span id="total-jobs">{totalApplications} Total</span>
        </Box>
      </Box>
      <Box
        id="selected-tooltip"
        sx={
          globalSelected?.length > 0
            ? { ...classes.selectedTooltip, ...classes.fadeIn }
            : { ...classes.selectedTooltip, ...classes.fadeOut }
        }
      >
        <Box
          id="selected-tooltip-text"
          sx={{
            display: 'flex',
            paddingLeft: '12px',
            alignItems: 'center',
            ...classes.selectedTooltipText
          }}
        >
          {globalSelected.length === totalApplications
            ? `All ${globalSelected?.length} applications are selected`
            : `${globalSelected?.length} selected`}
          <IconButton
            size="small"
            sx={{
              ...classes.selectedTooltipText,
              ...classes.selectedAllTooltipText,
              marginLeft: 1,
              color: '#666666'
            }}
            onClick={clearAllSelection}
          >
            Clear selection
          </IconButton>
        </Box>
        {displaySelectAll && (
          <IconButton
            disabled={isFetchingAllApplications}
            size="small"
            sx={{ ...classes.selectedTooltipText, ...classes.selectedAllTooltipText }}
            onClick={handleSelectAllApplications}
          >
            Select all {totalApplications} applications
          </IconButton>
        )}
      </Box>
      {selectedApplication.id && (
        <NotesPopover
          anchorEl={noteAnchorEl}
          applicationId={selectedApplication.id}
          handleCloseNote={handleCloseNote}
          jobId={jobId}
          onSuccess={handleSubmitNote}
          setApplications={setApplications}
          setSnackbarState={setSnackbarState}
          noteState={activeNote}
          newNoteOnApplicationEnabled={newNoteOnApplicationEnabled}
        />
      )}

      <Popover
        id={attachmentsListOpen ? 'applicant-attachments' : undefined}
        open={attachmentsListOpen}
        anchorEl={anchorEl}
        onClose={handleCloseAttachmentsList}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box>
          {selectedApplication?.attachments?.map((attachment) => (
            <Box
              key={attachment.id}
              sx={classes.attachmentItem}
              onClick={() => handleSelectAttachment(attachment)}
            >
              <Box sx={classes.fileIcon}>
                {DocTypeIcon(attachment.attached_file_name.split('.').pop())}
              </Box>
              <Box sx={classes.fileName}>{attachment.attached_file_name}</Box>
            </Box>
          ))}
        </Box>
      </Popover>
      <Popover
        id={statusAnchorEl ? 'status-selection' : undefined}
        sx={classes.statusDropdownItems}
        open={Boolean(statusAnchorEl)}
        anchorEl={statusAnchorEl}
        onClose={() => setStatusAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {selectedApplication &&
          applicationStatuses
            ?.filter((status) => status.name !== selectedApplication.status)
            ?.map((status) => (
              <Box
                key={status.id}
                sx={classes.statusDropdownItem}
                onClick={() => handleStatusChange(status.id)}
              >
                {status.name}
              </Box>
            ))}
      </Popover>
      {ai_studio_summaries_enabled && (
        <>
          <SummaryPopover
            applicantHovered={applicantHovered}
            handleHoverApplicant={handleHoverApplicant}
            handleUnhoverApplicant={handleUnhoverApplicant}
            lastHoveredApplicant={lastHoveredApplicant}
            anchorEl={applicantAnchorEl}
            jobId={jobId}
            setSnackbarState={setSnackbarState}
            summaryGeneratingIds={summaryGeneratingIds}
            setSummaryGeneratingIds={setSummaryGeneratingIds}
          />
          <InterviewQuestions
            jobId={jobId}
            open={!!selectedAIApplicant}
            handleClose={() => setSelectedAIApplicant(null)}
            selectedApplicantId={selectedAIApplicant?.id}
            positionTitle={jobTitle}
            setSnackbarState={setSnackbarState}
          />
        </>
      )}
    </Box>
  );
}
