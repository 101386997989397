import React, { ReactElement, SetStateAction, CSSProperties, Dispatch } from 'react';
import { classes } from './styles';
import { Box, Dialog, Button, CircularProgress } from '@mui/material';

export interface IGenericDialogProps {
  alternateColor?: boolean;
  buttonCallback: (url: string, id: number) => void;
  buttonText: string;
  callbackLoading: boolean;
  description: string | ReactElement;
  dialogId?: number;
  disablePrimaryButton?: boolean;
  isDialogOpen: boolean;
  onCancelCallback?: () => void;
  overrideColor?: { primary: CSSProperties; secondary: CSSProperties };
  primaryButtonId?: string;
  secondaryButtonId?: string;
  secondaryButtonText?: string;
  setDialogOpen: Dispatch<SetStateAction<boolean>> | ((value: boolean) => void);
  switchOrder?: boolean;
  title: string;
  url: string;
}

function GenericDialog({
  alternateColor,
  buttonCallback,
  buttonText,
  callbackLoading,
  description,
  dialogId,
  disablePrimaryButton,
  isDialogOpen,
  onCancelCallback,
  overrideColor,
  primaryButtonId,
  secondaryButtonId,
  secondaryButtonText,
  setDialogOpen,
  switchOrder,
  title,
  url
}: IGenericDialogProps) {
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      sx={{ '& .MuiPaper-root': { ...classes.dialog, maxWidth: '500px' }, zIndex: '20000' }}
    >
      <h4>{title}</h4>
      {description && <span>{description}</span>}
      <Box
        sx={{
          ...classes.dialogButtonContainer,
          flexDirection: switchOrder ? 'row-reverse' : 'row'
        }}
      >
        <Button
          disableElevation
          id={secondaryButtonId}
          sx={{
            ...classes.buttonSecondaryLight,
            ...(alternateColor && classes.secondaryButtonAlternateColor),
            ...(overrideColor && overrideColor.secondary)
          }}
          onClick={() => {
            onCancelCallback?.();
            setDialogOpen(false);
          }}
          style={{ padding: '10px 30px' }}
        >
          {secondaryButtonText || 'Cancel'}
        </Button>
        <Button
          data-testid="dialog-action-button"
          className="dialog-action-button"
          id={primaryButtonId}
          disableElevation
          sx={{
            ...classes.buttonRed,
            ...(alternateColor && classes.primaryButtonAlternateColor),
            '&.Mui-disabled': { background: '#E5E5E5', color: '#939393' },
            ...(overrideColor && overrideColor.primary)
          }}
          onClick={() => (dialogId ? buttonCallback(url, dialogId) : buttonCallback(url, 0))}
          style={{ padding: '10px 30px' }}
          disabled={disablePrimaryButton}
        >
          {callbackLoading ? <CircularProgress size={20} color="inherit" /> : buttonText}
        </Button>
      </Box>
    </Dialog>
  );
}

export default GenericDialog;
