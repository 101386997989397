import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import TableSkeleton from '../TableSkeleton';
import AdTable from './AdTable';
import { classes } from '../styles';

export default function ViewJobsAds({ apiKey, jobId }: { apiKey: string; jobId: string | null }) {
  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [ads, setAds] = useState(null);
  const [page, setPage] = useState(1);
  const [maxAds, setMaxAds] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const getInitialAds = useCallback(async () => {
    setLoading(true);
    const url = jobId
      ? `/api/v4/view_jobs/jobs/${jobId}/job_ads?limit=100`
      : '/api/v4/view_jobs/job_ads/active';
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then(async (response) => {
        if (response.ok) {
          return {
            data: await response.json(),
            maxAds: Number(response.headers.get('X-Total-Count')),
            maxPages: Number(response.headers.get('X-Total-Pages'))
          };
        }
        return response.json().then((error) => {
          error.code = response.status;
          throw error;
        });
      });
      if (jobId !== null) {
        setAds(response.data.job_ads);
      } else {
        setAds(response.data);
        setMaxAds(response.maxAds);
        setMaxPages(response.maxPages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [apiKey, jobId]);

  const getAds = async (currentType: string, currentPage: number, currentRows: number) => {
    if (jobId !== null) return;
    setLoading(true);
    const url = `/api/v4/view_jobs/job_ads/${currentType}?page=${currentPage}&limit=${currentRows}`;
    try {
      // TODO: Add ViewJobs endpoint when ready
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const createNewJob = useCallback(async () => {
    setLoadingCreate(true);
    try {
      const response = await fetch(`/api/v4/view_jobs/jobs/${jobId}/job_ads/init_view_job_ad`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      if (!response.ok) {
        console.log('Error creating new job ad');
      } else {
        const data = await response.json();
        window.location.href = data.url;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingCreate(false);
    }
  }, [apiKey, jobId]);

  useEffect(() => {
    getInitialAds();
  }, [getInitialAds]);

  return (
    <Box sx={classes.containerOuter}>
      <Box sx={classes.container}>
        <Box sx={classes.banner}>
          {jobId && (
            <Button
              disableElevation
              sx={{ ...classes.buttonSecondary, ...classes.newAdButton }}
              id="ViewJobsNewAdMultiposterBtn"
              onClick={createNewJob}
            >
              {loadingCreate ? <CircularProgress size={20} color="inherit" /> : 'New Ad'}
            </Button>
          )}
        </Box>
        {loading ? <TableSkeleton /> : <AdTable apiKey={apiKey} />}
      </Box>
    </Box>
  );
}
