"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
import {
  closeIcon,
  pagination,
  paginationContainer,
  rowSelect,
  rowSelectContainer
} from "../../shared/styleClasses";
export const classes = {
  tableWrapper: {
    tableLayout: "auto !important",
    marginTop: "40px",
    minWidth: "1000px",
    border: "none",
    "td, th, tr, thead": {
      maxWidth: "240px",
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "24px 12px 12px 12px"
    },
    th: {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontWeight: "bold",
      padding: "0px 12px 36px 12px"
    }
  },
  candidateTableWrapper: {
    marginLeft: "28px",
    tableLayout: "auto !important",
    maxWidth: "80%",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "12px 0px 12px 0px"
    },
    th: {
      color: theme.palette.primary.main,
      padding: "24px 0px 0px 0px",
      fontSize: "12px"
    }
  },
  status: {
    textTransform: "uppercase !important",
    display: "flex",
    justifyContent: "start",
    alignItems: "center !important"
  },
  details: {
    color: "#000000",
    disableRipple: "true",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer"
    }
  },
  widgetContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    columnGap: "50px",
    rowGap: "50px"
  },
  preview: {
    color: "#666666",
    fontSize: "medium",
    "&:hover": {
      cursor: "pointer"
    }
  },
  attachmentPreviewModal: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    width: "900px",
    height: "775px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  attachmentPreviewTitle: {
    fontFamily: "Source Sans Pro",
    fontSize: "30px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column"
  },
  attachmentPreviewContent: {
    borderTop: "1px solid #E3E3E3",
    borderBottom: "1px solid #E3E3E3",
    display: "flex",
    justifyContent: "start",
    height: "540px",
    width: "100%",
    overflowY: "auto",
    marginTop: "36px"
  },
  attachmentPreviewActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
    width: "100%",
    columnGap: "16px",
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  closeIcon,
  downloadButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "120px",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  noPreview: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: "#999999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  table: {
    display: "table",
    borderCollapse: "collapse",
    borderSpacing: "0",
    width: "100%",
    background: "#FFF",
    borderRadius: "8px"
  },
  rowHeader: {
    "& span": {
      fontSize: "16px",
      fontWeight: "bold",
      color: theme.palette.primary.main
    }
  },
  row: {
    display: "table-row-group",
    width: "100%",
    borderBottom: "1px solid #f3f3f3",
    "& > *": {
      padding: "24px",
      paddingRight: "0px",
      display: "table-cell",
      fontSize: "14px",
      "&:first-of-type": {
        paddingLeft: "44px"
      },
      "&:last-child": {
        paddingRight: "44px"
      },
      "& button": {
        maxWidth: "100px",
        maxHeight: "44px",
        lineHeight: "1.05rem"
      }
    }
  },
  title: {
    color: "#666666",
    fontWeight: "bold"
  },
  center: {
    textAlign: "center"
  },
  state: {
    fontSize: "13px",
    fontWeight: "bold",
    backgroundColor: "#8CCF951F",
    color: "#8CCF95",
    borderRadius: "6px",
    textTransform: "uppercase",
    padding: "8px 32px 8px 32px",
    width: "auto",
    height: "35px"
  },
  noEmailStatusContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  noEmailStatusesText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  emailStatusesSkeleton: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    width: "100%"
  },
  paginationParent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  },
  rowSelectContainer,
  paginationContainer,
  pagination,
  rowSelect,
  paginationMenuItems: {
    "& li": {
      fontFamily: "Source Sans Pro"
    }
  },
  cancelEmailButton: {
    fontSize: "13px",
    fontWeight: "bold",
    backgroundColor: "#E6A0521F",
    color: "#E6A052",
    borderRadius: "6px",
    textTransform: "uppercase",
    padding: "8px 16px",
    minWidth: "100px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      backgroundColor: "#E6A052",
      color: "#FFFFFF"
    }
  },
  unscheduledEmailDetails: {
    fontSize: "13px",
    fontWeight: "bold",
    backgroundColor: "#CCCCCC",
    color: "#FFFFFF",
    borderRadius: "6px",
    textTransform: "uppercase",
    padding: "8px 32px 8px 32px",
    width: "auto",
    height: "35px"
  },
  unsheduledDetailsPopover: {
    position: "absolute",
    top: "100%",
    left: 0,
    padding: "15px",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    whiteSpace: "normal",
    zIndex: 1,
    boxShadow: "0px 2px 14px rgba(48, 90, 111, 0.16)",
    width: "200px",
    alignItems: "flex-start"
  },
  unsheduledDetailsLabel: {
    fontWeight: "bold"
  },
  unsheduledDetailsInfo: {
    marginLeft: "8px",
    marginBottom: "8px"
  },
  chipContainer: {
    width: "100%",
    height: "60px",
    display: "flex",
    marginTop: "20px"
  },
  chipItem: {
    width: "fit-content",
    height: "40px",
    border: "1.5px solid #084d89",
    borderRadius: "32px",
    fontSize: "12px",
    color: "#084d89",
    fontWeight: "bold",
    margin: "0px 10px",
    textTransform: "uppercase",
    backgroundColor: "#edf2f5"
  },
  filterLabel: {
    color: "#084d89",
    borderRadius: "5px",
    margin: "0px 10px",
    fontSize: "12px",
    textTransform: "uppercase",
    width: "150px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    backgroundColor: "#edf2f5",
    border: "none"
  }
};
