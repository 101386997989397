import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import React, { Dispatch, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { GenericTableAction } from './genericTableReducer';
import { searchInput } from '../../shared/styleClasses';

const SearchInput = ({ dispatch, id }: { dispatch: Dispatch<GenericTableAction>; id?: string }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedAPICall = useDebouncedCallback(() => {
    dispatch({ type: 'SET_SEARCH_QUERY', payload: inputValue });
  }, 600);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    debouncedAPICall();
  };

  return (
    <FormControl fullWidth>
      <OutlinedInput
        id={id ? id : 'records-search'}
        sx={searchInput}
        placeholder={'Search by title'}
        value={inputValue}
        onChange={handleChange}
        startAdornment={
          <InputAdornment sx={{ padding: '0px 0px 0px 14px' }} position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default SearchInput;
