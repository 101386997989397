"use strict";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
export const useIsOverflow = (ref) => {
  const [hasOverflow, setHasOverflow] = useState(false);
  const debouncedCheckOverflow = useDebouncedCallback(() => {
    if (ref == null ? void 0 : ref.current) {
      const { scrollWidth, clientWidth, scrollHeight, clientHeight } = ref.current;
      const hasOverflow2 = scrollHeight > clientHeight || scrollWidth > clientWidth;
      setHasOverflow(hasOverflow2);
    }
  }, 100);
  useEffect(() => {
    debouncedCheckOverflow();
    window.addEventListener("resize", debouncedCheckOverflow);
    return () => {
      window.removeEventListener("resize", debouncedCheckOverflow);
    };
  }, [debouncedCheckOverflow, ref]);
  return hasOverflow;
};
