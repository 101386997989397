import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface StepperRightButtonProps {
  activeStep: number;
  steps: string[];
  isSaving: boolean;
  onClick: () => void;
  classes: any;
}

const StepperRightButton: React.FC<StepperRightButtonProps> = ({
  activeStep,
  steps,
  isSaving,
  onClick,
  classes
}) => {
  return (
    <Button
      disableElevation
      className={classes.buttonSecondary}
      style={{ minWidth: 130 }}
      onClick={onClick}
      endIcon={isSaving ? null : <ChevronRightIcon />}
    >
      {isSaving ? (
        <CircularProgress size={20} color="inherit" />
      ) : activeStep + 1 === steps.length ? (
        'Submit'
      ) : (
        'Next'
      )}
    </Button>
  );
};

export default StepperRightButton;
