"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../../ThemeContext/ThemeObject";
import { classes as rootClass } from "../styles";
import { applyButtonStyles } from "../../NewUI/shared/commonStyles";
export const classes = __spreadValues({
  formAutocomplete: {
    width: "225px",
    marginTop: "24px",
    "& .MuiAutocomplete-root": {
      "& .MuiOutlinedInput-root": {
        padding: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "0px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "& .MuiAutocomplete-input": {
        padding: "6px 4px 6px 11px"
      }
    },
    "& fieldset": {
      display: "none"
    },
    "& label": {
      color: "#838383 !important",
      fontSize: "21px",
      fontFamily: "Source Sans Pro, sans-serif",
      paddingLeft: "4px",
      left: "-13px",
      top: "-15px",
      "& .MuiFormLabel-asterisk": {
        color: theme.palette.error.main
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      transition: "all 0.25s ease-in-out",
      color: "#AAAAAA"
    }
  },
  formAutocompleteDynamicWidth: {
    width: "auto",
    minWidth: "225px",
    maxWidth: "100%"
  },
  applyButton: __spreadProps(__spreadValues({}, applyButtonStyles), {
    fontSize: "11px"
  }),
  resetText: {
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#939393",
    cursor: "pointer",
    margin: "auto 1rem"
  },
  autoCompleteListStyles: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#333333",
    maxHeight: "200px"
  },
  datePicker: {
    padding: "1rem",
    "& .DayPicker-NavBar": {
      left: "unset",
      right: "unset",
      padding: "0px",
      width: "233px"
    },
    "& .DayPicker-Weekday": {
      padding: "0px",
      width: "32px"
    },
    "& .DayPicker-Weekday abbr": {
      color: "#939393",
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: "10px"
    },
    "& .DayPicker-Caption": {
      color: "#004665",
      fontSize: "12px",
      fontWeight: "bold"
    },
    "& .DayPicker-Month": {
      margin: "0 10px",
      height: "fit-content"
    },
    "& .DayPicker-Months": {
      display: "flex"
    },
    "& .DayPicker-Day": {
      border: "none",
      fontSize: "10px",
      padding: "0px",
      height: "32px",
      borderRadius: "0"
    },
    "& .DayPicker-Day--outside": {
      backgroundColor: "#FFFFFF !important",
      "&::after": {
        display: "none"
      }
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)": {
      background: "rgba(91, 196, 191, 0.27)",
      color: "#000000"
    },
    "& .DayPicker-Day--start:not(.DayPicker-Day--outside)": {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      background: "rgba(91, 196, 191, 0.27) !important",
      position: "relative",
      "&::after": {
        content: '""',
        background: "rgba(91, 196, 191)",
        width: "32px",
        height: "32px",
        borderRadius: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "-1"
      }
    },
    "& .DayPicker-Day--end:not(.DayPicker-Day--outside)": {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
      background: "rgba(91, 196, 191, 0.27) !important",
      position: "relative",
      "&::after": {
        content: '""',
        background: "rgba(91, 196, 191)",
        width: "32px",
        height: "32px",
        borderRadius: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "-1"
      }
    },
    "& .DayPicker-Day--today": {
      fontWeight: "bold",
      color: "black"
    },
    "& .DayPicker-wrapper": {
      zIndex: "0",
      padding: "9px 0px",
      "& .DayPicker-NavButton": {
        width: "10px",
        height: "10px",
        margin: "0 -80px"
      },
      "& .DayPicker-Day--disabled": {
        backgroundColor: "white",
        pointerEvents: "none"
      }
    }
  },
  dateDisplay: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#939393",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 17px"
  },
  dateDisplayBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    borderRadius: "4px",
    border: "1px solid #DDDDDD",
    width: "88px",
    height: "36px"
  },
  dateInput: {
    "& .MuiOutlinedInput-root": {
      width: "88px",
      height: "31px",
      padding: "0px",
      margin: "0 4px",
      transform: "translateY(2px)",
      "&:hover": {
        "& fieldset": {
          borderColor: "#BBBBBB"
        }
      }
    },
    "& fieldset": {
      border: "1px solid #DDDDDD",
      borderColor: "#DDDDDD",
      borderRadius: "4px",
      "& legend": {
        display: "none"
      }
    },
    "& input": {
      fontSize: "12px",
      color: "#939393",
      padding: "0px",
      paddingLeft: "12px",
      paddingBottom: "4px"
    },
    "& button": {
      display: "none"
    }
  },
  invalidDate: {
    "& .MuiOutlinedInput-root": {
      "&:hover": {
        "& fieldset": {
          borderColor: "#E60B0B !important"
        }
      },
      "& fieldset": {
        borderColor: "#E60B0B"
      }
    }
  },
  onlyStartDate: {
    "& .DayPicker-Day--start": {
      borderRadius: "100% !important",
      color: "white"
    }
  },
  iconButton: {
    border: "1px solid #DDDDDD",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    "& svg": {
      fontSize: "20px",
      color: "#084D6D",
      transform: "translateY(1px)"
    }
  },
  popover: {
    marginTop: "15px"
  },
  popoverContent: {
    width: "250px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)"
  },
  filterPopoverContent: {
    overflowY: "scroll",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "& .DayPicker": {
      display: "contents"
    }
  },
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  filterPopoverHeader: {
    display: "flex",
    marginBottom: "13px",
    backgroundColor: "white",
    justifyContent: "right",
    position: "sticky",
    margin: "10px 10px 20px 0",
    top: "0px",
    zIndex: 99,
    boxShadow: "0px 6px 10px 7px #ffffff"
  },
  dateInputWrapper: {
    position: "relative",
    display: "flex",
    marginBottom: "4px",
    alignItems: "end"
  },
  calendarIcon: {
    fontSize: "1.3rem",
    margin: "0px 0 8px 8px",
    position: "absolute",
    zIndex: "1"
  },
  formAutocompleteDate: {
    width: "275px",
    marginTop: "24px",
    "& .MuiAutocomplete-root": {
      "& .MuiOutlinedInput-root": {
        padding: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "0px 10px 0px 25px!important",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "& .MuiAutocomplete-input": {
        padding: "6px 4px 6px 11px"
      }
    },
    "& fieldset": {
      display: "none"
    },
    "& label": {
      color: "#838383 !important",
      fontSize: "21px",
      fontFamily: "Source Sans Pro, sans-serif",
      paddingLeft: "4px",
      left: "-13px",
      top: "-15px",
      "& .MuiFormLabel-asterisk": {
        color: theme.palette.error.main
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      transition: "all 0.25s ease-in-out",
      color: "#AAAAAA"
    }
  },
  singleDayDatePicker: {
    "& .DayPicker-NavBar": {
      width: "auto",
      "& span": {
        margin: "auto !important"
      }
    },
    "& .DayPicker-Day--selected": {
      borderRadius: "50%",
      background: "rgba(91, 196, 191, 0.27) !important",
      color: "white !important",
      fontWeight: "bold",
      position: "relative",
      "&::after": {
        content: '""',
        background: "rgba(91, 196, 191)",
        width: "32px",
        height: "32px",
        borderRadius: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "-1"
      }
    }
  },
  visualReportSection: {
    padding: "20px 30px",
    border: "1px solid #EEEEEE"
  },
  overviewMetricBoxes: {
    fontFamily: "Source Sans Pro",
    border: "1px solid #EEEEEE",
    position: "relative",
    zIndex: 0,
    minHeight: "220px",
    padding: 2,
    flexDirection: "column",
    height: "100%",
    borderRadius: "4px",
    paddingTop: "10px"
  },
  backDropContent: {
    position: "absolute",
    height: "100%",
    padding: "3.5rem 2rem 2rem 2rem",
    backgroundColor: "rgb(0 0 0 / 0%)",
    fontSize: "16px",
    color: "#939393",
    zIndex: "2",
    alignItems: "flex-start"
  },
  popUpIconsClose: {
    fontSize: "1rem",
    color: "#939393"
  },
  popUpIconsInfo: {
    fontSize: "1rem",
    color: theme.palette.action.main
  },
  IconButton: {
    minWidth: "1rem",
    padding: "0"
  },
  percentageMetricText: {
    marginRight: "5px",
    fontWeight: "bold"
  },
  valueMetric: {
    fontSize: "30px",
    fontWeight: "bold"
  },
  textMetric: {
    fontSize: "23px",
    fontWeight: "600"
  },
  overviewMetricContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    margin: "8px 24px",
    flexGrow: 1
  }
}, rootClass);
