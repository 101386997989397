import React from 'react';
import { Editor } from 'react-draft-wysiwyg';

interface TextEditorProps {
  editorState: EditorState;
  setAnswers: React.Dispatch<React.SetStateAction<any>>;
  answers: any;
  activeError: string;
  requiredRef: string;
  classes: any;
}

const TextEditor: React.FC<TextEditorProps> = ({
  editorState,
  setAnswers,
  answers,
  activeError,
  requiredRef,
  classes
}) => {
  return (
    <Editor
      editorState={editorState}
      wrapperClassName={`${classes.textEditor} ${activeError === requiredRef ? classes.errorDescription : ''}`}
      toolbarClassName={classes.textEditorToolbar}
      editorClassName={classes.textEditorBody}
      onEditorStateChange={(e) => setAnswers({ ...answers, description: e })}
      toolbar={{
        options: ['inline', 'list'],
        inline: {
          options: ['bold', 'italic'],
          bold: { className: 'bordered-option-classname' },
          italic: { className: 'bordered-option-classname' }
        },
        list: { options: ['unordered'] }
      }}
    />
  );
};

export default TextEditor;
