"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../../ThemeContext/ThemeObject";
import { actionsMenuPaperCommonStyles } from "../shared/commonStyles";
export const styles = {
  applicationStatusDropdownStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "6px",
    color: "#333333",
    border: "1px solid #DDDDDD",
    fontSize: "12px",
    marginTop: 1,
    padding: "6px 10px",
    height: "38px"
  },
  applicationStatusPopoverStyle: {
    "& .MuiPaper-root": {
      minWidth: "150px",
      maxHeight: "200px",
      overflow: "auto",
      borderRadius: "6px",
      marginTop: 1
    }
  },
  actionsMenu: {
    "& .MuiPaper-root": __spreadProps(__spreadValues({}, actionsMenuPaperCommonStyles), {
      rowGap: "11px",
      minWidth: "153px",
      marginTop: "6px"
    })
  },
  actionsItem: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#939393",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  linkedInURLInput: {
    ".MuiInputBase-input": {
      height: "10px",
      margin: "unset",
      padding: "4px 6px",
      fontSize: "11px",
      minWidth: "150px",
      borderRadius: "4px",
      "&::placeholder": {
        fontStyle: "italic"
      }
    }
  },
  timeStampInput: {
    ".MuiInputBase-input": {
      height: "16px",
      padding: "4px 6px",
      fontSize: "11px",
      minWidth: "50px",
      borderRadius: "4px",
      "&::placeholder": {
        fontStyle: "italic"
      }
    }
  },
  timeStampItem: {
    cursor: "pointer",
    color: theme.palette.primary.dark,
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    height: "16px",
    margin: "unset",
    padding: "4px 6px",
    fontSize: "14px",
    minWidth: "50px"
  },
  linkedInURLLink: {
    cursor: "pointer",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#084D6D",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    "&:hover": { textDecoration: "underline" }
  },
  modalTitle: {
    fontWeight: "bold",
    color: "#084D6D",
    fontSize: "25px"
  },
  clickableGreyIcon: {
    cursor: "pointer",
    color: "#666666"
  },
  scoutNavyIcon: {
    color: "#084D6D",
    fontSize: "20px"
  },
  smallGreyIcon: {
    cursor: "pointer",
    color: "#666666",
    fontSize: "16px"
  },
  applicationHeaderItems: {
    flexDirection: "row",
    columnGap: 1,
    alignItems: "center",
    fontSize: "14px",
    color: "#333333"
  },
  applicationHeaderActionsContainer: {
    flexDirection: "row",
    columnGap: 2,
    justifyContent: "end",
    flexGrow: 1
  },
  surroundingApplications: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "8px",
    columnGap: 1,
    color: "#939393"
  },
  surroundingApplicationsButton: {
    border: "1px solid #E3E3E3",
    borderRadius: "6px",
    padding: "2px 6px",
    cursor: "pointer"
  },
  additionalInfoContainer: {
    padding: "24px 0px",
    borderRadius: "6px",
    border: "1px solid #E3E3E3",
    flexDirection: "row",
    columnGap: 2,
    justifyContent: "space-around"
  },
  AISummaryContainer: {
    padding: 3,
    borderRadius: "6px",
    border: "1px solid #E3E3E3",
    rowGap: 1
  },
  AISummaryHeader: {
    svg: { width: "40px", height: "40px" },
    flexDirection: "row",
    columnGap: 1,
    alignItems: "center"
  },
  AISummaryMessage: {
    fontWeight: 600,
    color: "#2D388D",
    span: { color: "#AFBCFF" }
  },
  AISummaryButton: {
    background: "#7A8EF1",
    color: "white",
    padding: "6px 24px",
    borderRadius: "16px",
    fontWeight: "bold",
    cursor: "pointer"
  },
  generateSummaryButton: {
    flexDirection: "row",
    color: "#AFBCFF",
    padding: "6px 16px",
    borderRadius: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    border: "2px solid #AFBCFF",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "170px"
  },
  summaryLastUpdated: {
    flexDirection: "row",
    columnGap: "4px",
    alignItems: "center",
    color: "#939393",
    fontStyle: "italic",
    fontSize: "12px"
  },
  ratingCell: {
    textAlign: "center",
    padding: "4px 12px",
    borderRadius: "4px",
    width: "60px",
    fontWeight: "400"
  },
  ratingInfoContainer: {
    padding: "6px 16px",
    margin: 1,
    border: "1px solid #5BC4C0",
    borderRadius: "10px"
  },
  ratingInfoClickable: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    svg: { color: "#5BC4C0" },
    cursor: "pointer"
  },
  ratingButton: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    padding: "6px 10px",
    color: "#939393",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "14px"
  },
  ratingColor: {
    1: {
      color: "#E37D7A",
      background: "#FCEFEF",
      borderColor: "#FCEFEF"
    },
    2: {
      color: "#E58F66",
      background: "rgba(229, 143, 102, 0.12)",
      borderColor: "rgba(229, 143, 102, 0.12)"
    },
    3: {
      color: "#E6A052",
      background: "#FCF4EA",
      borderColor: "#FCF4EA"
    },
    4: {
      color: "#9BB974",
      background: "rgba(155, 185, 116, 0.12)",
      borderColor: "rgba(155, 185, 116, 0.12)"
    },
    5: {
      color: "#8CCF95",
      background: "#EFFDF3",
      borderColor: "#EFFDF3"
    }
  },
  questionnaireContainer: {
    padding: 3,
    borderRadius: "6px",
    border: "1px solid #E3E3E3",
    rowGap: 2
  },
  attachmentsContainer: {
    padding: 3,
    borderRadius: "6px",
    border: "1px solid #E3E3E3",
    rowGap: 2
  },
  ratingCard: {
    flexDirection: "row",
    fontSize: "14px",
    borderRadius: "3px",
    minWidth: "170px",
    width: "22%",
    padding: "8px 16px",
    alignItems: "center",
    justifyContent: "space-between",
    svg: { fontSize: "14px" }
  },
  editRating: {
    ".MuiInputBase-input": {
      height: "8px",
      borderRadius: "3px",
      padding: 0.5,
      fontSize: "12px",
      fontWeight: "bold",
      margin: "unset"
    },
    "& input::placeholder": {
      fontSize: "10px"
    }
  },
  editableRatings: {
    ".MuiFormControl-root": { width: "45px" },
    ".MuiStack-root": { marginTop: "unset" }
  },
  sortableSectionsButton: {
    border: "1px solid #EAEEF0",
    borderRadius: "6px",
    padding: "6px 14px",
    height: "min-content",
    cursor: "pointer",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    }
  },
  sortableSectionsHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 1
  },
  timeslotAvailabilityButton: {
    padding: "6px 24px",
    borderRadius: "6px",
    alignItems: "center",
    fontWeight: "bold",
    width: "min-content"
  },
  preview: {
    color: "#666666",
    fontSize: "medium",
    "&:hover": {
      cursor: "pointer"
    }
  },
  fileIconContainer: {
    display: "flex",
    alignItems: "center"
  },
  fileIcon: {
    cursor: "pointer",
    width: "50px",
    height: "50px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    backgroundColor: "#084D6D10",
    "& svg": {
      color: theme.palette.primary.main,
      margin: "0px 2px 2px 0px"
    }
  },
  fileName: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    fontWeight: "bold",
    cursor: "pointer"
  },
  fileDate: {
    color: "#999999"
  },
  fileAttachedBy: {
    color: "#333333",
    fontSize: "15px"
  },
  noAttachments: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "24px 0px"
  },
  tableWrapper: {
    tableLayout: "auto !important",
    border: "none",
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "12px 0px",
      cursor: "inherit"
    },
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    }
  },
  tableActionIcon: {
    "&:focus": {
      outline: "none"
    }
  },
  timeslotsContainer: {
    flexDirection: "row",
    width: "100%",
    maxHeight: "290px",
    columnGap: 2,
    rowGap: 2,
    flexFlow: "wrap",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  timeslot: {
    border: `1px solid #DDDDDD`,
    borderRadius: "6px",
    padding: "8px 16px",
    fontWeight: "bold",
    width: "125px"
  },
  timeslotSubject: {
    fontSize: "12px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  noTimeslotMessage: {
    padding: 9,
    fontSize: "20px",
    fontWeight: "bold",
    color: theme.palette.common.grey
  },
  emptyStateContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "100px 0px"
  },
  emptyStateText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  applicationPageStickyHeader: {
    position: "sticky",
    top: "0px",
    zIndex: 10,
    background: "white",
    rowGap: 3,
    paddingBottom: 3
  },
  emptyStateIcon: { fontSize: "5rem", color: "#EAEAEA" },
  emptyStateMessage: { color: "#DDDDDD", fontWeight: "bold" },
  smartFormsAnswersPreviewContainer: {
    "& > div > div:first-of-type": {
      color: theme.palette.primary.dark,
      fontWeight: "bold"
    },
    "& > div > div:not(:first-of-type)": {
      color: "#333333"
    },
    "& > div": {
      paddingLeft: 0
    }
  },
  smartFormsTableContainer: {
    tableLayout: "auto !important",
    border: "none",
    th: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      paddingBottom: "16px",
      "& .Mui-active": {
        color: theme.palette.primary.main
      }
    },
    "td, th, tr, thead": {
      fontFamily: "Source Sans Pro, sans-serif",
      border: "none",
      backgroundColor: "#FFFFFF",
      textTransform: "none",
      padding: "24px"
    },
    "& thead > tr": { borderBottom: "1px solid #E5E5E5" },
    "& tbody > tr:nth-of-type(2n)": {
      borderBottom: "1px solid #E5E5E5"
    }
  },
  ratingInput: { flexDirection: "row", alignItems: "center", columnGap: 0.5 },
  questionsAndRatingsContainer: {
    overflow: "auto",
    rowGap: 2,
    flexGrow: 1
  },
  betaButtonTooltipContainer: {
    width: "430px",
    height: "400px",
    color: "#333333",
    fontFamily: "Source Sans Pro",
    rowGap: 2,
    padding: 2
  },
  candidateLink: {
    color: "#084D6D",
    fontSize: "20px",
    fontWeight: "bold",
    width: "fit-content"
  }
};
