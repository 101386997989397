import React, { useState, useRef, Dispatch } from 'react';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import { FormNumberField, FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { TCandidate } from '../../../Candidates/types';
import { scrollToElement } from '../../../utils/scroll-to-element';
import { validateEmail } from '../../../Candidates/EditCandidate';

export default function FoundU({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [candidate, setCandidate] = useState<TCandidate | null>(application?.candidate || null);
  const [preferredName, setPreferredName] = useState<string>('');
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const fieldRefs = {
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    mobile: useRef<HTMLInputElement>(null)
  };

  const { mutate: foundUExport, isLoading: exportingFoundU } = useMutation({
    mutationFn: async () => {
      if (application && candidate) {
        const { res } = await Api.foundUExport(application.id, {
          first_name: candidate?.firstname,
          surname: candidate?.lastname,
          email: candidate.email,
          mobile: candidate.phone1,
          preferred_name: preferredName,
          send_welcome_email: sendWelcomeEmail
        });
        return res;
      }
    },
    onSuccess: (res: { success: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in exporting to foundU, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const validateInputs = () => {
    if (!candidate) return false;
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!candidate.firstname.trim()) inputErrors.firstName = 'First name can not be empty';
    if (!candidate.lastname.trim()) inputErrors.lastName = 'Last name can not be empty';
    if (!validateEmail(candidate.email)) inputErrors.email = 'Please enter a proper email address';
    if (!candidate.phone1.trim()) inputErrors.mobile = 'Please enter a mobile number';
    setErrors(inputErrors);
    if (inputErrors.firstName) {
      scrollToElement(fieldRefs.firstName);
    } else if (inputErrors.lastName) {
      scrollToElement(fieldRefs.lastName);
    } else if (inputErrors.email) {
      scrollToElement(fieldRefs.email);
    } else if (inputErrors.mobile) {
      scrollToElement(fieldRefs.mobile);
    }
    return !Object.keys(inputErrors).length;
  };

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  if (!candidate) return null;

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.FOUNDU}
      label="FoundU export modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Export to FoundU</Stack>
        <Stack sx={styles.modalDoubleColumn}>
          <FormTextField
            label="First name"
            value={candidate.firstname || ''}
            required
            fullWidth
            onChange={(e) => setCandidate({ ...candidate, firstname: e.target.value })}
            error={errors.firstName}
            innerRef={fieldRefs.firstName}
          />
          <FormTextField
            label="Last name"
            value={candidate.lastname || ''}
            required
            fullWidth
            onChange={(e) => setCandidate({ ...candidate, lastname: e.target.value })}
            error={errors.lastName}
            innerRef={fieldRefs.lastName}
          />
        </Stack>
        <FormTextField
          label="Preferred name"
          value={preferredName}
          onChange={(e) => setPreferredName(e.target.value)}
          styles={{ width: '48%' }}
        />
        <Stack sx={styles.modalDoubleColumn}>
          <FormTextField
            label="Email"
            value={candidate.email}
            required
            fullWidth
            onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
            error={errors.email}
            innerRef={fieldRefs.email}
          />
          <FormNumberField
            label={'Mobile'}
            value={candidate.phone1}
            onChange={(e) => setCandidate({ ...candidate, phone1: e.target.value })}
            required
            fullWidth
            error={errors.mobile}
            innerRef={fieldRefs.mobile}
          />
        </Stack>
        <FormControlLabel
          control={
            <Checkbox
              sx={sharedClasses.formCheckbox}
              checked={sendWelcomeEmail}
              onChange={(event) => setSendWelcomeEmail(event.target.checked)}
            />
          }
          label="Send welcome email"
          sx={{ ...sharedClasses.checkboxLabel, margin: 'unset' }}
        />
        <ModalFooterButtons
          primaryButtonText="Export"
          primaryButtonCallback={() => validateInputs() && foundUExport()}
          isLoading={exportingFoundU}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
