import React from "react";
import PropTypes from "prop-types";
import { Stepper, Step, StepLabel, StepConnector } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 25,
    left: "calc(-50% + 25px)",
    right: "calc(50% + 25px)"
  },
  active: {
    "& $line": {
      borderColor: theme.palette.secondary.main
    }
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.secondary.main
    }
  },
  line: {
    borderColor: "#F3F3F3",
    borderTopWidth: 3,
    borderRadius: 1
  }
}))(StepConnector);
const StepperComponent = ({ activeStep, steps, classes }) => {
  StepperComponent.propTypes = {
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired
  };
  return /* @__PURE__ */ React.createElement("div", { className: classes.stepSelect }, /* @__PURE__ */ React.createElement(
    Stepper,
    {
      classes: { root: classes.stepperRoot },
      alternativeLabel: true,
      activeStep,
      connector: /* @__PURE__ */ React.createElement(QontoConnector, null)
    },
    steps.map((step, i) => /* @__PURE__ */ React.createElement(Step, { key: i, classes: { root: `${classes.stepItem}` } }, /* @__PURE__ */ React.createElement(
      StepLabel,
      {
        classes: {
          iconContainer: classes.stepLabelIconContainer,
          label: classes.stepLabelLabel,
          active: classes.stepLabelActive,
          completed: classes.stepLabelCompleted
        },
        StepIconProps: {
          classes: {
            root: classes.stepIconRoot,
            active: classes.stepIconActive,
            completed: classes.stepIconCompleted
          }
        }
      },
      step.label
    )))
  ));
};
export default StepperComponent;
