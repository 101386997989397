var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { withScoutTheme } from "../../../../ThemeContext/ThemeContext";
import { Snackbar } from "@material-ui/core";
import { Step1, Step2 } from "./Steps";
import useStyles from "../../../../IndeedIntegration/AdEditor/useStyles";
import ExitConfirmationDialog from "../../../../../components/SeekAd/AdEditor/Components/ExitConfirmationDialog";
import StepperComponent from "../../CustomUIElements/StepperComponent";
import StepperLeftButton from "../../Buttons/StepperLeftButton";
import StepperRightButton from "../../Buttons/StepperRightButton";
import JobAdSuccessDialog from "../../CustomUIElements/JobAdSuccessDialog";
import { handleNext } from "../handleNext";
import { handleDraftValue } from "../handleDraftValue";
const steps = [
  { label: "Job Details", value: "job_details" },
  { label: "Preview Ad", value: "preview" }
];
function AdEditor({ jobAdParams }) {
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState(updateAnswers(jobAdParams));
  const [activeError, setActiveError] = useState(null);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [editExistingJob, setEditExistingJob] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const classes = useStyles();
  const handleNextButton = (type) => {
    console.log(answers);
    handleNext(type, checkErrors, requiredRefs, setActiveError, submit);
  };
  function checkErrors() {
    const error = null;
    return error;
  }
  function updateAnswers(params) {
    return {
      featured: params.featured || false,
      title: params.title || "",
      description: handleDraftValue(params.job_description || "") || "",
      classification: params.classification ? params.classifications.find((param) => param[1] === params.classification) : "",
      workType: params.work_type ? params.worktypes.find((param) => param[1] === params.work_type) : "",
      workLocation: params.work_location ? params.worklocations.find((param) => param[1] === params.work_location) : "",
      address: params.address || "",
      payType: params.salary_type ? params.paytypes.find((param) => param[1] === params.salary_type) : "",
      payRangeLow: params.pay_range_low || 0,
      payRangeHigh: params.pay_range_high || 0,
      screeningQuestions: params.screening_questions || [],
      benefits: params.benefits ? params.extrabenefits.find((param) => param[1] === params.benefits) : [],
      jobPreview: params.job_preview || {}
    };
  }
  function submit(type) {
    return __async(this, null, function* () {
      try {
        type === "draft" ? setSavingDraft(true) : setSaving(true);
        if (type === "draft") {
          handleSnack("Draft saved");
          setIsExitModalOpen(false);
          window.history.back();
        } else if (type === "submit") {
          setIsSuccessDialogOpen(true);
        } else if (type === "next") {
          setAnswers(updateAnswers("mock data"));
          setActiveStep(activeStep + 1);
        }
        console.log("submit", type);
      } catch (e) {
        if (type === "draft") {
          handleSnack("Unable to save draft");
        } else if (type === "submit") {
          handleSnack("Unable to post to Indeed. Please check required fields and try again.");
        } else if (type === "next") {
          setActiveStep(activeStep + 1);
        }
      } finally {
        setSaving(false);
        setSavingDraft(false);
      }
    });
  }
  function handleSnack(message) {
    setIsSnackOpen(true);
    setSnackMessage(message);
  }
  const requiredRefs = {
    featured: useRef(null),
    title: useRef(null),
    description: useRef(null),
    classification: useRef(null),
    workType: useRef(null),
    workLocation: useRef(null),
    address: useRef(null),
    payType: useRef(null),
    payRangeLow: useRef(null),
    payRangeHigh: useRef(null),
    screeningQuestions: useRef(null),
    benefits: useRef(null),
    jobPreview: useRef(null)
  };
  useEffect(() => {
    setActiveError(null);
  }, [answers]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [activeStep]);
  return /* @__PURE__ */ React.createElement("div", { className: classes.containerOuter }, /* @__PURE__ */ React.createElement("div", { className: classes.container }, /* @__PURE__ */ React.createElement(StepperComponent, { activeStep, steps, classes }), activeStep === 0 && /* @__PURE__ */ React.createElement(
    Step1,
    {
      jobAdParams,
      answers,
      setAnswers,
      requiredRefs,
      activeError
    }
  ), activeStep === 1 && /* @__PURE__ */ React.createElement(
    Step2,
    {
      jobAdParams,
      answers,
      setAnswers,
      requiredRefs,
      activeError
    }
  ), /* @__PURE__ */ React.createElement("div", { className: classes.buttonContainer }, /* @__PURE__ */ React.createElement("a", { onClick: () => setIsExitModalOpen(true) }, "Save and exit"), /* @__PURE__ */ React.createElement("div", { className: classes.buttons }, /* @__PURE__ */ React.createElement(
    StepperLeftButton,
    {
      activeStep,
      onClick: () => setActiveStep(activeStep - 1),
      classes
    }
  ), /* @__PURE__ */ React.createElement(
    StepperRightButton,
    {
      activeStep,
      steps,
      isSaving: saving,
      onClick: () => handleNextButton(activeStep + 1 === steps.length ? "submit" : "next"),
      classes
    }
  )))), /* @__PURE__ */ React.createElement(
    ExitConfirmationDialog,
    {
      saving,
      savingDraft,
      setActiveStep,
      isExitModalOpen,
      setIsExitModalOpen,
      editExistingJob,
      submit
    }
  ), /* @__PURE__ */ React.createElement(
    JobAdSuccessDialog,
    {
      open: isSuccessDialogOpen,
      onClose: () => window.location.replace(document.referrer),
      answers,
      classes,
      adType: "viewjobs"
    }
  ), /* @__PURE__ */ React.createElement(Snackbar, { open: isSnackOpen, onClose: () => setIsSnackOpen(false), message: snackMessage }));
}
AdEditor.propTypes = {
  jobAdParams: PropTypes.object.isRequired
};
export default withScoutTheme(AdEditor);
