"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../../components/ThemeContext/ThemeObject";
import {
  filterPopoverContentStyles,
  filterPopoverHeaderCommonStyles
} from "../../components/NewUI/shared/commonStyles";
import {
  applyButton,
  clearButton,
  filterSwitchLabels
} from "../../components/NewUI/shared/styleClasses";
export const classes = {
  iconButton: {
    border: "1px solid #EAEEF0",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "69px",
    height: "60px",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    }
  },
  iconButtonOpen: {
    backgroundColor: "rgba(8,77,109,0.121)"
  },
  popover: {
    marginTop: "15px"
  },
  popoverContent: {
    width: "272px",
    padding: "22px 0px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)"
  },
  tableSettingsHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: 1,
    paddingLeft: "4px"
  },
  filterSwitch: {
    transform: "translateX(10px)",
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& :hover": {
      backgroundColor: "rgba(91, 196, 191, 0.17)"
    }
  },
  filterSwitchActive: {
    transform: "translateX(10px)",
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    }
  },
  filterSwitchLabels,
  scrollableFilters: {
    height: "fit-content",
    padding: "26px",
    paddingTop: "0px"
  },
  filterPopoverHeader: __spreadProps(__spreadValues({}, filterPopoverHeaderCommonStyles), {
    top: "0px"
  }),
  filterOptionContainer: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#084D6D",
    marginBottom: "12px"
  },
  filterButton: {
    border: "1px solid #DDDDDD",
    borderRadius: 1,
    width: "fit-content",
    padding: "8px 12px"
  },
  filterPopoverContent: __spreadProps(__spreadValues({}, filterPopoverContentStyles), {
    overflowY: "scroll"
  }),
  clearButton,
  applyButton,
  ratingSlider: {
    fontFamily: "Source Sans Pro",
    color: theme.palette.action.main,
    height: "2px",
    "& .MuiSlider-rail": {
      backgroundColor: "#E0E0E0"
    },
    "& .MuiSlider-track": {
      backgroundColor: theme.palette.action.main
    },
    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.action.main,
      width: "8px",
      height: "8px",
      "&:hover": {
        boxShadow: "0px 0px 0px 5px" + theme.palette.action.main + "16"
      }
    },
    "& .MuiSlider-thumb.Mui-active": {
      boxShadow: "0px 0px 0px 7px" + theme.palette.action.main + "16"
    },
    "& .MuiSlider-valueLabel": {
      fontSize: "11px",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      top: "36px",
      "&::after": {
        display: "none"
      }
    }
  }
};
