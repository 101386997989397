"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import queryString from "query-string";
const BASE_URL = window.location.origin;
class Api {
  constructor() {
    this.handleResponse = (response) => __async(this, null, function* () {
      if (response.ok) {
        let resHead = {};
        [...response.headers].forEach(
          (header) => resHead[header[0]] = header[1]
        );
        let res = yield response.json();
        return { res, resHead };
      }
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    });
    this.get = (path, params = {}, headers, noCache) => fetch(
      `${BASE_URL}${path}?${queryString.stringify(params, {
        arrayFormat: "bracket",
        encode: false
      })}`,
      __spreadValues({
        headers: this.getHeaders(headers)
      }, noCache && { cache: "no-store" })
    ).then((r) => this.handleResponse(r));
    this.post = (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "POST",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.put = (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "PUT",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.delete = (path, params = {}, headers = {}) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "DELETE",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.getJobs = (params, apiKey) => this.get(`/api/v4/jobs`, params, apiKey);
    this.getJob = (apiKey, jobId) => this.get(`/api/v4/jobs/${jobId}`, {}, apiKey);
    this.getStatuses = (params, apiKey) => this.get(`/api/v4/jobs/statuses`, params, apiKey);
    this.getFilters = (apiKey, url, params) => this.get(`/api/v4/jobs/${url}`, params, apiKey);
    this.getUISettings = (params) => this.get(`/api/v4/ui_preferences`, params, {}, true);
    this.getUserPermissions = (apiKey) => this.get(`/api/v4/user_permissions`, {}, apiKey);
    this.getJobNote = (apiKey, jobId) => this.get(`/api/v4/jobs/${jobId}/job_notes`, {}, apiKey);
    this.postJobNote = (apiKey, jobId, params) => this.post(`/api/v4/jobs/${jobId}/job_notes`, params, apiKey);
    this.postUISettings = (params) => this.post(`/api/v4/ui_preferences`, params, {});
    this.putChangedState = (link, apiKey) => this.put(link, {}, apiKey);
    this.putChangedStatus = (jobId, statusID, apiKey) => this.put(`/api/v4/jobs/${jobId}/status/${statusID}`, {}, apiKey);
    this.putPinJob = (jobId, apiKey) => this.put(`/api/v4/jobs/${jobId}/pin`, {}, apiKey);
    this.putUnpinJob = (jobId, apiKey) => this.put(`/api/v4/jobs/${jobId}/unpin`, {}, apiKey);
    this.putArchiveJobs = (apiKey, params) => this.put(`/api/v4/jobs/archive`, params, apiKey);
    this.putUnrchiveJobs = (apiKey, params) => this.put(`/api/v4/jobs/unarchive`, params, apiKey);
    this.getCSVExport = (params, apiKey) => this.get(`/api/v4/jobs/export`, params, apiKey);
    this.referenceValidation = (apiKey, params) => this.get(`/api/v4/jobs/validating_reference`, params, apiKey);
    this.referencePrefix = (apiKey, params) => this.get(`/api/v4/jobs/auto_job_reference_setting`, params, apiKey);
    this.cloneJob = (apiKey, jobId, params, queryParams) => this.post(`/api/v4/jobs/${jobId}/clone${queryParams}`, params, apiKey);
    this.importResources = (apiKey, jobId, params, queryParams) => this.post(`/api/v4/jobs/${jobId}/import_resources${queryParams}`, params, apiKey);
    this.cloneJobsOptions = (apiKey, params) => this.get(`/api/v4/jobs/jobs_for_clone`, params, apiKey);
    this.createJob = (apiKey, params) => this.post("/api/v4/jobs/", params, apiKey);
    this.getEntities = (apiKey) => this.get(`/api/v4/entities`, {}, apiKey);
  }
  getHeaders(headers) {
    return Object.assign(
      {},
      {
        "Content-Type": "application/json"
      },
      headers
    );
  }
}
export default new Api();
