import React, { useState, useEffect, useRef, Dispatch, useCallback } from 'react';
import { Box, Modal, Button, Switch, CircularProgress, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import {
  MultilineFormTextField,
  FormTextField
} from '../../../Components/CustomUIElements/FormTextField';
import FormSelectField from '../../../Components/CustomUIElements/FormSelectField';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import Api from '../../API';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import SortableChoice from '../../../EditJob/Questions/Modals/SortableChoice';
// import { IUserPermissions } from '../../../Components/sharedTypes';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useFieldChoiceList } from '../../../hooks/useFieldChoiceList';
import { SmartFormFieldsState, SmartFormFieldsAction } from '../../reducer';
import { IError } from '../../types';

const fieldTypeOptions = [
  'Availability',
  'Date',
  'Text',
  'Textarea',
  'Radio Button',
  'Select',
  'Rank',
  'Checkbox'
];

const fieldTypeValues = {
  Select: 'select_field',
  Date: 'date_field',
  Text: 'text_field',
  'Radio Button': 'radio_button_field',
  Rank: 'rank_field',
  Availability: 'availability_field',
  Checkbox: 'check_box_field',
  Textarea: 'text_area_field'
};

const DateOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default function NewField({
  formId,
  SmartFormFieldsState,
  dispatch,
  userPermissions
}: {
  formId: number;
  SmartFormFieldsState: SmartFormFieldsState;
  dispatch: Dispatch<SmartFormFieldsAction>;
  userPermissions: Record<string, Record<string, boolean>>;
}) {
  const [fieldType, setFieldType] = useState('');
  const [label, setLabel] = useState('');
  const [enabledSwitch, setEnabledSwitch] = useState(true);
  const [requiredSwitch, setRequiredSwitch] = useState(false);
  const [rateableSwitch, setRateableSwitch] = useState(false);
  const [confidentialSwitch, setConfidentialSwitch] = useState(false);
  const [weekDuration, setWeekDuration] = useState('Weekly');
  const [weekStartDay, setWeekStartDay] = useState<string>('Sunday');
  const [headingOne, setHeadingOne] = useState('');
  const [headingTwo, setHeadingTwo] = useState('');
  const [allowBreak, setAllowBreak] = useState('No');
  const [errorLabel, setErrorLabel] = useState('');
  const [errorField, setErrorField] = useState('');
  const [errorChoices, setErrorChoices] = useState('');
  const {
    choiceList,
    setChoiceList,
    addChoice,
    removeChoice,
    clearChoices,
    updateChoice,
    switchChoices
  } = useFieldChoiceList();

  const labelRef = useRef<HTMLInputElement>(null);
  const fieldTypeRef = useRef<HTMLInputElement>(null);
  const choicesRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    switchChoices(active.id, over.id);
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const setDefaultValue = useCallback(async () => {
    if (SmartFormFieldsState.editingQuestion) {
      setFieldType(getKeyByValue(fieldTypeValues, SmartFormFieldsState.editingQuestion.field_type));
      setLabel(SmartFormFieldsState.editingQuestion.title);
      setEnabledSwitch(SmartFormFieldsState.editingQuestion.enabled);
      setRequiredSwitch(SmartFormFieldsState.editingQuestion.required);
      setRateableSwitch(SmartFormFieldsState.editingQuestion.rateable);
      setConfidentialSwitch(SmartFormFieldsState.editingQuestion.confidential);
      setChoiceList(SmartFormFieldsState.editingQuestion.field_choices);
      if (SmartFormFieldsState.editingQuestion.availability_config) {
        const duration = SmartFormFieldsState.editingQuestion.availability_config.week_duration;
        setWeekDuration(duration.charAt(0).toUpperCase() + duration.slice(1));
        setWeekStartDay(
          DateOptions[SmartFormFieldsState.editingQuestion.availability_config.week_start_day]
        );
        setHeadingOne(SmartFormFieldsState.editingQuestion.availability_config.heading_1);
        setHeadingTwo(SmartFormFieldsState.editingQuestion.availability_config.heading_2);
        setAllowBreak(
          SmartFormFieldsState.editingQuestion.availability_config.allow_break === true
            ? 'Yes'
            : 'No'
        );
      }
    }
  }, [SmartFormFieldsState.editingQuestion]);

  const handleClose = () => {
    dispatch({
      type: 'SET_EDITING_QUESTION',
      payload: null
    });
    dispatch({
      type: 'SET_MODALS_OPEN',
      payload: { ...SmartFormFieldsState.modalsOpen, newField: false }
    });
    clearData();
  };

  const handleSubmit = () => {
    if (!fieldType || !label) {
      if (!fieldType) {
        fieldTypeRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setErrorField('This field is required');
      } else {
        labelRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setErrorLabel('This field is required');
      }
      return;
    }

    let fieldObject = {
      title: label,
      enabled: enabledSwitch,
      required: requiredSwitch,
      rateable: rateableSwitch,
      confidential: confidentialSwitch,
      field_type: fieldTypeValues[fieldType]
    };

    if (['Select', 'Rank', 'Checkbox', 'Radio Button'].includes(fieldType)) {
      choiceList.map((choice, index) => {
        if (!choice?.name) {
          choicesRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setErrorChoices('All field choices must each have a value');
          return;
        }
        choice.position = index;
        if (choice.id.toString().length == 13) {
          choice.id = '';
        }
      });

      const jsonString = JSON.stringify(Object.assign({}, choiceList));
      const attributes = JSON.parse(jsonString);
      fieldObject['field_choices_attributes'] = attributes;
    } else if (['Availability'].includes(fieldType)) {
      fieldObject['availability_config_attributes'] = {
        week_duration: weekDuration.toLowerCase(),
        week_start_day: DateOptions.indexOf(weekStartDay),
        heading_1: headingOne,
        heading_2: headingTwo,
        allow_break: allowBreak === 'Yes'
      };
    }

    CreateEditField.mutate(fieldObject);
  };

  const CreateEditField = useMutation({
    mutationFn: (fieldObject) => {
      if (SmartFormFieldsState.editingQuestion) {
        dispatch({
          type: 'SET_ACTION_STATES',
          payload: {
            ...SmartFormFieldsState.actionStates,
            [SmartFormFieldsState.editingQuestion.id]: {
              enabled: enabledSwitch,
              required: requiredSwitch,
              rateable: rateableSwitch,
              confidential: confidentialSwitch
            }
          }
        });
        return Api.putQuestion(SmartFormFieldsState.editingQuestion.id, formId, {
          field: fieldObject
        });
      } else {
        return Api.newField(formId, {
          field: fieldObject
        });
      }
    },
    onError: (error: IError) => {
      dispatch({
        type: 'SET_ACTION_STATES',
        payload: { ...SmartFormFieldsState.actionStates }
      });
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There has been an error ${
            SmartFormFieldsState.editingQuestion ? 'editing' : 'creating'
          } the question, ${error.errors.join('. ')}`,
          state: 'error'
        }
      });
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Field has been ${SmartFormFieldsState.editingQuestion ? 'updated' : 'created'}`,
          state: 'success'
        }
      });
      queryClient.invalidateQueries(['questions'], { exact: true });
      handleClose();
    }
  });

  const handleChoiceInput = (e, index: number) => {
    const { value } = e.target;
    updateChoice(value, index);
  };

  const clearData = () => {
    setFieldType('');
    setLabel('');
    setEnabledSwitch(true);
    setRequiredSwitch(false);
    setRateableSwitch(false);
    setConfidentialSwitch(false);
    clearChoices();
    setWeekDuration('Weekly');
    setWeekStartDay('Sunday');
    setHeadingOne('');
    setHeadingTwo('');
    setAllowBreak('No');
    setErrorLabel('');
    setErrorField('');
    setErrorChoices('');
  };

  useEffect(() => {
    setDefaultValue();
  }, [setDefaultValue, SmartFormFieldsState.editingQuestion]);

  return (
    <Modal
      open={SmartFormFieldsState.modalsOpen.newField}
      onClose={() => handleClose()}
      aria-labelledby="new-field-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <Box sx={classes.modalWrapper}>
          <CloseIcon onClick={() => handleClose()} sx={classes.closeIcon} />
          <Box id="new-field-title" sx={classes.actionModalHeader}>
            New field
          </Box>
          <Box
            sx={classes.modalContent}
            id="new-field-modal-content"
            aria-label="New Field Modal Content"
          >
            <Box role="form" id="new-field-form" aria-label="New Field form">
              <Box sx={{ ...classes.actionModalFormLine, marginTop: '20px' }} ref={fieldTypeRef}>
                <Virtualize
                  value={fieldType}
                  passOptions={fieldTypeOptions}
                  setValue={(value: string) => {
                    setFieldType(value);
                    setErrorField('');
                  }}
                  passedStyles={{
                    ...sharedClasses.formAutocomplete,
                    width: '300px',
                    marginTop: '20px'
                  }}
                  label="Field type"
                />
                {errorField && <Box sx={{ ...classes.formError, top: '63px' }}>{errorField}</Box>}
              </Box>
            </Box>
            <Box sx={{ marginLeft: '15px' }}>
              <Box sx={classes.modalFormLine}>
                <MultilineFormTextField
                  value={label}
                  onChange={(e) => {
                    setErrorLabel('');
                    setLabel(e.target.value);
                  }}
                  styles={{ width: '600px', marginTop: '20px' }}
                  rows={6}
                  innerRef={labelRef}
                  error={errorLabel}
                  label={'Field label'}
                />
              </Box>
            </Box>
            <Box sx={classes.checkboxContainer} id="enabled-filter">
              <FormControlLabel
                sx={
                  !enabledSwitch
                    ? { ...classes.switchLabels, ...classes.labelActive }
                    : classes.switchLabels
                }
                control={
                  <Switch
                    sx={enabledSwitch ? classes.switchActive : classes.switch}
                    checked={enabledSwitch}
                    onChange={() => setEnabledSwitch(!enabledSwitch)}
                  />
                }
                label="Enabled"
                labelPlacement="end"
              />
              <FormControlLabel
                sx={
                  !requiredSwitch
                    ? { ...classes.switchLabels, ...classes.labelActive }
                    : classes.switchLabels
                }
                control={
                  <Switch
                    sx={requiredSwitch ? classes.switchActive : classes.switch}
                    checked={requiredSwitch}
                    onChange={() => setRequiredSwitch(!requiredSwitch)}
                  />
                }
                label="Required"
                labelPlacement="end"
              />
              <FormControlLabel
                sx={
                  !rateableSwitch
                    ? { ...classes.switchLabels, ...classes.labelActive }
                    : classes.switchLabels
                }
                control={
                  <Switch
                    sx={rateableSwitch ? classes.switchActive : classes.switch}
                    checked={rateableSwitch}
                    onChange={() => setRateableSwitch(!rateableSwitch)}
                  />
                }
                label="Rateable"
                labelPlacement="end"
              />
              <FormControlLabel
                sx={
                  !confidentialSwitch
                    ? { ...classes.switchLabels, ...classes.labelActive }
                    : classes.switchLabels
                }
                control={
                  <Switch
                    sx={confidentialSwitch ? classes.switchActive : classes.switch}
                    checked={confidentialSwitch}
                    onChange={() => setConfidentialSwitch(!confidentialSwitch)}
                  />
                }
                label="confidential"
                labelPlacement="end"
              />
            </Box>
            {['Select', 'Rank', 'Checkbox', 'Radio Button'].includes(fieldType) && (
              <Box sx={classes.addChoiceContainer} ref={choicesRef}>
                <Box sx={classes.addChoiceHead}>
                  <Box id="add-choice-title" sx={classes.addChoiceLabel}>
                    Choices
                  </Box>
                  <Button
                    id="add-choice-button"
                    sx={classes.modalAddChoiceButton}
                    onClick={addChoice}
                  >
                    Add choice
                  </Button>
                </Box>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext items={choiceList} strategy={verticalListSortingStrategy}>
                    {choiceList.map(
                      (choice, index) =>
                        !(choice?._destroy === 1) && (
                          <SortableChoice
                            key={choice.id}
                            id={choice.id}
                            choice={choice}
                            index={index}
                            handleChoiceInput={handleChoiceInput}
                            onRemoveChoice={removeChoice}
                          />
                        )
                    )}
                  </SortableContext>
                </DndContext>
                {errorChoices && <Box sx={{ ...classes.inlineError }}>{errorChoices}</Box>}
              </Box>
            )}
            {['Availability'].includes(fieldType) && (
              <Box sx={classes.availabilityContainer}>
                <Box id="availability-config-title" sx={classes.addChoiceLabel}>
                  Availability Config
                </Box>
                <Box sx={classes.config}>
                  <FormSelectField
                    options={['Weekly', 'Fortnightly']}
                    defaultValue={weekDuration}
                    value={weekDuration}
                    onChange={(value) => setWeekDuration(value)}
                    label={'How long is your work period?'}
                    styles={{ width: '290px', marginBottom: '15px' }}
                    required={true}
                  />
                  <FormSelectField
                    options={DateOptions}
                    defaultValue={weekStartDay}
                    value={weekStartDay}
                    onChange={(value) => {
                      setWeekStartDay(value);
                    }}
                    label={'When does the work period start?'}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                  <FormTextField
                    label="Heading 1"
                    value={headingOne}
                    onChange={(e) => setHeadingOne(e.target.value)}
                    required={false}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                  <FormTextField
                    label="Heading 2"
                    value={headingTwo}
                    onChange={(e) => setHeadingTwo(e.target.value)}
                    required={false}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                  <FormSelectField
                    options={['Yes', 'No']}
                    defaultValue={allowBreak}
                    value={allowBreak}
                    onChange={(value) => setAllowBreak(value)}
                    label={'Allow break?'}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                </Box>
              </Box>
            )}
            <Box sx={classes.modalFooter}>
              <Button
                id="close-new-field-button"
                sx={classes.modalCancelButton}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                id="submit-new-field-button"
                type="submit"
                sx={{ ...classes.modalCreateButton, width: '91px', height: '41px' }}
                onClick={handleSubmit}
              >
                {CreateEditField.isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  'Confirm'
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
