import React, { Dispatch, useRef, useState } from 'react';
import { Button, CircularProgress, Stack } from '@mui/material';
import { NavigateBefore as NavigateBeforeIcon } from '@mui/icons-material';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { ApplicationAction, ApplicationState, IApplication, IFormsOption } from '../types';
import { ModalType } from '../config';
import { styles } from './styles';
import { IError } from '../../SmartForms/types';
import { createQuestionErrorsObject } from '../../ApprovalForms/NewApproval/NewApproval';
import FormsDropdown from './Components/FormsDropdown';
import { validateForm } from '../SmartFormsTab/validate-form-submission';
import { getAnswerAttributes } from '../SmartFormsTab/get-answer-attributes';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { classes } from '../../Job/Applications/styles';
import QuestionsAndRatings from '../OverviewTab/QuestionsAndRatings';
import { ExitConfirmationModal } from './ExitConfirmationModal';

export default function AddForm({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const [selectedForm, setSelectedForm] = useState<IFormsOption | null>(null);
  const [ratings, setRatings] = useState<Record<number, number>>({});
  const [answers, setAnswers] = useState<
    Record<number, string | number | Record<string, string | boolean>>
  >({});
  const [questionErrors, setQuestionErrors] = useState<Record<number, string>>({});
  const [step, setStep] = useState<number>(1);
  const [exitConfirmation, setExitConfirmation] = useState<boolean>(false);

  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const questionsRef = useRef<Array<HTMLDivElement | null>>([]);

  const {
    data: formQuestions,
    isFetching: fetchingFormQuestions,
    refetch: fetchFormQuestions
  } = useQuery({
    queryKey: ['form'],
    queryFn: async () => {
      if (selectedForm) {
        const { res } = await Api.getFormQuestions({
          form_id: selectedForm.id,
          enabled_only: true
        });
        return res.fields;
      }
    },
    onSuccess: (res) => {
      res && setQuestionErrors(createQuestionErrorsObject(res));
      setStep(2);
    },
    onError: (error: IError) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: error.errors.join(', '), state: 'error' }
      }),
    enabled: false
  });

  const answersAttributes: any = {};
  if (formQuestions) {
    formQuestions.forEach((question, index) => {
      answersAttributes[index] = getAnswerAttributes(question, index, answers, ratings);
    });
  }

  const { mutate: addForm, isLoading: addingForm } = useMutation({
    mutationFn: async () => {
      if (application && selectedForm) {
        const { res } = await Api.addForm(
          application.job.id,
          application.id,
          selectedForm.id,
          answersAttributes
        );
        return res;
      }
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(['formResponses'], { exact: true });
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      handleClose();
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Failed to create form response`, state: 'error' }
      })
  });

  const handleClose = () => {
    queryClient.removeQueries(['form']);
    queryClient.removeQueries(['formResponses']);
    dispatch({ type: 'SET_MODALS_OPEN', payload: null });
  };

  const handleSubmit = () => {
    step == 1
      ? fetchFormQuestions()
      : formQuestions &&
        validateForm(formQuestions, answers, setQuestionErrors, questionsRef) &&
        addForm();
  };

  const buttonName = step === 1 ? 'Select' : 'Add form response';

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.ADD_FORM}
      label="Add form response modal"
      handleClose={() => setExitConfirmation(true)}
      styleOverrides={{ ...styles.modalStyleOverrides, ...(step === 1 ? {} : styles.wideModal) }}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>{step === 1 ? 'Add form' : selectedForm?.name}</Stack>
        {step === 1 ? (
          <Stack sx={{ paddingBottom: 5, overflow: 'auto' }}>
            <FormsDropdown
              applicationId={application?.id}
              selectedForm={selectedForm}
              setSelectedForm={setSelectedForm}
              dispatch={dispatch}
            />
          </Stack>
        ) : (
          <QuestionsAndRatings
            questions={formQuestions}
            questionErrors={questionErrors}
            setQuestionErrors={setQuestionErrors}
            ratings={ratings}
            setRatings={setRatings}
            setAnswers={setAnswers}
            questionsRef={questionsRef}
          />
        )}
        <Stack
          sx={{ flexDirection: 'row', justifyContent: 'flex-end', columnGap: 1, paddingTop: 1 }}
        >
          <Button sx={classes.modalCancelButton} onClick={() => setExitConfirmation(true)}>
            Cancel
          </Button>
          {step === 2 && (
            <Button
              onClick={() => setStep(1)}
              sx={{ ...sharedClasses.genericButtonSecondary, minWidth: 'unset', padding: 1 }}
            >
              <NavigateBeforeIcon />
            </Button>
          )}
          <Button
            sx={{
              ...sharedClasses.genericButton,
              '&.Mui-disabled ': { background: '#DDDDDD', color: '#939393' },
              minWidth: step === 1 ? '95px' : '165px',
              minHeight: '41px'
            }}
            onClick={handleSubmit}
            disabled={!selectedForm}
          >
            {fetchingFormQuestions || addingForm ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              buttonName
            )}
          </Button>
        </Stack>
      </Stack>
      <ExitConfirmationModal
        exitConfirmation={exitConfirmation}
        setExitConfirmation={setExitConfirmation}
        handleClose={handleClose}
      />
    </StyledModal>
  );
}
