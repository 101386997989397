"use strict";
import { searchInput } from "../../NewUI/shared/styleClasses";
export const classes = {
  searchContainer: {
    width: "100%",
    position: "relative"
  },
  searchInput,
  searchAdornment: {
    padding: "0px 0px 0px 14px"
  },
  editBooleanIcon: {
    zIndex: 2,
    cursor: "pointer",
    border: "1px solid rgba(0, 0, 0, 0.26)",
    borderRadius: "0px 6px 6px 0px",
    backgroundColor: "#FFFFFF",
    width: "69px",
    height: "59.5px",
    transform: "translate(14px, -2.5px)",
    "& svg": {
      fontSize: "30px",
      color: "#084D6D"
    },
    "&:hover": {
      backgroundColor: "rgb(245 245 245)",
      borderColor: "#DADEE0"
    },
    "&:focus": {
      outline: "none"
    }
  },
  tlpMatch: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: 700,
    color: "#4E4EC7",
    backgroundColor: "#dfdff6",
    width: "200px",
    borderRadius: "4.8px 0px 0px 4.8px",
    height: "57.5px",
    textTransform: "none",
    zIndex: 2,
    transform: "translate(-13px, -2.5px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e6e6f7"
    },
    "&:focus": {
      outline: "none",
      backgroundColor: "#e6e6f7"
    }
  },
  searchTooltip: {
    position: "absolute",
    top: "-22px",
    left: "8px",
    backgroundColor: "#FFFFFF",
    color: "#000000",
    border: "1px solid #DDDDDD",
    borderRadius: "6px",
    padding: "4px 10px",
    fontSize: "14px",
    fontFamily: "Source Sans Pro"
  },
  booleanEditorContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    rowGap: "36px",
    padding: "0 0 20px 20px"
  },
  booleanEditorRow: {
    display: "flex",
    position: "relative",
    border: "2px solid #DDDDDD",
    borderRadius: "6px",
    padding: "10px"
  },
  booleanEditorValuesContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    height: "100%",
    width: "100%",
    gap: "20px"
  },
  booleanEditorValue: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: 700,
    color: "#AFBCFF",
    backgroundColor: "#2D388D",
    height: "33px",
    width: "fit-content",
    borderRadius: "28px",
    display: "flex",
    alignItems: "center",
    padding: "0 2px 0 16px"
  },
  booleanEditorRemoveValueButton: {
    color: "#AFBCFF",
    "& svg": {
      fontSize: "16px"
    },
    "&:focus": {
      outline: "none"
    }
  },
  booleanEditorAddButton: {
    backgroundColor: "#FFFFFF",
    color: "#2D388D",
    border: "1px solid #2D388D",
    borderRadius: "6px",
    width: "50px",
    height: "33px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  booleanEditorRemoveRowButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  booleanEditorRemoveRowButton: {
    color: "#2D388D"
  },
  booleanEditorOperatorContainer: {
    position: "absolute",
    left: "-12px",
    top: "-26px"
  },
  booleanEditorOperatorGroup: {
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#2D388D",
      color: "#AFBCFF",
      "&:hover": {
        backgroundColor: "#2D388D"
      }
    }
  },
  booleanEditorOperatorButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: 700,
    color: "#BBBBBB",
    backgroundColor: "#FFFFFF",
    height: "30px",
    width: "45px",
    display: "flex",
    alignItems: "center",
    padding: "0 6px 0 6px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#EFEFEF",
      color: "#AFBCFF"
    }
  },
  booleanEditorValueInput: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: 700,
    color: "#2D388D",
    backgroundColor: "#FFFFFF",
    height: "30px",
    minWidth: "40px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "12px",
    borderRadius: "28px",
    border: "1px solid #2D388D"
  }
};
