"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../../../ThemeContext/ThemeObject";
import { actionsModalReducedPaddingCommonStyles } from "../../shared/commonStyles";
import {
  actionButton,
  actionModalFormLine,
  closeIcon,
  modalCreateButton
} from "../../shared/styleClasses";
export const classes = {
  questionsPageContainer: {
    paddingTop: "6px",
    overflowY: "auto",
    width: "100%"
  },
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px",
    "& h2": {
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "1.2em",
      color: "#666666",
      marginTop: "0",
      "@media (max-width: 1450px)": {
        fontSize: "20px"
      },
      "@media (max-width: 1260px)": {
        fontSize: "14px"
      }
    }
  },
  modalWrapper: {
    height: "100%",
    position: "relative",
    display: "contents"
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "40px",
    padding: "24px 16px",
    minWidth: "700px"
  },
  noQuestionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  noQuestionsText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px",
    textAlign: "center"
  },
  actionsContianer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "22px",
    margin: "30px 0 30px 0",
    padding: "0px 16px"
  },
  actionButton,
  actionsModalReducedPadding: __spreadProps(__spreadValues({}, actionsModalReducedPaddingCommonStyles), {
    paddingBottom: 0
  }),
  closeIcon,
  actionModalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginLeft: "15px"
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    margin: "24px 0 0 0"
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "20px",
    width: "100%"
  },
  actionModalFormLine,
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px",
    marginTop: "auto",
    marginBottom: "40px"
  },
  modalFooterSmallerSection: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    marginBottom: "40px",
    columnGap: "20px"
  },
  modalCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "100px",
    textTransform: "none",
    textDecoration: "underline",
    color: theme.palette.error.main,
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      boxShadow: "none"
    }
  },
  modalCreateButton,
  formError: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    position: "absolute",
    top: "-24px",
    left: "4px"
  },
  inlineError: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    left: "4px"
  },
  checkboxContainer: {
    display: "flex",
    marginLeft: "19px"
  },
  switchLabels: {
    justifyContent: "start",
    width: "120px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      color: "#000000"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    },
    "& :hover": {
      "& .MuiSwitch-track": {
        backgroundColor: "#000 !important"
      }
    }
  },
  switchActive: {
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    }
  },
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontFamily: "Source Sans Pro",
      fontSize: "14px"
    }
  },
  addChoiceContainer: {
    padding: "20px 15px"
  },
  addChoiceHead: {
    display: "flex",
    alignItems: "baseline"
  },
  addChoiceLabel: {
    color: "#838383",
    paddingLeft: "4px"
  },
  modalAddChoiceButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#5BC4BF",
    backgroundColor: "#5BC4BF12",
    borderRadius: "6px",
    boxShadow: "none",
    marginTop: "10px",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#4da8a412",
      boxShadow: "none"
    }
  },
  choice: {
    marginTop: "15px"
  },
  availabilityContainer: {
    padding: "20px 15px"
  },
  config: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column"
  },
  questionsWrapper: {
    display: "flex",
    padding: "12px 18px 0 18px",
    background: "#F3F3F3",
    borderRadius: "6px"
  },
  questionInput: {
    "& span": {
      fontSize: "14px !Important"
    }
  },
  titleContainer: {
    display: "flex",
    columnGap: "18px",
    paddingRight: "101px",
    textAlign: "center"
  },
  questionsTitle: {
    color: theme.palette.primary.main,
    fontSize: "13px",
    fontWeight: "bold",
    paddingBottom: "5px",
    width: "70px",
    padding: "0px"
  },
  iconGreen: {
    color: theme.palette.success.main,
    fontSize: "1.25rem",
    margin: "auto",
    display: "flex"
  },
  iconRed: {
    color: theme.palette.error.main,
    fontSize: "1.25rem",
    margin: "auto",
    display: "flex"
  },
  weightValue: {
    fontSize: "13px",
    textAlign: "center"
  },
  noticeContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "9px 16px",
    border: `1px solid ${theme.palette.action.main}`,
    borderRadius: "8px",
    color: "#939393",
    fontSize: "16px",
    margin: "16px",
    rowGap: "1px"
  },
  noticeHeader: {
    fontWeight: "bold"
  },
  noticeIcon: {
    color: theme.palette.action.main,
    margin: "0 9px 3px 0",
    fontSize: "16px"
  },
  noticeBody: {
    marginLeft: "26px"
  }
};
