import { Box, FormControl, MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material';
import React, { Dispatch } from 'react';
import { TPaginationState, TablePaginationAction } from './types';
import { classes } from './styles';
import { handleAPIRequest } from '../Utilities/handleAPIRequest';
import { clearPreferenceCache } from '../../helpers/uiPreferencesHelpers';

const GenericTablePagination = ({
  paginationState,
  dispatch,
  totalPages,
  totalItems,
  userPreferenceSource
}: {
  paginationState: TPaginationState<string>;
  dispatch: Dispatch<TablePaginationAction<string>>;
  totalPages: number;
  totalItems: number;
  userPreferenceSource?: string;
}) => {
  const handleSetLimit = (e: SelectChangeEvent<number>) => {
    dispatch({ type: 'SET_LIMIT', payload: Number(e.target.value) });
    dispatch({ type: 'SET_PAGE', payload: 1 });
    if (userPreferenceSource) {
      handleAPIRequest({
        method: 'POST',
        url: '/api/v4/ui_preferences',
        body: {
          source: userPreferenceSource,
          row_count: Number(e.target.value)
        }
      });
      clearPreferenceCache(userPreferenceSource);
    }
  };
  return (
    <Box sx={classes.paginationParent}>
      <Box sx={classes.rowSelectContainer}>
        <span>Rows per page: </span>
        <FormControl>
          <Select
            id="rows-per-page"
            sx={classes.rowSelect}
            value={paginationState.limit}
            onChange={handleSetLimit}
            displayEmpty
            MenuProps={{ sx: classes.paginationMenuItems }}
          >
            <MenuItem id="first-rpp-item" value={10}>
              10
            </MenuItem>
            <MenuItem id="second-rpp-item" value={20}>
              20
            </MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={40}>40</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={classes.paginationContainer}>
        <Pagination
          id="pagination-menu"
          count={totalPages}
          page={paginationState.page}
          siblingCount={0}
          onChange={(e, page) => dispatch({ type: 'SET_PAGE', payload: page })}
          sx={classes.pagination}
        />
        <span id="total-jobs">{totalItems} Total</span>
      </Box>
    </Box>
  );
};

export default GenericTablePagination;
