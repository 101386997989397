import React from 'react';
import { Dialog, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

interface JobAdSuccessDialogProps {
  open: boolean;
  onClose: () => void;
  answers: any;
  classes: any;
  adType: string;
  jobId?: string;
}

const JobAdSuccessDialog: React.FC<JobAdSuccessDialogProps> = ({
  open,
  onClose,
  answers,
  classes,
  adType,
  jobId
}) => {
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.successDialog }}>
      <div className={classes.successCheck}>
        <CheckCircleIcon />
      </div>
      <h4>Success!</h4>
      <div className={classes.successDialogText}>
        {answers && answers.advertisement_id ? (
          <span>Your ad has been updated on {adType}</span>
        ) : (
          <span>Your ad has been posted to {adType}</span>
        )}
      </div>
      <div className={`${classes.dialogButtonContainer} ${classes.successDialogContainer}`}>
        <Button
          disableElevation
          className={classes.buttonSecondary}
          onClick={() => {
            if (jobId) {
              location.href = `/admin/jobs/${jobId}/job_ads`;
            } else {
              window.history.back();
            }
          }}
          endIcon={<ChevronRightIcon />}
        >
          Back to ads
        </Button>
      </div>
    </Dialog>
  );
};

export default JobAdSuccessDialog;
