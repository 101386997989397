import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { pdfjs } from 'react-pdf';
import { IAttachment, IPreviewData } from '../../types';
import { classes } from '../../../Components/Modals/styles';
import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';
import { useDragScroll } from '../../../hooks';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface WordPreviewProps {
  onPinchHandler: (...args: unknown[]) => ReactDOMAttributes;
  previewData: IPreviewData;
  selectedAttachment: IAttachment;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  zoom: number;
}

const WordPreview: FC<WordPreviewProps> = ({
  onPinchHandler,
  previewData,
  selectedAttachment,
  setCurrentIndex,
  zoom
}) => {
  const [scrollRef] = useDragScroll();

  if (!('data' in previewData)) return null;

  return (
    <Stack>
      <NavigationButtons
        hasPrev={previewData.prev}
        hasNext={previewData.next}
        setCurrentIndex={setCurrentIndex}
      />
      <Box sx={classes.docPreviewContent}>
        <Box ref={scrollRef} {...onPinchHandler()}>
          <ImagePreviewList
            imageData={previewData.data || []}
            attachmentName={selectedAttachment.attached_file_name}
            zoom={zoom}
          />
        </Box>
      </Box>
    </Stack>
  );
};

const NavigationButton: FC<{
  onClick: () => void;
  label: string;
}> = ({ onClick, label }) => (
  <Button sx={classes.downloadButton} onClick={onClick}>
    {label}
  </Button>
);

const NavigationButtons: FC<{
  hasPrev: boolean;
  hasNext: boolean;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
}> = ({ hasPrev, hasNext, setCurrentIndex }) => (
  <Box sx={{ ...classes.docPreviewNavigation, position: 'sticky', top: 0 }}>
    {hasPrev && (
      <NavigationButton onClick={() => setCurrentIndex((prev) => prev - 1)} label="Previous" />
    )}
    {hasNext && (
      <NavigationButton onClick={() => setCurrentIndex((prev) => prev + 1)} label="Next" />
    )}
  </Box>
);

const ImagePreviewList: FC<{
  imageData: string[];
  attachmentName: string;
  zoom: number;
}> = ({ imageData, attachmentName, zoom }) => (
  <>
    {imageData.map((src, index) => (
      <Box
        key={index}
        alt={attachmentName}
        component="img"
        draggable={false}
        src={src || ''}
        sx={{
          ...classes.imagePreview,
          height: '1000px',
          transform: `scale(${zoom})`,
          transformOrigin: '0 0',
          borderBottom: '2px solid black'
        }}
      />
    ))}
  </>
);

export default WordPreview;
