"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { fetchPreferences } from "../../helpers/uiPreferencesHelpers";
export const getPreferences = (userPreferenceSource, dispatch) => __async(void 0, null, function* () {
  var _a, _b, _c, _d, _e;
  const { res: globalRes } = yield fetchPreferences("universal");
  const { ui_preferences: [globalPreferences] = [] } = globalRes;
  const { res: localRes } = yield fetchPreferences(userPreferenceSource);
  const { ui_preferences: [localPreferences] = [] } = localRes;
  ((_a = globalPreferences == null ? void 0 : globalPreferences.columns) == null ? void 0 : _a.density) && dispatch({ type: "SET_DENSITY", payload: (_b = globalPreferences == null ? void 0 : globalPreferences.columns) == null ? void 0 : _b.density });
  (localPreferences == null ? void 0 : localPreferences.sortable_columns) && dispatch({ type: "SET_COLUMNS", payload: localPreferences == null ? void 0 : localPreferences.sortable_columns });
  (localPreferences == null ? void 0 : localPreferences.row_count) && dispatch({ type: "SET_LIMIT", payload: localPreferences == null ? void 0 : localPreferences.row_count });
  ((_c = localPreferences == null ? void 0 : localPreferences.sorting) == null ? void 0 : _c.sortBy) && dispatch({
    type: "SET_SORT",
    payload: {
      sortBy: (_d = localPreferences == null ? void 0 : localPreferences.sorting) == null ? void 0 : _d.sortBy,
      sortOrder: ((_e = localPreferences == null ? void 0 : localPreferences.sorting) == null ? void 0 : _e.sortOrder) || "desc"
    }
  });
  return null;
});
